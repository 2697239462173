<template>
  <div class="pp-tokens-table">
    <v-row align="stretch" class="pt-2">
      <v-col
        class="pa-4"
        cols="12" md="8" lg="6"
        v-for="item of renderedItems"
        :key="'token-' + item.fullId"
      >
        <v-tooltip-ext :nudge-bottom="tooltipY" open-delay="300" color="primary" :disabled="!item.name">
          <template #activator="{ on: onTokenImage }">
            <div
              class="relative"
              :class="{
                'quarter-opacity': item.hidden
              }"
              v-on="onTokenImage"
              @mousemove="trackCursor"
            >
              <div class="absolute w-100 h-100 rounded-circle" :class="backCircle" v-if="item.imageUri && backCircle"></div>
              <v-img
                :src="item.imageUri || ''"
                aspect-ratio="1"
                :content-class="item.imageUri ? 'pointer' : 'accent'"
                :alt="item.name"
                :class="item.class || 'rounded'"
                v-on="item.preventOpen ? {} : { click: () => { $emit('view-token', item) } }"
              >
                <div class="w-100 h-100 d-flex justify-center align-center">
                  <v-icon x-large color="white" v-if="!item.imageUri">mdi-image-off</v-icon>
                  <v-icon x-large color="secondary" class="ma-auto" v-else-if="item.hidden">mdi-eye-off</v-icon>
                </div>
              </v-img>
            </div>
          </template>
          {{ item.name }}
        </v-tooltip-ext>
      </v-col>
    </v-row>
    <v-row justify="space-between" class="pb-2 pp-tokens-table-footer" v-if="itemsPerPageOptions.length > 0">
      <div class="d-flex align-center pp-tokens-table-ipp">
        <v-chip-group v-model="itemsPerPage">
          <v-chip
            v-for="itemsPerPageOption of itemsPerPageOptions"
            :key="'ipp-' + itemsPerPageOption"
            outlined
            :value="itemsPerPageOption"
          >{{ itemsPerPageOption }}</v-chip>
        </v-chip-group>
        <div>
          {{ $t('shared.perPage') }}
        </div>
      </div>
      <div class="d-flex align-center pp-tokens-table-pages">
        <div class="pt-1">{{ pageStart }} - {{ pageEnd }}</div>
        <v-btn
          class="mt-1 ml-3 minw-0 px-0"
          rounded
          outlined
          small
          :disabled="pageNum <= 0"
          @click="pageNum--"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          class="mt-1 ml-3 minw-0 px-0"
          rounded
          outlined
          small
          :disabled="pageNum >= totalPages - 1"
          @click="pageNum++"
        ><v-icon>mdi-chevron-right</v-icon></v-btn>
      </div>
    </v-row>
  </div>
</template>

<script>

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [12, 24, 48]
    },
    backCircle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      itemsPerPage: this.itemsPerPageOptions[0] || 0,
      pageNum: 0,
      tooltipX: 0,
      tooltipY: 0
    }
  },
  computed: {
    pageOffset () {
      return this.pageNum * this.itemsPerPage
    },
    pageStart () {
      return this.pageOffset + 1
    },
    pageEnd () {
      const pageEnd = this.itemsPerPage ? this.pageOffset + this.itemsPerPage : this.items.length
      return pageEnd > this.items.length ? this.items.length : pageEnd
    },
    totalPages () {
      return this.itemsPerPage ? Math.ceil(this.items.length / this.itemsPerPage) : 1
    },
    renderedItems () {
      return this.items.slice(this.pageStart - 1, this.pageEnd)
    }
  },
  watch: {
    items () {
      this.pageNum = 0
    },
    itemsPerPage (newValue, oldValue) {
      const oldPageStart = this.pageNum * oldValue + 1
      this.pageNum = Math.floor(oldPageStart / newValue)
    }
  },
  methods: {
    trackCursor (event) {
      this.tooltipY = event.pageY - window.scrollY - 50
    },
    viewToken (item) {
      console.log('view-token-in', item)
    }
  }

}
</script>

<style lang="scss">
  @import '@/styles/main.scss';
  .pp-tokens-table {
    .pp-tokens-table-footer {
      .pp-tokens-table-pages {
        .v-btn.v-btn--outlined {
          border: 1px solid rgb(255, 255, 255, 0.12);
          width: 32px;
          height: 32px
        }
      }
    }
  }
</style>

<!--

Background Blurred
  Repeat element before image
  .v-image {
    position: absolute;
    width: 100%;
    background-color: black;
    border-radius: 20px;
    border: 1px solid black;
    .v-image__image {
      background-size: 200% 200%;
      filter: blur(5px) brightness(0.6);
      margin: -10px;
      width: calc(100% + 20px);
      height: calc(100% + 20px);
    }
  }

-->
