import service from './main'
import urls from './urls'

const content = () => ({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})

const obtainWeb3Token = ({ clientId, clientSecret, secretLang, secretNonce, secretExpiresOn }) => service.postRequest(urls.sessionMgmt.token, {
  grant_type: 'client_credentials',
  scope: 'web3-login',
  client_id: clientId,
  client_secret: clientSecret,
  secret_lang: secretLang,
  secret_nonce: secretNonce,
  secret_expires_on: secretExpiresOn
}, content())

const obtainWeb2Token = ({ authCode, redirectUri }) => service.postRequest(urls.sessionMgmt.token, {
  grant_type: 'authorization_code',
  scope: 'web2-login',
  code: authCode,
  redirect_uri: redirectUri
}, content())

const refreshToken = ({ clientId, refreshToken, scope }) => service.postRequest(urls.sessionMgmt.token, {
  grant_type: 'refresh_token',
  client_id: clientId,
  refresh_token: refreshToken,
  scope
}, content())

const revokeToken = () => service.postRequest(urls.sessionMgmt.revoke)

export default {
  obtainWeb3Token,
  obtainWeb2Token,
  refreshToken,
  revokeToken
}
