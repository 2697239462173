<template>
  <div>
    <web3-modal-vue
      ref="Web3ModalComponent"
      :theme="web3ModalTheme"
      :provider-options="web3ModalProviderOptions"
      cache-provider
    />
    <pp-sign-in-web-3
      ref="SignInWeb3"
      :signing-in="isSigningIn"
    />
    <pp-wallet-connect
      v-model="showWalletConnectDialog"
      ref="PpWalletConnect"
      :web2-options="web2OptionsToRender"
      @connect-web2="connectSocial"
      :web3-options="web3OptionsToRender"
      @connect-web3="web3Option => web3Option.onClick()"
    />
  </div>
</template>

<script>
import Web3ModalVue from 'web3modal-vue'
import PpSignInWeb3 from '@/components/dialogs/PpSignInWeb3.vue'
import PpWalletConnect from '@/components/dialogs/PpWalletConnect.vue'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { mapGetters } from 'vuex'
import { signinMessage, oneAllSubdomain } from '@/data/constants'
import { sessionMgmtService, filesMgmtService, profileService } from '@/services'
import { socialPlatforms } from '@/data/social'
import { ethers } from 'ethers'

/* The OneAll library is loaded asynchronously */
const oneAllScriptElement = document.createElement('script')
oneAllScriptElement.type = 'text/javascript'
oneAllScriptElement.async = true
oneAllScriptElement.src = '//' + oneAllSubdomain + '.api.oneall.com/socialize/library.js'
const firstScriptElement = document.getElementsByTagName('script')[0]
firstScriptElement.parentNode.insertBefore(oneAllScriptElement, firstScriptElement)

export default {
  components: {
    Web3ModalVue,
    PpSignInWeb3,
    PpWalletConnect
  },
  data () {
    return {
      web3ModalTheme: 'dark',
      web3ModalProviderOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: '-'
          }
        }
      },
      showWalletConnectDialog: false,
      web3OptionsToRender: [],
      web3ProviderPromise: null,
      isSigningIn: false,
      isSocialOpen: false,
      isLoadingSocial: false
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'web3Address',
      'isWeb3Connected'
    ]),
    web2OptionsToRender () {
      return socialPlatforms(['google', 'facebook', 'twitter', 'instagram', 'linkedin', 'twitch'])
    }
  },
  methods: {
    toggleModal () {
      const web3Modal = this.$refs.Web3ModalComponent
      if (web3Modal) {
        this.web3OptionsToRender = web3Modal.userOptions
          .map(userOption => {
            const userOptionId = web3Modal.providerController.providers
              .find(provider => provider.name === userOption.name)
              .id
            return {
              ...userOption,
              id: userOptionId
            }
          })
      } else {
        this.web3OptionsToRender = []
      }
      this.showWalletConnectDialog = !this.showWalletConnectDialog
    },
    async initWalletController () {
      const web3Modal = this.$refs.Web3ModalComponent
      if (!web3Modal) {
        setTimeout(this.initWalletController, 200)
        return
      }
      web3Modal._toggleModal = this.toggleModal
      this.$store.dispatch('web3Init', web3Modal)
    },
    async openWalletConnectDialog (afterConnect = () => {}) {
      if (!this.showWalletConnectDialog) {
        const web3Modal = this.$refs.Web3ModalComponent
        web3Modal.clearCachedProvider()
        return web3Modal.connect()
          .then(web3Provider => {
            console.log('web3Modal.connect resolved', web3Provider)
            const ethProvider = new ethers.providers.Web3Provider(web3Provider)
            return this.$store.dispatch('web3Connect', ethProvider)
          })
          .then(this.signInWithWallet)
          .then(afterConnect)
      }
    },
    async switchWallet (callback = () => {}) {
      await this.$store.dispatch('removeToken')
      await this.$store.dispatch('web3Remove')
      return callback()
    },
    async disconnectWallet (callback = () => {}) {
      await this.$store.dispatch('removeToken')
      await this.$store.dispatch('web3Reset')
      return callback()
    },
    async signInWithWallet (callback = () => {}, callbackErr = () => {}) {
      if (this.isLoggedIn) {
        return callback()
      }
      if (!this.isWeb3Connected) {
        return callbackErr()
      }
      this.$refs.SignInWeb3.$emit('open', callback)
      return this.requestSignature()
        .then(callback)
        .catch(callbackErr)
    },
    async requestSignature (callback = () => {}) {
      console.log('requestSignature')
      if (!this.$root.ethProvider || !this.$root.ethSigner || !this.web3Address) {
        return Promise.reject(new Error(0))
      }

      const clientId = this.web3Address
      const secretLang = this.$i18n.locale
      const secretNonce = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)
      const secretExpiresOn = Date.now() + 5 * 60 * 1000

      const dateObj = new Date(0)
      dateObj.setUTCMilliseconds(secretExpiresOn)
      const expiresOnISO = dateObj.toISOString()

      const message = signinMessage(secretLang, secretNonce, expiresOnISO)
      const signature = await this.$store.dispatch('web3SignMsg', message)

      this.isSigningIn = true
      this.$store.commit('setToken', {})
      const fetchProfilePromise = profileService.getProfile(clientId)
        .catch(error => {
          this.$store.dispatch('alertShow', { error })
        })
      const fetchTokenResponse = await sessionMgmtService.obtainWeb3Token({
        clientId,
        clientSecret: signature,
        secretLang,
        secretNonce,
        secretExpiresOn
      })
        .catch(error => {
          this.$store.dispatch('alertShow', { error })
        })
        .finally(() => {
          this.isSigningIn = false
          this.$refs.SignInWeb3.$emit('close')
        })
      await this.processLogin({
        client_id: clientId,
        ...fetchTokenResponse
      }, await fetchProfilePromise)
      return callback()
    },
    async signInWithSocial ({ authCode, callback }) {
      const callbackUrl = sessionStorage['web2_session_callback']
      if (!callbackUrl) { return }

      this.isSigningIn = true
      this.$store.commit('setToken', {})
      const fetchTokenResponse = await sessionMgmtService.obtainWeb2Token({
        authCode,
        redirectUri: callbackUrl
      })
        .catch(error => {
          this.$store.dispatch('alertShow', { error })
        })
        .finally(() => {
          this.isSigningIn = false
        })
      const fetchProfileResponse = await profileService.getProfile(fetchTokenResponse.address)
        .catch(error => {
          this.$store.dispatch('alertShow', { error })
        })
      await this.processLogin({
        clientId: fetchTokenResponse.address,
        ...fetchTokenResponse
      }, fetchProfileResponse)
      return (callback || (() => {}))()
    },
    async processLogin (loginData, profileData) {
      return (profileData.avatar
        ? filesMgmtService.downloadFileToDataUrl(profileData.avatar.uri)
        : Promise.resolve())
        .then(avatarDataUrl => {
          profileData.avatar.uri = avatarDataUrl
        })
        .catch(() => {
          profileData.avatar = null
        })
        .finally(() => {
          return this.$store.dispatch('setToken', {
            clientId: loginData.client_id,
            accessToken: loginData.access_token,
            refreshToken: loginData.refresh_token,
            tokenType: loginData.token_type,
            scope: loginData.scope,
            expiresIn: loginData.expires_in,
            profile: profileData
          })
        })
    },
    getCallbackUrl (params = {}, redirect) {
      const fullPath = redirect || location.href
      const callbackUrl = `${fullPath}${fullPath.includes('?') ? '&' : '?'}${
          Object.entries(params)
            .map(([attrKey, attrVal]) => encodeURIComponent(attrKey) + '=' + encodeURIComponent(attrVal))
            .join('&')
        }`
      console.log({ callbackUrl })
      return callbackUrl
    },
    connectSocial (web2Option, scope = 'web2-login', additionalParams = {}) {
      const oneAllInstance = _oneall || [] // eslint-disable-line no-undef
      const nonce = `${'0'.repeat(12)}${Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(16)}`.slice(-12)
      const callbackUrl = this.getCallbackUrl({
        auth_scope: scope,
        auth_nonce: nonce,
        auth_platform: web2Option.id,
        ...additionalParams
      })

      sessionStorage['web2_session_callback'] = callbackUrl

      oneAllInstance.push([
        'social_login',
        'set_callback_uri',
        callbackUrl
      ])
      oneAllInstance.push(['social_login', 'do_login', web2Option.id, ''])
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (newRoute) {
        if (newRoute.query.connection_token) {
          const currentQuery = Object.assign({}, this.$route.query)
          const authScope = newRoute.query.auth_scope
          const authPlatform = newRoute.query.auth_platform
          const authCode = newRoute.query.connection_token
          delete currentQuery.auth_scope
          delete currentQuery.auth_platform
          delete currentQuery.auth_nonce
          delete currentQuery.oa_action
          delete currentQuery.oa_connector
          delete currentQuery.connection_token
          this.isLoadingSocial = true
          this.$router.replace({
            name: this.$route.name,
            params: Object.assign({
              authScope,
              authPlatform,
              authCode
            }, this.$route.params),
            query: currentQuery
          }).catch(() => {})

          if (authScope === 'web2-login') {
            return this.signInWithSocial({ authCode })
              .finally(() => {
                this.$root.web2Ready = true
                this.isLoadingSocial = false
              })
          } else {
            this.isLoadingSocial = false
          }
        }
        if (!this.isLoadingSocial) {
          this.$root.web2Ready = true
        }
      }
    }
  },
  mounted () {
    this.$nextTick(this.initWalletController)
  },
  created () {
    this.$root.$on('open-wallet-connect', e => this.openWalletConnectDialog(e).catch(() => {}))
    this.$root.$on('switch-wallet', e => this.switchWallet(e).catch(() => {}))
    this.$root.$on('disconnect-wallet', e => this.disconnectWallet(e).catch(() => {}))
    this.$root.$on('signin-with-wallet', e => this.signInWithWallet(e).catch(() => {}))
    this.$root.$on('request-signin-signature', e => this.requestSignature(e).catch(() => {}))
    this.$root.$on('process-login', e => this.processLogin(e).catch(() => {}))
    this.$root.$on('connect-social', this.connectSocial)
  },
  destroyed () {
    this.$root.$off('open-wallet-connect')
    this.$root.$off('switch-wallet')
    this.$root.$off('disconnect-wallet')
    this.$root.$off('signin-with-wallet')
    this.$root.$off('request-signin-signature')
    this.$root.$off('process-login')
    this.$root.$off('connect-social')
  }
}
</script>

<style>

</style>
