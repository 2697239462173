var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tokenData)?_c('div',[_c('div',{staticClass:"relative",class:{
      'quarter-opacity': _vm.tokenHidden
    }},[_c('div',{staticClass:"absolute w-100 h-100 ml-1 mt-1"},[_c('img',{staticClass:"w-100 h-100 image-to-shadow",attrs:{"src":_vm.resolvedImageUri}})]),_c('v-img',{ref:"TokenImage",staticClass:"rounded",attrs:{"src":_vm.resolvedImageUri,"aspect-ratio":"1","content-class":_vm.resolvedImageUri ? '' : 'accent',"alt":_vm.tokenData.name}},[_c('div',{staticClass:"w-100 h-100 d-flex justify-center align-center"},[(!_vm.resolvedImageUri)?_c('v-icon',{attrs:{"x-large":"","color":"white"}},[_vm._v("mdi-image-off")]):(_vm.tokenData.hidden)?_c('v-icon',{staticClass:"ma-auto",attrs:{"x-large":"","color":"secondary"}},[_vm._v("mdi-eye-off")]):_vm._e()],1)])],1),_c('v-row',{staticClass:"pt-6",attrs:{"justify":"end","align":"end"}},[_c('v-tooltip-ext',{attrs:{"top":"","z-index":"300","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var onDownload = ref.on;
return [(_vm.resolvedImageUri)?_c('v-btn',_vm._g({staticClass:"mb-n1 mr-2",attrs:{"icon":"","plain":"","href":_vm.resolvedImageUri,"download":"","target":"_blank"}},onDownload),[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e()]}}],null,false,208331929)},[_vm._v(" "+_vm._s(_vm.$t('token.download'))+" ")]),(_vm.tokenInMyWallet)?_c('v-tooltip-ext',{attrs:{"top":"","z-index":"300","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var onTransfer = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-n1 mr-2",attrs:{"icon":"","plain":""},on:{"click":function($event){return _vm.$refs.TokenTransfer.$emit('open', _vm.tokenData)}}},onTransfer),[_c('v-icon',[_vm._v("mdi-send")])],1)]}}],null,false,330991654)},[_vm._v(" "+_vm._s(_vm.$t('token.transfer'))+" ")]):_vm._e(),_c('v-spacer'),_c('v-tooltip-ext',{attrs:{"left":"","z-index":"300","color":"primary","disabled":!_vm.networkName},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var onNetworkLogo = ref.on;
return [_c('div',_vm._g({staticClass:"square-28 mr-4"},onNetworkLogo),[(_vm.networkLogo.icon)?_c('v-icon',{staticClass:"text-shadow",attrs:{"size":"28"}},[_vm._v(_vm._s(_vm.networkLogo.icon))]):(_vm.networkLogo.image)?_c('div',{staticClass:"relative"},[_c('div',{staticClass:"absolute w-100 h-100 ml-1 mt-1"},[_c('img',{staticClass:"w-100 h-100 image-to-shadow",attrs:{"src":_vm.networkLogo.image}})]),_c('v-img',{attrs:{"src":_vm.networkLogo.image,"aspect-ratio":"1"}})],1):_vm._e()],1)]}}],null,false,959260519)},[_vm._v(" "+_vm._s(_vm.networkName)+" ")]),(_vm.tokenData.collection.imageUri)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip-ext',{attrs:{"top":"","z-index":"300","color":"primary","disabled":!_vm.tokenData.collection.name},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var onCollectionLogo = ref.on;
return [_c('div',_vm._g({staticClass:"relative"},onCollectionLogo),[_c('div',{staticClass:"absolute w-100 h-100 ml-1 mt-1"},[_c('img',{staticClass:"w-100 h-100 image-to-shadow",attrs:{"src":_vm.tokenData.collection.imageUri}})]),_c('v-img',{staticClass:"rounded",attrs:{"src":_vm.tokenData.collection.imageUri,"aspect-ratio":"1","alt":_vm.tokenData.collection.name}})],1)]}}],null,false,4163116886)},[_vm._v(" "+_vm._s(_vm.tokenData.collection.name)+" ")])],1):_vm._e()],1),_c('pp-transfer-token',{ref:"TokenTransfer"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }