import { ipfsWebGatewayUri } from '@/data/constants.js'

function shortenHash (hashToShorten) {
  if (!hashToShorten) { return '' }
  if (hashToShorten.length <= (hashToShorten.startsWith('0x') ? 10 : 8)) {
    return hashToShorten
  }
  return hashToShorten.substr(0, 2) === '0x'
    ? (hashToShorten.substr(0, 6) + '...' + hashToShorten.substr(-4))
    : (hashToShorten.substr(0, 4) + '...' + hashToShorten.substr(-4))
}

function uppercaseAddressOrName (addressOrName) {
  if (!addressOrName) { return '' }
  return addressOrName.match(/^0x[0-9a-f]{40}$/i)
    ? '0x' + shortenHash(addressOrName.substr(2)).toUpperCase()
    : (addressOrName.startsWith('0x')
      ? '0x' + addressOrName.slice(2).toUpperCase()
      : addressOrName.toUpperCase()
    )
}

function translateIpfsUri (originalUri) {
  if (!originalUri) { return '' }
  if (originalUri.substr(0, 7) === 'ipfs://') {
    return ipfsWebGatewayUri(originalUri.substr(7))
  }
  return originalUri
}

function hyphenCaseToUpperCamelCase (stringToCast) {
  let name = ''
  stringToCast.split(/-|_/g).forEach(value => {
    name += value.charAt(0).toUpperCase() + value.substring(1)
  })
  return name
}

function hyphenCaseToLowerCamelCase (stringToCast) {
  const stringUpperCamelCase = hyphenCaseToUpperCamelCase(stringToCast)
  return stringUpperCamelCase.charAt(0).toLowerCase() + stringUpperCamelCase.substring(1)
}

function capitalCase (stringToCast, capAll = false) {
  if (!stringToCast) { return '' }
  const capitalize = strToCapitalize => (strToCapitalize.charAt(0).toUpperCase() + strToCapitalize.slice(1).toLowerCase())
  return capAll ? stringToCast.split(' ').map(segment => capitalize(segment)).join(' ') : capitalize(stringToCast)
}

export {
  shortenHash,
  uppercaseAddressOrName,
  translateIpfsUri,
  hyphenCaseToUpperCamelCase,
  hyphenCaseToLowerCamelCase,
  capitalCase
}

export default {
  shortenHash,
  uppercaseAddressOrName,
  translateIpfsUri,
  hyphenCaseToUpperCamelCase,
  hyphenCaseToLowerCamelCase,
  capitalCase
}
