<template>
  <pp-dialog
    v-if="tokenData"
    v-model="transferTokenOpen"
    width="650"
    :card-attrs="{
      color: 'primary'
    }"
    :title="$t('token.transfer')"
    close-icon
    :buttons="[{
      name: 'transfer',
      text: $t('token.transfer'),
      primary: true,
      btnAttrs: {
        loading: isTransfering,
        disabled: isTransfering || !validTransferInputs
      }
    },
    {
      name: 'cancel',
      text: $t('shared.cancel'),
      secondary: true,
      btnAttrs: {
        disabled: isTransfering
      }
    }]"
    @cancel="transferTokenOpen = false"
    @transfer="transferToken"
    class="transfer-token-dialog"
  >
    <v-form @submit.prevent="" v-model="validTransferInputs">
      <v-row justify="center" class="transfer-token-content">
        <v-col cols="24" class="pt-4 text-body-2">
          {{ $t('token.transferDialog.message', {
            name: tokenData.title || ('Token # ' + tokenData.tokenId),
            id: shortenHash(tokenData.tokenId),
            collection: tokenData.collection.name,
            chain: chainTitle
          }) }}
        </v-col>
        <v-col cols="24" class="py-4 px-sm-8">
          <v-text-field
            v-model="transferAddress"
            :placeholder="$t('token.transferDialog.placeholder')"
            background-color="primary"
            color="white-primary"
            class="rounded-0 font-weight-medium transfer-address-text"
            :hint="$t('shared.hintAddress')"
            @keyup.enter="() => isFungibleToken ? $refs.TransferAmount.focus() : transferToken()"
            autofocus
            :rules="[rules.requiredAddress, rules.validAddress, rules.notSameAddress]"
            :disabled="isTransfering"
          >
            <template #prepend-inner>
              <v-icon large color="white-primary">mdi-account-outline</v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="24" sm="18" md="16" class="py-4 px-sm-8">
          <v-text-field v-if="isFungibleToken"
            v-model="transferAmount"
            :placeholder="$t('token.transferDialog.placeholderAmount')"
            background-color="primary"
            color="white-primary"
            class="rounded-0 font-weight-medium transfer-address-text"
            :hint="$t('token.transferDialog.hintAmount')"
            @keyup.enter="transferToken"
            :rules="[rules.requiredAmount, rules.maxAmount]"
            :disabled="isTransfering"
            v-mask="'#'.repeat(78)"
            ref="TransferAmount"
          >
            <template #prepend-inner>
              <v-icon large color="white-primary">mdi-pound</v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </pp-dialog>
</template>

<script>
import PpDialog from '@/components/general/PpDialog.vue'
import validations from '@/validations'
import { shortenHash } from '@/filters'
import { mapGetters } from 'vuex'
import { ethers, BigNumber as ethBigNumber } from 'ethers'
import contractAbis from '@/data/abi'
import { getPoapContractAddress, getChainId, getNativeToken } from '@/data/web3constants'
import { tokenService } from '@/services'

export default {
  components: { PpDialog },
  data () {
    return {
      tokenData: null,
      transferTokenOpen: false,
      transferAddress: '',
      transferAmount: '',
      validTransferInputs: false,
      isTransfering: false
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'userAddress',
      'isWeb3Connected',
      'web3ChainId'
    ]),
    rules () {
      return {
        requiredAddress: value => !!value || this.$t('shared.hintAddress'),
        validAddress: value => validations.validAddress(value, this.$t('shared.hintAddress')),
        notSameAddress: value => ((value || '').toLowerCase() !== this.userAddress) || this.$t('shared.hintAddress'),
        requiredAmount: value => !!value || this.$t('token.transferDialog.hintAmount'),
        maxAmount: value => ethBigNumber.from(value || '0').lte(this.maxAmount) || this.$t('validations.maxQuantityTokens')
      }
    },
    maxAmount () {
      if (!this.tokenData || !this.tokenData.accountBalance) {
        return ethers.constants.MaxUint256
      }
      return ethBigNumber.from(this.tokenData.accountBalance)
    },
    chainTitle () {
      if (!this.tokenData) { return null }
      if (!this.tokenData.networkId) { return null }
      return {
        ethereum: 'Ethereum',
        homestead: 'Ethereum',
        matic: 'Polygon PoS Chain',
        xdai: 'Gnosis Chain'
      }[this.tokenData.networkId] || null
    },
    authToken () {
      return this.$store.getters.token
    },
    isFungibleToken () {
      if (!this.tokenData) { return null }
      if (!this.tokenData.standard) { return null }
      return this.tokenData.standard === 'erc1155' || this.tokenData.standard === 'erc20'
    }
  },
  methods: {
    shortenHash,
    openTokenTransfer (tokenData) {
      this.tokenData = tokenData
      this.transferAddress = ''
      this.transferAmount = ''
      this.transferTokenOpen = true
    },
    transferToken () {
      if ((this.isLoggedIn && this.authToken.scope === 'web3-login') || this.isWeb3Connected) {
        this.isTransfering = true
        return this.transferTokenWeb3()
          .finally(() => {
            this.isTransfering = false
          })
      } else if (this.isLoggedIn && this.authToken.scope === 'web2-login') {
        this.isTransfering = true
        return this.transferTokenWeb2()
          .finally(() => {
            this.isTransfering = false
          })
      }
    },
    async transferTokenWeb3 () {
      const tokenChainId = getChainId(this.tokenData.networkId)
      if (this.web3ChainId !== tokenChainId) {
        await this.$store.dispatch('web3SwitchChain', this.tokenData.networkId)
        await (new Promise(resolve => setTimeout(resolve, 500)))
      }
      const ethSigner = this.$root.ethSigner
      const contractAddr = this.tokenData.type === 'poap'
        ? getPoapContractAddress(this.tokenData.networkId)
        : this.tokenData.contractAddress
      const contractInstance = new ethers.Contract(contractAddr, contractAbis[this.tokenData.standard], ethSigner)

      window.contractInstance = contractInstance
      const tokenIdBN = ethers.BigNumber.from(this.tokenData.tokenId || '0')
      const amountBN = ethers.BigNumber.from(this.transferAmount || '0')

      const ownershipChecker = {
        erc20: async (baseInstance, owner, tokenId, amount) => (await baseInstance.balanceOf(owner)).gte(amount),
        erc721: async (baseInstance, owner, tokenId, amount) => (await baseInstance.ownerOf(tokenId)).toLowerCase() === owner.toLowerCase(),
        erc1155: async (baseInstance, owner, tokenId, amount) => (await baseInstance.balanceOf(owner, tokenId)).gte(amount)
      }

      if (!(await (ownershipChecker[this.tokenData.standard])(contractInstance, this.userAddress, tokenIdBN, amountBN))) {
        this.$store.dispatch('alertShow', { error: 'User is not token owner' })
        throw new Error('User is not token owner')
      }

      const txBuilder = {
        erc20: baseInstance => baseInstance.transfer(
          this.transferAddress,
          amountBN
        ),
        erc721: baseInstance => baseInstance['safeTransferFrom(address,address,uint256)'](
          this.userAddress,
          this.transferAddress,
          tokenIdBN
        ),
        erc1155: baseInstance => baseInstance.safeTransferFrom(
          this.userAddress,
          this.transferAddress,
          tokenIdBN,
          amountBN,
          []
        )
      }

      const gasUnitsEstimation = await (txBuilder[this.tokenData.standard])(contractInstance.estimateGas)
      const gasPriceEstimation = await ethSigner.getGasPrice()
      const gasEstimation = gasUnitsEstimation.mul(gasPriceEstimation)
      const gasEstimationText = Number(ethers.utils.formatEther(gasEstimation)).toFixed(4)

      const gasBalance = await ethSigner.getBalance()
      const gasBalanceText = Number(ethers.utils.formatEther(gasBalance)).toFixed(4)

      const gasSymbol = (getNativeToken(this.tokenData.networkId) || {}).symbol || 'coins'

      if (gasEstimation.gt(gasBalance)) {
        await new Promise((resolve, reject) => {
          this.$root.$emit('launch-msg-dialog', {
            title: this.$t('token.transferDialog.noGasDialog.title'),
            messageHTML:
              `<div class="text-body-2">` +
              `<p>${this.$t('token.transferDialog.noGasDialog.messageLine1', { fee: gasEstimationText, symbol: gasSymbol, balance: gasBalanceText })}</p>` +
              `<p>${this.$t('token.transferDialog.noGasDialog.messageLine2', { symbol: gasSymbol })}</p>` +
              `<p class="text-body-3">${this.$t('token.transferDialog.noGasDialog.messageNote')}</p>` +
              `</div>`,
            persistent: true,
            closeIcon: true,
            width: 650,
            cardAttrs: {
              color: 'primary'
            },
            buttons: [
              {
                name: 'closeBtn',
                text: this.$t('shared.close'),
                primary: true
              }, {
                name: 'stillTransfer',
                text: this.$t('token.transferDialog.noGasDialog.transferAnyway'),
                secondary: true
              }
            ],
            close: reject,
            closeBtn: () => {
              this.$root.$emit('close-msg-dialog')
              reject(new Error('close'))
            },
            stillTransfer: () => {
              this.$root.$emit('close-msg-dialog')
              resolve()
            }
          })
        })
      }

      const txReceipt = await (txBuilder[this.tokenData.standard])(contractInstance)
      await txReceipt.wait()
      this.$root.$emit('token-transfer', this.isFungibleToken ? {
        tokenFullId: this.tokenData.fullId,
        recipient: this.transferAddress,
        amount: this.transferAmount
      } : {
        tokenFullId: this.tokenData.fullId,
        recipient: this.transferAddress
      })
      this.transferTokenOpen = false
    },
    async transferTokenWeb2 () {
      return tokenService.transferToken(
        this.tokenData.networkId,
        this.tokenData.contractAddress,
        this.tokenData.tokenId,
        this.isFungibleToken ? {
          standard: this.tokenData.standard,
          recipient: this.transferAddress,
          amount: this.transferAmount
        } : {
          standard: this.tokenData.standard,
          recipient: this.transferAddress
        }
      )
        .then(() => {
          this.$root.$emit('token-transfer', this.isFungibleToken ? {
            tokenFullId: this.tokenData.fullId,
            recipient: this.transferAddress,
            amount: this.transferAmount
          } : {
            tokenFullId: this.tokenData.fullId,
            recipient: this.transferAddress
          })
          this.transferTokenOpen = false
        })
        .catch(error => {
          this.$store.dispatch('alertShow', { error })
        })
    }
  },
  watch: {
    $route () {
      this.transferTokenOpen = false
    }
  },
  created () {
    this.$on('open', this.openTokenTransfer)
  },
  destroyed () {
    this.$off('open')
  }
}
</script>

<style lang="scss">
  @import '@/styles/main.scss';

  .transfer-token-content {
    .transfer-address-text {
      .v-messages__message {
        text-align: center;
        width: 100%;
      }
    }
  }
</style>
