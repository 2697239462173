import error from './error'

export default {
  lang: {
    code: 'es',
    name: 'Español'
  },
  nav: {
    explore: 'Explorar',
    profile: 'Mi Perfil',
    favorites: 'Favoritos',
    leaderboard: 'Líderes',
    signInWithWallet: 'Ingresar con Wallet',
    changeWallet: 'Cambiar Wallet',
    disconnectWallet: 'Desconectar Wallet',
    logoutSocial: 'Salir del Perfil',
    termsOfService: 'Términos de Servicio',
    privacyPolicy: 'Políticas de Privacidad',
    claim: 'Obtener POAP'
  },
  explore: {
    searchPlaceholder: 'Buscar por Dirección o ENS',
    exploreCollection: 'Explorar mi colección',
    connectAndExplore: 'Conectar y explorar mi colección'
  },
  profile: {
    loadingProfile: 'cargando perfil...',
    couldNotLoad: 'El perfil no pudo ser cargado',
    noPoaps: 'no hay POAPs en el perfil',
    noNfts: 'no hay NFTs en el perfil',
    editNamePlaceholder: 'Edita tu nombre...',
    editBioPlaceholder: 'Edita tu biografía...',
    collectionsPlaceholder: 'Abre para filtrar por colección...'
  },
  favorites: {
    pleaseLogin: 'Por favor ingrese para ver sus tokens favoritos',
    noPoaps: 'no tienes POAPs favoritos',
    noNfts: 'no tienes NFTs favoritos'
  },
  claim: {
    enterCodeMessage: 'Ingresa el código de 6 dígitos:',
    enterCodePlaceholder: 'Código POAP',
    previewPoap: 'Previsualizar POAP',
    claimPoap: 'Obtener POAP para {0}',
    connectAndClaim: 'Conectar y obtener POAP',
    manualClaimMessage: 'o ingresa la dirección manualmente',
    manualClaimPlaceholder: 'Ingresa la dirección o ENS',
    manualClaimAction: 'Obtener POAP Manualmente',
    resetClaimAction: 'Ingresa otro Código POAP'
  },
  widgets: {
    recentSearches: {
      title: 'Búsquedas Recientes',
      empty: 'Borrar historial'
    }
  },
  token: {
    description: 'Descripción',
    attributes: 'Atributos',
    viewOn: 'Ver en',
    otherHolders: 'Otros Asistentes a Este Evento',
    loadingHolders: 'cargando otros asistentes...',
    poapsOwned: '{0} POAPs obtenidos',
    eventDate: 'Fecha del evento',
    mintDate: 'Fecha de obtención',
    city: 'Ciudad',
    country: 'País',
    download: 'Descargar',
    transfer: 'Transferir Token',
    transferDialog: {
      title: 'Transferir Token',
      message: 'Transferir token "{name}" (# {id}) de la collección "{collection}" en la red {chain}',
      placeholder: 'Ingrese dirección o ENS del receptor',
      placeholderRecipient: 'Ingrese la cantidad a transferir',
      hintAmount: 'Número de tokens en formato decimal',
      noGasDialog: {
        title: 'Falta de Gas',
        messageLine1: 'La transaccion de transferencia requiere <strong>{fee} {symbol}</strong> de comisión de gas pero solo tienes <strong>{balance} {symbol}</strong> en tu billetera.',
        messageLine2: 'Por favor deposita <strong>{symbol}</strong> extra a tu billetera para procesar la transferencia.',
        messageNote: '<strong>NOTA</strong>: Las transacciones que requieran más gas que el disponible fallaran y consumirán gas en el proceso.',
        transferAnyway: 'Transferir Igual'
      }
    }
  },
  walletConnect: {
    shortTitle: 'Conectar',
    title: 'Conectar a PlayerProof',
    web2Message: 'Conectar tu cuenta social',
    web3Message: 'o conectar tu billetera Web3'
  },
  signinWeb3: {
    title: 'Ingresar con Web3',
    messageRequest: 'Para ingresar con Web3 debes firmar un mensaje utilizando tu billetera.',
    messageExplain: 'Esto te permitirá editar y curar tu perfil, transferir o raccionar a tokens, o ingresar a eventos exclusivos para tenedores.',
    retry: 'Reintentar firma'
  },
  footer: {
    title: 'Player Proof',
    copyright: '©2022 Player Proof. Todos los derechos reservados.',
    buildHash: 'Hash del paquete'
  },
  error404: {
    error404: 'Error 404',
    routeNotFound: 'Ruta no encontrada',
    backToExplore: 'Volver a Explorar'
  },
  shared: {
    soon: 'Pronto',
    connect: 'Conectar',
    connectWallet: 'Conectar Wallet',
    switchToEth: 'Cambiar a Red ETH',
    hintAddressOrEns: '0x1234...abcd o dominio123.eth',
    hintAddress: '0x1234...abcd',
    claimedBy: 'Obtenido por {0}',
    ownedBy: 'Propiedad de {0}',
    perPage: 'por página',
    save: 'Guardar',
    cancel: 'Cancelar',
    loadingPoaps: 'cargando POAPs...',
    loadingNfts: 'cargando NFTs...'
  },
  validations: {
    maxQuantityTokens: 'La canitdad excede el balance de cuenta'
  },
  error,
  success: {
  }
}
