// import i18n from '@/plugins/i18n'
import { utils as ethUtils } from 'ethers'

function required (value, message) {
  if (Array.isArray(value) && value.length === 0) {
    return message
  }
  return !!value || message
}

function validAddress (value, message) {
  if (!value) { return true }
  const regex = /^0x[0-9a-fA-F]{40}$/
  if (!regex.test(value)) { return message }
  return ethUtils.isAddress(value) || message
}

function validEns (value, message) {
  if (!value) { return true }
  const regex = /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.eth$/
  return regex.test(value.toLowerCase()) || message
}

function validAddressOrEns (value, message) {
  if (!value) { return true }
  return validAddress(value, false) || validEns(value, false) || message
}

function validateFields (validationObject, form = null) {
  const keys = Object.keys(validationObject)

  return keys.every(
    key => validationObject[key].rules(form).every(
      rule => rule(
        validationObject[key].field(form)
      ) === true
    )
  )
}

export default {
  required,
  validAddress,
  validEns,
  validAddressOrEns,
  validateFields
}
