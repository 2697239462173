import service from './main'
import urls from './urls'

const getProfile = (addressOrEns) => service.getRequest(urls.profile.profile, { addressOrEns })
const updateProfile = (addressOrEns, updates) => service.putRequest(urls.profile.profile, { addressOrEns, ...updates })

const resolveNameOffchain = (addressOrEns) => service.getRequest(urls.profile.ens, { addressOrEns, resolveMode: 'resolve-offchain' })
const resolveName = (addressOrEns) => service.getRequest(urls.profile.ens, { addressOrEns, resolveMode: 'resolve' })
const lookupAddress = (addressOrEns) => service.getRequest(urls.profile.ens, { addressOrEns, resolveMode: 'lookup' })
const resolveAvatar = (addressOrEns) => service.getRequest(urls.profile.ens, { addressOrEns, resolveMode: 'avatar' })

const getTokensList = (addressOrEns, type, queryParams = {}) => service.getRequest(urls.profile.tokens, { addressOrEns, type, ...queryParams })
const getReactionsList = (reactionType, type, queryParams = {}) => service.getRequest(urls.profile.reactions, { reactionType, type, ...queryParams })

const updateSocialPlatform = (
  addressOrEns,
  socialPlatformId,
  updateAction,
  updateParams = {}
) => service.putRequest(urls.profile.socialPlatform, {
  addressOrEns,
  social: socialPlatformId,
  action: updateAction,
  ...updateParams
})

export default {
  getProfile,
  updateProfile,
  resolveNameOffchain,
  resolveName,
  lookupAddress,
  resolveAvatar,
  getTokensList,
  getReactionsList,
  updateSocialPlatform
}
