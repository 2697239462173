<template>
  <v-dialog
    v-model="showDialog"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card v-bind="cardAttrs" ref="DialogCard" v-resize="calcNonContentHeight">
      <v-card-title v-if="title || $slots.title">
        <v-row justify="space-between" align="center" class="widget-section-title pb-2 flex-nowrap maxw-100">
          <div class="text-h2 font-weight-regular text-uppercase text-start text-truncate" v-if="!$slots.title">{{ title }}</div>
          <slot name="title"></slot>
          <v-icon v-if="closeIcon" @click="closeModal">mdi-close</v-icon>
        </v-row>
      </v-card-title>
      <v-card-text class="scroll-y" :style="'max-height: calc(90vh - ' + String(nonContentHeight - 20) + 'px)'">
        <slot></slot>
        <div class="flex-grow-1 d-flex justify-center align-center pa-4" v-if="$slots.message">
          <slot name="message"></slot>
        </div>
      </v-card-text>
      <template v-if="buttonsWithDefaults.length">
        <v-divider />
        <v-card-actions class="justify-center flex-row-reverse flex-wrap">
          <v-col
            v-for="(button, $iButton) in buttonsWithDefaults"
            :key="'modalButton' + $iButton"
            v-bind="button.colAttrs"
            v-on="button.colEvents"
          >
            <v-btn
              v-bind="button.btnAttrs"
              v-on="button.btnEvents"
              @click="buttonClick(button, $event)"
            >
              <span v-if="button.text">{{ button.text }}</span>
              <slot v-else :name="button.name">
              </slot>
            </v-btn>
          </v-col>
          <v-spacer v-if="closeIcon && !$vb.xs" />
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  model: {
    prop: 'show',
    event: 'change'
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    closeIcon: {
      type: Boolean,
      default: false
    },
    buttons: {
      type: Array,
      default: () => []
    },
    cardAttrs: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      showDialog: false,
      nonContentHeight: 0
    }
  },
  watch: {
    show: {
      immediate: true,
      handler (newValue) {
        this.showDialog = newValue
      }
    },
    showDialog: function (newValue) {
      setTimeout(() => { this.$root.modalOpen = newValue }, 100)
      if (this.show !== newValue) { this.$emit('change', newValue) }
      if (!newValue) {
        this.$emit('close')
      } else {
        this.calcNonContentHeight()
      }
    }
  },
  methods: {
    closeModal () {
      this.showDialog = false
      this.$emit('manual-close')
    },
    buttonClick (button, $event) {
      this.$emit(button.name, $event)
      if ((button.btnAttrs || {}).to) {
        this.$emit('close', $event)
      }
    },
    calcNonContentHeight () {
      if (!this.$refs.DialogCard || !this.showDialog) { return }
      this.$nextTick(() => {
        if (!this.$refs.DialogCard || !this.showDialog) { this.nonContentHeight = 0; return }
        const titleElement = this.$refs.DialogCard.$el.getElementsByClassName('v-card__title')[0]
        const buttonsElement = this.$refs.DialogCard.$el.getElementsByClassName('v-card__actions')[0]
        this.nonContentHeight = 20 + (titleElement ? titleElement.clientHeight : 0) +
          (this.buttonsWithDefaults.length && buttonsElement ? buttonsElement.clientHeight + 2 : 0)

        this.$emit('non-content-resize', this.nonContentHeight)
      })
    }
  },
  computed: {
    buttonsWithDefaults () {
      const buttonsWithDefaults = this.buttons.filter(button => button)
      buttonsWithDefaults.forEach(button => {
        button.colAttrs ||= {}
        button.colAttrs.cols ||= '24 max-xs'
        button.colAttrs.sm ||= '24 max-sm'
        button.colAttrs.class ||= 'pa-1'
        button.btnEvents ||= {}
        button.colEvents ||= {}
        if (button.primary) {
          button.btnAttrs ||= {}
          button.btnAttrs.color ||= 'accent'
          button.btnAttrs.minWidth ||= '126px'
        } else if (button.secondary || !button.btnAttrs) {
          button.btnAttrs ||= {}
          button.btnAttrs.color ||= 'black-secondary'
          button.btnAttrs.minWidth ||= '126px'
          if (button.btnAttrs.text === undefined) { button.btnAttrs.text = true }
        }
      })
      return buttonsWithDefaults
    }
  }
}
</script>

<style lang="scss">
  @import '@/styles/main.scss';

  .v-dialog {
    margin: 10px!important;
  }

</style>
