const socialPlatforms = (platformList) => [
  {
    id: 'google',
    name: 'Google',
    icon: 'mdi-google',
    logo: '/assets/img/social/google.png',
    getText: () => '',
    getUrl: () => ''
  },
  {
    id: 'facebook',
    name: 'Facebook',
    icon: 'mdi-facebook',
    logo: '/assets/img/social/facebook.png',
    getText: handle => `@${handle}`,
    getUrl: handle => `https://facebook.com/${handle}`
  }, {
    id: 'twitter',
    name: 'Twitter',
    icon: 'mdi-twitter',
    logo: '/assets/img/social/twitter.png',
    getText: handle => `@${handle}`,
    getUrl: handle => `https://twitter.com/${handle}`
  }, {
    id: 'instagram',
    name: 'Instagram',
    icon: 'mdi-instagram',
    logo: '/assets/img/social/instagram.png',
    getText: handle => `@${handle}`,
    getUrl: handle => `https://instagram.com/${handle}`
  }, {
    id: 'linkedin',
    name: 'LinkedIn',
    icon: 'mdi-linkedin',
    logo: '/assets/img/social/linkedin.png',
    getText: handle => decodeURIComponent(handle)
      .replace(/-[0-9a-z]+\d[0-9a-z]+$/, '')
      .split(/[.\-_]/g)
      .map(member => member.charAt(0).toUpperCase() + member.slice(1))
      .join(' '),
    getUrl: handle => `https://www.linkedin.com/in/${handle}`
  }, {
    id: 'twitch',
    name: 'Twitch',
    icon: 'mdi-twitch',
    logo: '/assets/img/social/twitch.png',
    getText: handle => `@${handle}`,
    getUrl: handle => `https://twitch.tv/${handle}`
  }, {
    id: 'youtube',
    name: 'YouTube',
    icon: 'mdi-youtube',
    logo: '/assets/img/social/youtube.png',
    getText: handle => handle,
    getUrl: handle => `https://youtube.com/${handle}`
  }
].filter(platformObj => platformList.includes(platformObj.id))

export { socialPlatforms }
