var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pp-dialog',{staticClass:"transfer-token-dialog",attrs:{"width":"400","card-attrs":{
    color: 'primary'
  },"title":_vm.$t('signinWeb3.title'),"close-icon":"","buttons":[{
    name: 'retry',
    text: _vm.$t('signinWeb3.retry'),
    primary: true,
    btnAttrs: {
      loading: _vm.web3SignatureInProgress || _vm.signingIn || _vm.isLoggedIn,
      disabled: _vm.web3SignatureInProgress || _vm.signingIn || _vm.isLoggedIn
    }
  },
  {
    name: 'cancel',
    text: _vm.$t('shared.cancel'),
    secondary: true
  }]},on:{"retry":function($event){return _vm.$root.$emit('request-signin-signature', _vm.signatureCallback)},"cancel":function($event){_vm.signInWeb3Open = false}},model:{value:(_vm.signInWeb3Open),callback:function ($$v) {_vm.signInWeb3Open=$$v},expression:"signInWeb3Open"}},[_c('div',{staticClass:"text-center pa-4"},[_c('v-icon',{staticClass:"mx-2",attrs:{"x-large":""}},[_vm._v("mdi-wallet-outline")]),_c('v-icon',{staticClass:"mx-2",attrs:{"x-large":""}},[_vm._v("mdi-draw")])],1),_c('div',{staticClass:"text-body-2 text--primary pa-2"},[_vm._v(" "+_vm._s(_vm.$t('signinWeb3.messageRequest' ))+" ")]),_c('div',{staticClass:"text-body-3 pa-2"},[_vm._v(" "+_vm._s(_vm.$t('signinWeb3.messageExplain' ))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }