<template>
  <div v-if="tokenData">
    <div class="relative"
      :class="{
        'quarter-opacity': tokenHidden
      }">
      <div class="absolute w-100 h-100 ml-1 mt-1">
        <img :src="resolvedImageUri" class="w-100 h-100 image-to-shadow" />
      </div>
      <!-- <div v-if="resolvedImageUri && tokenData.type === 'poap'"
        class="absolute w-100 h-100 rounded-circle white"
      ></div> -->
      <v-img
        :src="resolvedImageUri"
        aspect-ratio="1"
        :content-class="resolvedImageUri ? '' : 'accent'"
        :alt="tokenData.name"
        class="rounded"
        ref="TokenImage"
      >
        <div class="w-100 h-100 d-flex justify-center align-center">
          <v-icon x-large color="white" v-if="!resolvedImageUri">mdi-image-off</v-icon>
          <v-icon x-large color="secondary" class="ma-auto" v-else-if="tokenData.hidden">mdi-eye-off</v-icon>
        </div>
      </v-img>
    </div>
    <v-row justify="end" align="end" class="pt-6">
      <v-tooltip-ext top z-index="300" color="primary">
        <template #activator="{ on: onDownload }">
          <v-btn icon plain class="mb-n1 mr-2" :href="resolvedImageUri" download target="_blank" v-on="onDownload" v-if="resolvedImageUri">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>
        {{ $t('token.download') }}
      </v-tooltip-ext>
      <v-tooltip-ext top z-index="300" color="primary" v-if="tokenInMyWallet">
        <template #activator="{ on: onTransfer }">
          <v-btn icon plain class="mb-n1 mr-2" v-on="onTransfer" @click="$refs.TokenTransfer.$emit('open', tokenData)">
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </template>
        {{ $t('token.transfer') }}
      </v-tooltip-ext>
      <v-spacer />
      <v-tooltip-ext left z-index="300" color="primary" :disabled="!networkName">
        <template #activator="{ on: onNetworkLogo }">
          <div class="square-28 mr-4" v-on="onNetworkLogo">
            <v-icon size="28" class="text-shadow" v-if="networkLogo.icon">{{ networkLogo.icon }}</v-icon>
            <div class="relative" v-else-if="networkLogo.image">
              <div class="absolute w-100 h-100 ml-1 mt-1">
                <img :src="networkLogo.image" class="w-100 h-100 image-to-shadow" />
              </div>
              <v-img
                :src="networkLogo.image"
                aspect-ratio="1"
              >
              </v-img>
            </div>
          </div>
        </template>
        {{ networkName }}
      </v-tooltip-ext>
      <v-col cols="6" v-if="tokenData.collection.imageUri">
        <v-tooltip-ext top z-index="300" color="primary" :disabled="!tokenData.collection.name">
          <template #activator="{ on: onCollectionLogo }">
            <div  class="relative" v-on="onCollectionLogo">
              <div class="absolute w-100 h-100 ml-1 mt-1">
                <img :src="tokenData.collection.imageUri" class="w-100 h-100 image-to-shadow" />
              </div>
              <v-img
                :src="tokenData.collection.imageUri"
                aspect-ratio="1"
                :alt="tokenData.collection.name"
                class="rounded"
              >
              </v-img>
            </div>
          </template>
          {{ tokenData.collection.name }}
        </v-tooltip-ext>
      </v-col>
    </v-row>
    <pp-transfer-token ref="TokenTransfer" />
  </div>
</template>

<script>
import PpTransferToken from '@/components/dialogs/PpTransferToken.vue'
import { getNetworkName, getNetworkLogo } from '@/data/web3constants'
import { translateIpfsUri } from '@/filters'
import { mapGetters } from 'vuex'

export default {
  components: {
    PpTransferToken
  },
  props: {
    tokenData: {
      type: Object,
      default: () => ({})
    },
    showForAccount: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'userAddress'
    ]),
    tokenInMyWallet () {
      if (!this.userAddress) { return false }
      if (!this.showForAccount) { return false }
      return this.userAddress.toLowerCase() === this.showForAccount.toLowerCase()
    },
    tokenHidden () {
      if (!this.tokenData) { return false }
      return this.tokenData.hidden
    },
    resolvedImageUri () {
      if (!this.tokenData) { return '' }
      if (!this.tokenData.imageUri) { return '' }
      return translateIpfsUri(this.tokenData.imageUri)
    },
    networkLogo () {
      if (!this.tokenData) { return {} }
      if (!this.tokenData.networkId) { return {} }
      return getNetworkLogo(this.tokenData.networkId) || {}
    },
    networkName () {
      if (!this.tokenData) { return null }
      if (!this.tokenData.networkId) { return null }
      return getNetworkName(this.tokenData.networkId) || null
    }
  },
  watch: {
    tokenData: {
      deep: true,
      handler () {
        this.$forceUpdate()
      }
    }
  }
}
</script>

<style lang="scss">

</style>
