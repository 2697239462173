import en from './en'
import es from './es'

const langs = ['en', 'es']

export default {
  langs,
  en,
  es
}

export {
  langs,
  en,
  es
}
