import Vue from 'vue'
import Vuetify, { VMenu, VSelect, VTooltip } from 'vuetify/lib'
import en from 'vuetify/lib/locale/en'
import es from 'vuetify/lib/locale/es'
import customIcons from '@/components/icons'

Vue.use(Vuetify)

Vue.component('VMenuExt', {
  extends: VMenu,
  props: {
    offsetY: {
      type: Boolean,
      default: true
    }
  }
})
Vue.component('VSelectExt', {
  extends: VSelect,
  props: {
    menuProps: {
      type: [String, Array, Object],
      default: () => ({
        // These are the vuetify defaultMenuProps for VSelect, copied as is
        closeOnClick: false,
        closeOnContentClick: false,
        disableKeys: true,
        openOnClick: false,
        maxHeight: 304,

        // This is the addition this extension is about (to prevent selector menu from overlapping with input field)
        offsetY: true
      })
    }
  }
})
Vue.component('VTooltipExt', {
  extends: VTooltip,
  props: {
    zIndex: {
      type: [Number, String],
      default: 150
    }
  }
})

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: customIcons
  },
  lang: {
    locales: { es, en },
    current: 'en'
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#5d2d91',
        accent: '#ff0046',
        secondary: '#44216a',
        info: '#4D9DE0',
        error: '#BC4B51',
        warning: '#C455A8',
        success: '#3BB273'
      }
    }
  },
  breakpoint: {
    mobileBreakpoint: 480,
    scrollBarWidth: 24,
    thresholds: {
      xs: 480,
      sm: 768,
      md: 1100,
      lg: 1350
    }
  }
})
