import service from './main'
import urls from './urls'
import { blobToDataUrl } from '@/functions'

const uploadUrl = (quantity) => service.getRequest(urls.filesMgmt.uploadUrl, { urls: quantity })

const uploadFile = (url, file) => service.putRequest(url, file, {
  headers: {
    'Content-Type': file.type
  },
  singleFile: true,
  dontBefore: true,
  dontEncodeUrl: true
})

const downloadFile = (url, params, contentMode = 'blob') => service.getRequest(url, params, {
  dontBefore: true,
  dontEncodeUrl: true,
  contentMode
})

const downloadFileToDataUrl = (url, params) => downloadFile(url, params, 'blob').then(blobToDataUrl)

export default {
  uploadUrl,
  uploadFile,
  downloadFile,
  downloadFileToDataUrl
}
