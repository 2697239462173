export default {
  // Frontend errors
  generic: 'Ha ocurrido un error',
  noResults: 'Sin resultados',

  // Web3 errors
  challengeNotSigned: 'Demostración de tenencia no firmada',
  couldntRetrieveHRContentURI: 'No se pudo obtener enlace al contenido de alta resolución',
  fetchingContractData: 'Error al obtener datos del contrato',
  fetchingTokensData: 'Error al obtener datos de los tokens',
  fetchingTokenData: 'Error al obtener datos del token',

  // API errors
  validationErrorHttpMethod: 'Método HTTP no válido',
  validationErrorApiPath: 'Camino de API no válido',
  validationErrorAcceptedContentType: 'Tipo de contenido aceptado no válido',
  validationErrorHeaders: 'Encabezados no válidos',
  validationErrorPathParams: 'Parámetros de ruta no válidos',
  validationErrorQueryParams: 'Parámetros de consulta no válidos',
  validationErrorBodyParams: 'Contenido de cuerpo no válido',
  authTokenUnauthorized: 'No autorizado',
  warmupCronFailed: 'El cron de calentamiento falló',
  rotateSecretsCronFailed: 'Rotar secretos cron falló',
  generatePresignedUrlsFailed: 'Erroras de generación de URL presignadas',
  missingEnvVars: 'Variables de entorno faltantes',
  kmsclientsInitFailed: 'La iniciación del cliente KMS falló',
  generateKeypairFailed: 'Generar KeyPair fallado',
  signHashFailed: 'Falló de la señal hash',
  invalidEnsProfile: 'Perfil de ens no disponible',
  getProfileFailedBanned: 'El perfil ha sido prohibido',
  getProfileReactionsFailed: 'Error de recuperar reacciones de perfil',
  invalidSocialPlatform: 'Plataforma social no compatible',
  socialPlatformAlreadyAdded: 'Plataforma social ya agregada',
  duplicatedSocialPlatform: 'La plataforma social ya existe para otro perfil',
  socialPlatformServiceUnavailable: 'Servicio de plataforma social no disponible',
  socialPlatformRedirectUriMismatch: 'Plataforma social no válida Redirección URI',
  invalidSocialPlatformAction: 'Acción no válida a través de la plataforma social',
  socialPlatformRemovalForbidden: 'La plataforma social no se puede eliminar',
  obtainTokenFailed: 'Error de generación de token de autenticación',
  getClaimFailed: 'Obtener un reclamo fallido',
  claimTokenFailed: 'El token de reclamo falló',
  invalidTokenClientId: 'ID de cliente no válido para la generación de tokens de autenticación',
  refreshTokenExpired: 'Actualizar el token ha expirado',
  obtainTokenInvalidAuthcodeFormat: 'Código de autenticación no válido',
  socialIdentityMismatch: 'Identidad social inválida',
  obtainTokenInvalidSecretFormat: 'Formato secreto no válido',
  obtainTokenInvalidSecretLang: 'Lenguaje secreto no válido',
  obtainTokenInvalidSecret: 'Secreto no válido',
  invalidReaction: 'Reacción no encontrada',
  invalidReactionAction: 'Acción no válida por reacción',
  getTokenInvalidId: 'Token no encontrado',
  invalidTokenBanned: 'Token ha sido prohibido',
  invalidTokenHidden: 'Token oculto por el propietario',
  invalidTokenNotFound: 'Token no encontrado en la cadena',
  getTokenNetworkNotSupported: 'Red token no compatible',
  tokenTypeNotSupported: 'Tipo de token no compatible',
  authTokenInvalidScope: 'Alcance de token de autenticación no válida',
  walletNotFound: 'Billetera no encontrada',
  walletLowBalanceForTx: 'Saldo de la billetera por debajo del requisito de tarifa de gas para transcripción de transferencia'
}
