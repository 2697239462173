<template>
  <div id="urlnotfound-view">
    <v-card class="pa-12 my-12 mx-auto white-primary--border" max-width="300px" outlined>
      <h1 class="text--primary">{{ $t('error404.error404') }}</h1>
      <h2 class="text--primary">{{ $t('error404.routeNotFound') }}</h2>
    </v-card>
    <v-btn
      large
      outlined
      color="white-primary"
      class="mx-auto"
      :to="{ name: 'explore' }"
    >{{ $t('error404.backToExplore') }}</v-btn>
  </div>
</template>

<script>
export default {
  metaInfo () {
    return {
      title: 'PlayerProof - ' + this.$t('error404.error404')
    }
  }
}
</script>

<style>

</style>
