<template>
  <v-app id="app" :class="isLoadingSite ? 'shinny-background' : ''">
    <v-row class="h-100 w-100" align="center" justify="center" v-if="!apiUrlsLoaded">
      <v-progress-circular
        indeterminate
        size="64"
        width="6"
        color="accent"
      />
    </v-row>
    <template v-else>
      <pp-stacked-snackbar ref="StackedSnackba" r/>
      <pp-wallet-controller ref="WalletController" />
      <router-view :loading.sync="isLoadingSite" />
      <v-overlay z-index="300" opacity="0.6" :value="showOverlay" >
        <v-progress-circular indeterminate color="white" />
      </v-overlay>
      <pp-dialog v-if="msgDialog.message || msgDialog.messageHTML" v-model="msgDialogShow" v-bind="msgDialog" v-on="msgDialog"
        @cancel="() => { msgDialogShow = false; if (msgDialog && msgDialog.cancel) { msgDialog.cancel() } }">
        <template #message>
          <div v-if="msgDialog.messageHTML" v-html="msgDialog.messageHTML"></div>
          <span v-else>{{ msgDialog.message }}</span>
        </template>
      </pp-dialog>
    </template>
  </v-app>
</template>

<script>
import Vue from 'vue'
import './styles/main.scss'
import PpStackedSnackbar from '@/components/general/PpStackedSnackbar.vue'
import PpWalletController from '@/components/functional/PpWalletController.vue'
import PpDialog from '@/components/general/PpDialog.vue'
import service from '@/services/main'
import { setBaseUrls } from '@/services/urls'

export default {
  name: 'App',
  components: {
    PpStackedSnackbar,
    PpWalletController,
    PpDialog
  },
  data () {
    return {
      showOverlay: false,
      apiUrlsLoaded: false,
      isLoadingSite: true,
      msgDialog: {
        message: ''
      },
      msgDialogShow: false
    }
  },
  methods: {
    printSelfXSSWarning () {
      console.log(' ') // eslint-disable-line no-console
      console.log(' ') // eslint-disable-line no-console
      console.log('%c   STOP!!!  ', 'background: ' + this.$vuetify.theme.themes.dark.accent + '; color: white; font-size: 48px') // eslint-disable-line no-console
      console.log('%c This browser feature is for developers only. ', 'background: ' + this.$vuetify.theme.themes.dark.secondary + '; color: white; font-size: 24px') // eslint-disable-line no-console
      console.log('%c Please refrain from pasting code or running scripts here. ', 'background: ' + this.$vuetify.theme.themes.dark.secondary + '; color: white; font-size: 24px') // eslint-disable-line no-console
      console.log('%c It may cause your wallet account, your tokens and/or your private data to be compromised. ', 'background: ' + this.$vuetify.theme.themes.dark.secondary + '; color: white; font-size: 24px') // eslint-disable-line no-console
      console.log(' ') // eslint-disable-line no-console
      console.log('%c Please find additional information at ', 'background: black; color: white; font-weight: 500; font-size: 14px') // eslint-disable-line no-console
      console.log('%c http://en.wikipedia.org/wiki/Self-XSS ', 'background: black; color: white; font-weight: 700; font-style: italic; font-size: 16px') // eslint-disable-line no-console
      console.log(' ') // eslint-disable-line no-console
      console.log(' ') // eslint-disable-line no-console
    }
  },
  watch: {
    $route () {
      this.msgDialogShow = false
    }
  },
  created () {
    console.log('$root.web2Ready', this.$root.web2Ready)
    console.log('$root.web3Ready', this.$root.web3Ready)
    this.$store.dispatch('initStore')
    this.apiUrlsLoaded = false
    service.getRequest('/static/config.json', {}, { dontBefore: true })
      .then(fetchResponse => {
        setBaseUrls({
          apiUrl: !window.sessionStorage['api-subdomain'] ? fetchResponse.API_URL : 'https://' + window.sessionStorage['api-prod-subdomain'] + '.playerproof.com/',
          webUrl: !window.sessionStorage['web-subdomain'] ? fetchResponse.WEB_URL || '' : 'https://' + window.sessionStorage['web-subdomain'] + '.playerproof.com/'
        })
        Vue.prototype.$buildNumber = fetchResponse.BUILD_NUM
      })
      .catch(e => {
        setBaseUrls({
          apiUrl: 'https://api.playerproof.com/',
          webUrl: 'https://www.playerproof.com/'
        })
        Vue.prototype.$buildNumber = 'UNKNOWN'
      })
      .finally(() => {
        this.apiUrlsLoaded = true

        const preloadedToken = this.$store.getters.token
        if (preloadedToken.accessToken && preloadedToken.refreshToken && (Date.now() < preloadedToken.expireTime)) {
          this.$store.dispatch('initRefreshToken')
          this.$store.dispatch('fetchUser')
          this.$store.dispatch('fetchPartner')
        }
      })

    this.printSelfXSSWarning()
  },
  mounted () {
    this.$root.$on('set-overlay', overlayValue => { this.showOverlay = overlayValue })

    this.$root.$on('copy-text', (textToCopy, parentElement = document.body) => {
      const copyTextElement = document.createElement('textarea')
      copyTextElement.value = textToCopy
      parentElement.appendChild(copyTextElement)
      copyTextElement.select()
      copyTextElement.setSelectionRange(0, 99999) /* For mobile devices */
      document.execCommand('copy')
      parentElement.removeChild(copyTextElement)
    })

    this.$root.$on('launch-msg-dialog', msgParams => {
      this.msgDialog = msgParams
      this.$nextTick(() => { this.msgDialogShow = true })
    })

    this.$root.$on('close-msg-dialog', () => {
      this.msgDialogShow = false
      this.$nextTick(() => { this.msgDialog = { message: '' } })
    })
  },
  destroyed () {
    this.$root.$off('copy-text')
    this.$root.$off('set-overlay')
    this.$root.$off('launch-msg-dialog')
    this.$root.$off('close-msg-dialog')
  }
}
</script>

<style lang="scss">
  @import '@/styles/main.scss';
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background-color: $background;
    position: relative;
    height: 100%;
    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      transition: opacity 1s;
      background-image: url('/assets/img/background.jpg');
      background-size: auto 100vh;
      background-position: center top;
      background-attachment: fixed;
      background-repeat: no-repeat;
    }
    &.shinny-background:before {
      opacity: 0.7;
    }

    .web3modal-modal-lightbox {
      z-index: 100;
    }
  }
</style>
