var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',_vm._g(_vm._b({},'v-list',_vm.$attrs,false),_vm.$listeners),[_vm._t("before-list"),(_vm.showNav)?[_c('v-list-item',_vm._b({attrs:{"to":{ name: 'explore' }}},'v-list-item',_vm.itemProps,false),[_c('v-list-item-icon',[_c('v-icon',_vm._b({},'v-icon',_vm.iconProps,false),[_vm._v("mdi-magnify")])],1),_c('v-list-item-title',_vm._b({},'v-list-item-title',_vm.titleProps,false),[_vm._v(_vm._s(_vm.$t('nav.explore')))])],1),_c('v-list-item',_vm._g(_vm._b({attrs:{"to":_vm.userAddressOrEns
        ? {
          name: 'profile',
          params: {
            tokens_holder: _vm.userAddressOrEns
          }
        } : undefined,"disabled":!_vm.$root.web2Ready || !_vm.$root.web3Ready}},'v-list-item',_vm.itemProps,false),!_vm.userAddressOrEns
        ? {
          click: function () {
            this$1.$root.$emit('open-wallet-connect', function () { return _vm.$router.push({
                name: 'profile',
                params: {
                  tokens_holder: _vm.userAddressOrEns
                }
              }); }
            )
          }
        } : undefined),[_c('v-list-item-icon',[_c('v-icon',_vm._b({},'v-icon',_vm.iconProps,false),[_vm._v("mdi-account-outline")])],1),_c('v-list-item-title',_vm._b({},'v-list-item-title',_vm.titleProps,false),[_vm._v(_vm._s(_vm.$t('nav.profile')))])],1),_c('v-list-item',_vm._b({attrs:{"to":{ name: 'favs'  },"disabled":!_vm.$root.web2Ready || !_vm.$root.web3Ready}},'v-list-item',_vm.itemProps,false),[_c('v-list-item-icon',[_c('v-icon',_vm._b({},'v-icon',_vm.iconProps,false),[_vm._v("mdi-heart-outline")])],1),_c('v-list-item-title',_vm._b({},'v-list-item-title',_vm.titleProps,false),[_vm._v(_vm._s(_vm.$t('nav.favorites')))])],1),_c('v-list-item',_vm._b({staticClass:"white-secondary--text"},'v-list-item',_vm.itemProps,false),[_c('v-list-item-icon',[_c('v-icon',_vm._b({attrs:{"color":"white-secondary"}},'v-icon',_vm.iconProps,false),[_vm._v("mdi-trophy-outline")])],1),_c('v-list-item-title',_vm._b({},'v-list-item-title',_vm.titleProps,false),[_vm._v(" "+_vm._s(_vm.$t('nav.leaderboard'))+" "),_c('span',{staticClass:"text-body-3 font-italic text-lowercase"},[_vm._v("("+_vm._s(_vm.$t('shared.soon'))+")")])])],1)]:_vm._e(),_vm._t("after-list")],2)}
var staticRenderFns = []

export { render, staticRenderFns }