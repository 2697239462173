export default {
  // Frontend errors
  generic: 'An error has happend',
  noResults: 'No results',

  // Web3 errors
  challengeNotSigned: 'Ownership challenge was not signed',
  couldntRetrieveHRContentURI: 'Couldn\'t retrieve high-resolution content URI',
  fetchingContractData: 'Error fetching contract data',
  fetchingTokensData: 'Error fetching tokens data',
  fetchingTokenData: 'Error fetching token data',

  // API errors
  validationErrorHttpMethod: 'Invalid HTTP method',
  validationErrorApiPath: 'Invalid API path',
  validationErrorAcceptedContentType: 'Invalid accepted content type',
  validationErrorHeaders: 'Invalid headers',
  validationErrorPathParams: 'Invalid path parameters',
  validationErrorQueryParams: 'Invalid query parameters',
  validationErrorBodyParams: 'Invalid body content',
  authTokenUnauthorized: 'Unauthorized',
  warmupCronFailed: 'Warmup cron failed',
  rotateSecretsCronFailed: 'Rotate Secrets cron failed',
  generatePresignedUrlsFailed: 'Error generating presigned URLs',
  missingEnvVars: 'Missing environment variables',
  kmsclientsInitFailed: 'KMS client initiation failed',
  generateKeypairFailed: 'Generate keypair failed',
  signHashFailed: 'Sign hash failed',
  invalidEnsProfile: 'ENS profile unavailable',
  getProfileFailedBanned: 'Profile has been banned',
  getProfileReactionsFailed: 'Error retrieving profile reactions',
  invalidSocialPlatform: 'Unsupported social platform',
  socialPlatformAlreadyAdded: 'Social platform already added',
  duplicatedSocialPlatform: 'Social platform already exists for another profile',
  socialPlatformServiceUnavailable: 'Social platform service unavailable',
  socialPlatformRedirectUriMismatch: 'Invalid social platform redirect Uri',
  invalidSocialPlatformAction: 'Invalid action over social platform',
  socialPlatformRemovalForbidden: "Social platform can't be removed",
  obtainTokenFailed: 'Error generating authentication token',
  getClaimFailed: 'Get claim failed',
  claimTokenFailed: 'Claim token failed',
  invalidTokenClientId: 'Invalid client id for auth token generation',
  refreshTokenExpired: 'Refresh token has expired',
  obtainTokenInvalidAuthcodeFormat: 'Invalid authentication code',
  socialIdentityMismatch: 'Invalid social identity',
  obtainTokenInvalidSecretFormat: 'Invalid secret format',
  obtainTokenInvalidSecretLang: 'Invalid secret language',
  obtainTokenInvalidSecret: 'Invalid secret',
  invalidReaction: 'Reaction not found',
  invalidReactionAction: 'Invalid action for reaction',
  getTokenInvalidId: 'Token not found',
  invalidTokenBanned: 'Token has been banned',
  invalidTokenHidden: 'Token hidden by owner',
  invalidTokenNotFound: 'Token not found on chain',
  getTokenNetworkNotSupported: 'Token network not supported',
  tokenTypeNotSupported: 'Token type not supported',
  authTokenInvalidScope: 'Invalid auth token scope',
  walletNotFound: 'Wallet not found',
  walletLowBalanceForTx: 'Wallet balance below gas fee requirement for transfer transction'
}
