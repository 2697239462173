<template>
  <v-hover v-model="isOnHover">
    <v-avatar color="accent" :size="size" class="relative">
      <template v-if="!value && !previewImage">
        <v-progress-circular
          v-if="isUploading || updating || loading"
          indeterminate
          color="white"
          :size="size * 0.5"
        ></v-progress-circular>
        <v-btn v-else-if="editable"
          icon
          plain
          @click="$refs.avatarInput.click()"
        >
          <v-icon color="white">mdi-upload</v-icon>
        </v-btn>
        <v-icon :size="size * 0.5" v-else>mdi-ethereum</v-icon>
      </template>
      <template v-else>
        <img :src="(isUploading || updating) ? (previewImage || value) : value" />
        <div class="absolute w-100 h-100 d-flex justify-center align-center">
          <v-progress-circular indeterminate color="white" :size="size * 0.5" v-if="isUploading || updating" />
          <v-btn class="pa-0" min-width="36" color="accent" @click='clearImage' v-else-if="isOnHover && editable">
            <v-icon color="white">mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
      <input type="file" ref="avatarInput" name="thumbnail_img" class="d-none" @change="fileChange" :accept="'.' + validExtensions.join(',.')">
    </v-avatar>
  </v-hover>
</template>

<script>
import { filesMgmtService } from '@/services'
// import { md5HashArrayBufferBase64 } from '@/functions'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: true
    },
    updating: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    size: {
      type: [String, Number],
      default: 150
    },
    requireLogged: {
      type: Function,
      default: f => f()
    }
  },
  data () {
    return {
      isOnHover: false,
      isUploading: false,
      previewImage: null,
      validExtensions: [
        'jpg',
        'jpeg',
        'png'
      ]
    }
  },
  watch: {
    value (newValue) {
      this.previewImage = newValue
    }
  },
  methods: {
    fileChange (e) {
      this.isUploading = true
      this.isOnHover = false
      const totalMax = 2000000
      const fileMax = 2000000
      const file = e.target.files[0]

      if (!file) { return }
      const ext = file.name.split('.').pop()
      const readerBytes = new FileReader()

      readerBytes.onloadend = () => {
        /* Exit by file size */
        if (file.size > totalMax) {
          this.isUploading = false
          return this.$store.dispatch('alertShow', { error: this.$t('error.maxAttachmentsSize', [totalMax / 1000000]) })
        }
        this.requireLogged(() => {
          this.upload(new File([file], file.name, { type: file.type }), readerBytes.result)
        })
      }

      readerBytes.onerror = () => {
        this.isUploading = false
      }

      /* Exit by file size */
      if (file.size > fileMax) {
        this.isUploading = false
        return this.$store.dispatch('alertShow', { error: this.$t('error.maxAttachmentsSize', [totalMax / 1000000]) })
      }
      /* Exit by extension or max quantity of files */
      if (this.validExtensions.indexOf(ext) === -1) {
        this.isUploading = false
        return this.$store.dispatch('alertShow', { error: this.$t('error.invalidAttachmentType') })
      }
      readerBytes.readAsArrayBuffer(file)

      const readerPreview = new FileReader()
      readerPreview.onloadend = () => {
        this.previewImage = readerPreview.result
      }
      readerPreview.readAsDataURL(file)
    },
    upload (file, fileContent) {
      let url
      // const md5Hash = md5HashArrayBufferBase64(fileContent)
      filesMgmtService.uploadUrl(1) //, [md5Hash])
        .then(({ urls }) => {
          url = urls[0]
          return filesMgmtService.uploadFile(url.url, file) //, md5Hash, this.$store.getters.token.accessToken.slice(-32))
        })
        .then(fetchResponse => {
          this.$emit('update-avatar', { name: url.name, previewUrl: this.previewImage })
        })
        .catch(e => {
          console.error(e)
          this.$store.dispatch('alertShow', { error: this.$t('error.uploadingFile', { file: document.name }) })
        })
        .finally(() => {
          this.isUploading = false
        })
    },
    clearImage () {
      this.previewImage = null
      this.$emit('update-avatar', {})
      this.isOnHover = false
    }
  },
  mounted () {
    this.$on('open-dialog', () => {
      this.$refs.avatarInput.click()
    })
  }
}
</script>

<style lang="scss">
</style>
