import erc20 from './erc20.json'
import erc721 from './erc721.json'
import erc1155 from './erc1155.json'

export default {
  erc20,
  erc721,
  erc1155
}

export {
  erc20,
  erc721,
  erc1155
}
