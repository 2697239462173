<template>
  <div class="stacked-snackbar-container">
    <v-alert
      v-for="snackbarAlert in snackbarAlerts"
      :key="'snackbar-alert-' + snackbarAlert.id"
      :type="snackbarAlert.type"
      :color="snackbarAlert.color"
      class="mb-2 mx-2"
      elevation="2"
      dismissible
      @input="closeAlert(snackbarAlert.id, $event)"
      close-icon="mdi-close"
    >
      <span class="mr-2 text-body-3 font-weight-medium">{{ snackbarAlert.message }}</span>
    </v-alert>
  </div>
</template>

<script>
export default {
  computed: {
    snackbarAlerts () {
      return this.$store.getters.snackbarAlerts
    }
  },
  methods: {
    closeAlert (alertId, e) {
      if (e) { return }
      this.$store.dispatch('alertsHide', [alertId])
    }
  }
}
</script>

<style lang="scss">
  .stacked-snackbar-container {
    position: fixed;
    top: 8px;
    right: 0;
    z-index: 1000;
    width: 320px;
    .v-alert .v-alert__wrapper {
      .v-alert__icon {
        align-self: center;
      }
      .v-alert__content {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
      }
    }
  }

</style>
