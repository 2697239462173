const oneAllSubdomain = 'playerproof'

const ipfsWebGatewayUri = ipfsHash => ('https://ipfs.io/ipfs/' + ipfsHash)
const explorerAddressUri = addr => ('https://etherscan.io/address/' + addr)
const explorerContractUri = contractAddr => ('https://etherscan.io/address/' + contractAddr + '#readContract')
const explorerTokenUri = (contractAddr, tokenId) => ('https://etherscan.io/token/' + contractAddr + '?a=' + tokenId + '#inventory')

const signinMessage = (
  lang,
  nonce,
  expiresOn
) => ({
  en: `Sign in to PlayerProof\n\nRandom Nonce: ${nonce}\nExpiry Timestamp: ${expiresOn}`,
  es: `Ingresar a PlayerProof\n\nNumero Aleatorio: ${nonce}\nSello de Expiracion: ${expiresOn}`
}[lang])

export default {
  oneAllSubdomain,
  ipfsWebGatewayUri,
  explorerAddressUri,
  explorerContractUri,
  explorerTokenUri,
  signinMessage
}
export {
  oneAllSubdomain,
  ipfsWebGatewayUri,
  explorerAddressUri,
  explorerContractUri,
  explorerTokenUri,
  signinMessage
}
