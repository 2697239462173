// import { fetch } from 'whatwg-fetch'

/** @module */

/**
 * @private
 * @description Get first level object length
 *
 * @param {Object} objectToMeasure
 * @returns {Number}
 */
const objectLength = objectToMeasure => Object.keys(objectToMeasure).length

/**
 * @private
 * @description Serialize params to URL
 *
 * @param {string} [baseUrl='']
 * @param {*} urlParams
 * @returns {String}
 */
const serialize = (baseUrl = '', urlParams) => {
  return objectLength(urlParams) === 0
    ? baseUrl
    : baseUrl +
        '?' +
        Object.keys(urlParams)
          .filter(urlParam => urlParams[urlParam])
          .map(function (urlParam) {
            return encodeURIComponent(urlParam) + '=' + encodeURIComponent(urlParams[urlParam])
          })
          .join('&')
}

const postSerialize = urlParams => {
  return Object.keys(urlParams)
    .filter(urlParam => urlParams[urlParam])
    .map(urlParam => encodeURIComponent(urlParam) + '=' + encodeURIComponent(urlParams[urlParam])).join('&')
}

const defaultError = message => Promise.reject(message)
const callbacks = {
  4: defaultError, /* default handle for 4XX error */
  5: defaultError /* default handle for 5XX error */
}

const setCallback = (number, callback) => {
  callbacks[number] = callback
}

/**
 * @private
 * @description It sends a HTTP request. It's normally called by its synonyms
 *
 * @param {string|Object} [baseUrl='']
 * @param {string} baseUrl.baseUrl
 * @param {string} baseUrl.urlParams
 * @param {Object} [urlParams={}]
 * @param {string} [requestMethod='GET']
 * @param {Object} [requestContent={}]
 * @returns {Promise}
 */
const fetcher = (requestUrl = '', requestParams = {}, requestMethod = 'GET', requestContent = {}) => {
  // Format the url entity/:id/something to entity/1/something (this can be false is there some missing params)
  // Removes any param replace in the url from the requestParams object not to send it twice
  if (typeof requestUrl === 'string') {
    requestUrl = urlMaker(requestUrl, requestParams)
  } else if (typeof requestUrl === 'function') {
    requestUrl = urlMaker(requestUrl(), requestParams)
  }

  // Encode request URL
  if (requestContent.dontEncodeUrl !== true) {
    requestUrl = encodeURI(requestUrl)
  }

  requestContent.method = requestMethod

  // Initializes headers if none received
  if (requestContent.headers == null) {
    requestContent.headers = {}
  }

  if (requestParams instanceof window.File) {
    //  If a file was sent as parameter
    requestContent.body = requestParams
  } else if (objectLength(requestParams) > 0) {
    if (requestMethod === 'GET') {
      // If sending a GET request, requestParams are serialized on the URL
      requestUrl = serialize(requestUrl, requestParams)
    } else {
      // Selects application/json as default content type and serializaes params
      if (requestContent.headers['Content-Type'] == null) {
        requestContent.headers['Content-Type'] = 'application/json'
      }
      if (requestContent.headers['Content-Type'] === 'application/json') {
        // If sending other request methods (POST, PUT, DELETE), requestParams are sent in the request body
        //  as json if application/json content type
        requestContent.body = JSON.stringify(requestParams)
      } else if (requestContent.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        //  or serialized if application/x-www-form-urlencoded content type
        requestContent.body = postSerialize(requestParams)
      } else {
        //  or forwarded directly for other kinds of content type
        requestContent.body = requestParams
      }
    }
  }

  // Prevents Auth token injection if requestor wishes not to
  if (requestContent.dontBefore !== true) {
    doBefore(requestContent)
  }

  if (requestUrl !== false) {
    let failWithTimeout = false
    const controller = new window.AbortController()
    const timeoutId = setTimeout(() => {
      failWithTimeout = true
      controller.abort()
    }, 30000)
    requestContent.signal = controller.signal
    const dateBeforeFetch = new Date()
    return fetch(requestUrl, requestContent)
      .then(fetchResponse => {
        clearTimeout(timeoutId)
        const contentType = fetchResponse.headers.get('content-type') || ''
        let contentMode
        if (requestContent.contentMode) {
          contentMode = requestContent.contentMode
        } else if (contentType.indexOf('application/json') !== -1) {
          contentMode = 'json'
        } else if (contentType.indexOf('text') !== -1) {
          contentMode = 'text'
        } else {
          contentMode = 'arrayBuffer'
        }
        // const contentMode = fetchResponse.headers.get('content-type').indexOf('application/json') !== -1 ? 'json' : 'text'
        if (fetchResponse.status === 200 || fetchResponse.status === 201) {
          // Happy path, everything is OK
          return fetchResponse[contentMode]()
            .then(fetchResponseData => {
              if (callbacks[fetchResponse.status] != null) {
                return callbacks[fetchResponse.status](fetchResponseData)
              } else if (callbacks[2] != null) {
                return callbacks[2](fetchResponseData)
              } else {
                return Promise.resolve(fetchResponseData)
              }
            })
        } else if (fetchResponse.status >= 400 && fetchResponse.status < 500) {
          return fetchResponse[contentMode]()
            .then(fetchResponseData => {
              if (contentMode === 'json') {
                fetchResponseData.requestDate = dateBeforeFetch.toISOString()

                const redactedRequestContent = Object.assign({}, requestContent)
                delete redactedRequestContent.body
                redactedRequestContent.headers.Authorization = (redactedRequestContent.headers.Authorization || '').substr(0, 30) + '****'

                fetchResponseData.requestContent = redactedRequestContent

                fetchResponseData.requestContent.requestUrl = requestUrl
                fetchResponseData.responseStatus = fetchResponse.status
                fetchResponseData.responseDate = (new Date()).toISOString()
                fetchResponseData.responseHeaders = Object.fromEntries(fetchResponse.headers.entries())
              }
              return callbacks[fetchResponse.status] != null
                ? callbacks[fetchResponse.status](fetchResponseData)
                : callbacks[4](fetchResponseData)
            })
        } else if (fetchResponse.status >= 500 && fetchResponse.status < 600) {
          return fetchResponse[contentMode]()
            .then(fetchResponseData => {
              if (contentMode !== 'json') { fetchResponseData = {} }
              fetchResponseData.requestDate = dateBeforeFetch.toISOString()

              const redactedRequestContent = Object.assign({}, requestContent)
              delete redactedRequestContent.body
              redactedRequestContent.headers.Authorization = (redactedRequestContent.headers.Authorization || '').substr(0, 30) + '****'

              fetchResponseData.requestContent = redactedRequestContent

              fetchResponseData.requestContent.requestUrl = requestUrl
              fetchResponseData.responseStatus = fetchResponse.status
              fetchResponseData.responseDate = (new Date()).toISOString()
              fetchResponseData.responseHeaders = Object.fromEntries(fetchResponse.headers.entries())
              return callbacks[fetchResponse.status] != null
                ? callbacks[fetchResponse.status](fetchResponseData)
                : callbacks[5](fetchResponseData)
            })
        }
        return Promise.reject(new Error('Error'))
      })
      .catch(e => {
        clearTimeout(timeoutId)
        if (failWithTimeout) {
          return Promise.reject(new Error('Request timeout'))
        }
        return Promise.reject(e)
      })
  } else {
    return Promise.reject(new Error('Bad params'))
  }
}

/**
 * @description It makes a GET request
 *
 * @param {string} [url=''] Base URL
 * @param {Object} [params={}] Params sendend in url
 * @param {Object} [optionalrequestContent={}] Additionals headers
 * @returns {Promise}
 */
const getRequest = (url = '', params = {}, optionalrequestContent = {}) => fetcher(url, params, 'GET', optionalrequestContent)

/**
 * @description It makes a POST request
 *
 * @param {string} [url=''] Base URL
 * @param {Object} [params={}] Params sendend in url and body
 * @param {Object} [optionalrequestContent={}] Additionals headers
 * @returns {Promise}
 */
const postRequest = (url = '', params = {}, optionalrequestContent = {}) => fetcher(url, params, 'POST', optionalrequestContent)

/**
 * @description It makes a PUT request
 *
 * @param {string} [url=''] Base URL
 * @param {Object} [params={}] Params sendend in url and body
 * @param {Object} [optionalrequestContent={}] Additionals headers
 * @returns {Promise}
 */
const putRequest = (url = '', params = {}, optionalrequestContent = {}) => fetcher(url, params, 'PUT', optionalrequestContent)

/**
 * @description It makes a DELETE request
 *
 * @param {string} [url=''] Base URL
 * @param {Object} [params={}] Params sendend in url and body
 * @param {Object} [optionalrequestContent={}] Additionals headers
 * @returns {Promise}
 */
const delRequest = (url = '', params = {}, optionalrequestContent = {}) => fetcher(url, params, 'DELETE', optionalrequestContent)

/**
 * @private
 * @description Search and replace params in urls
 * @example
 * // returns /api/entity/1/name/myvalue
 * urlMaker('/api/entity/:id/name/:value', { id: 1, value: 'myvalue' })
 * @param {string} [url=''] Base url
 * @param {Object} params Params to replace
 * @returns {string} New URL
 */
const urlMaker = (baseUrl = '', urlParams) => {
  const expression = /:[a-z]+\/?/gi
  let newUrl = baseUrl
  const matches = baseUrl.match(expression)

  if (matches != null) {
    matches.map(fullItem => {
      const urlItem = fullItem.substring(1, fullItem.length).replace('/', '')
      if (newUrl !== false && urlParams[urlItem] != null) {
        // Replaces the parameter on the url
        newUrl = newUrl.replace(':' + urlItem, urlParams[urlItem])
        // Removes param from the params object so it's not sent a second time on the search params
        delete urlParams[urlItem]
      } else {
        newUrl = false
      }
    })
  }

  return newUrl
}

let before = () => {}
const setBefore = callback => {
  before = callback
}
const doBefore = requestContent => {
  before(requestContent)
}

export default {
  getRequest,
  postRequest,
  putRequest,
  delRequest,
  setCallback,
  setBefore
}
