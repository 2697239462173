import error from './error'

export default {
  lang: {
    code: 'en',
    name: 'English'
  },
  nav: {
    explore: 'Explore',
    profile: 'My Profile',
    favorites: 'Favorites',
    leaderboard: 'Leaderboard',
    signInWithWallet: 'Sign-in with Wallet',
    changeWallet: 'Change Wallet',
    disconnectWallet: 'Disconnect Wallet',
    logoutSocial: 'Logout from Profile',
    termsOfService: 'Terms of Service',
    privacyPolicy: 'Privacy Policy',
    claim: 'Claim POAP'
  },
  explore: {
    searchPlaceholder: 'Search by address or ENS',
    exploreCollection: 'Explore my collection',
    connectAndExplore: 'Connect to explore my collection'
  },
  profile: {
    loadingProfile: 'loading profile...',
    couldNotLoad: 'Profile could not be loaded',
    noPoaps: 'no POAPs in the profile',
    noNfts: 'no NFTs in the profile',
    editNamePlaceholder: 'Edit profile name...',
    editBioPlaceholder: 'Edit profile biography...',
    collectionsPlaceholder: 'Open to filter by collection...'
  },
  favorites: {
    pleaseLogin: 'Please log in to view your favorite tokens',
    noPoaps: 'you have no favorite POAPs',
    noNfts: 'you have no favorite NFTs'
  },
  claim: {
    enterCodeMessage: 'Please enter 6 digit claim code:',
    enterCodePlaceholder: 'POAP Code',
    previewPoap: 'Preview POAP',
    claimPoap: 'Claim POAP for {0}',
    connectAndClaim: 'Connect and claim POAP',
    manualClaimMessage: 'or enter your address manually',
    manualClaimPlaceholder: 'Enter address or ENS',
    manualClaimAction: 'Claim POAP Manually',
    resetClaimAction: 'Enter another POAP Code'
  },
  widgets: {
    recentSearches: {
      title: 'Recent Searches',
      empty: 'Clear history'
    }
  },
  token: {
    description: 'Description',
    attributes: 'Attributes',
    viewOn: 'View on',
    otherHolders: 'Other Attendees to This Event',
    loadingHolders: 'loading other attendees...',
    poapsOwned: '{0} POAPs owned',
    eventDate: 'Event date',
    mintDate: 'Mint date',
    city: 'City',
    country: 'Country',
    download: 'Download',
    transfer: 'Transfer Token',
    transferDialog: {
      title: 'Transfer Token',
      message: 'Transfer token "{name}" (# {id}) from the "{collection}" collection on the {chain} network',
      placeholder: 'Enter recipient address',
      placeholderAmount: 'Enter quantity to transfer',
      hintAmount: 'Number of tokens in decimal format',
      noGasDialog: {
        title: 'Gas Shortage',
        messageLine1: 'The transfer transaction requires <strong>{fee} {symbol}</strong> of gas fees but you only have <strong>{balance} {symbol}</strong> in your wallet.',
        messageLine2: 'Please deposit some extra <strong>{symbol}</strong> to your wallet to process this transfer.',
        messageNote: '<strong>NOTE</strong>: Transactions that require more gas than available will fail and consume gas in the process.',
        transferAnyway: 'Transfer Anyway'
      }
    }
  },
  walletConnect: {
    shortTitle: 'Connect',
    title: 'Connect to PlayerProof',
    web2Message: 'Connect with your social account',
    web3Message: 'or connect with your Web3 wallet'
  },
  signinWeb3: {
    title: 'Sign-In with Web3',
    messageRequest: 'To sign-in with Web3 please sign a message using your wallet.',
    messageExplain: 'This will enable you to edit and curate your profile, transfer or react to tokens, or enter holder-exclusive events.',
    retry: 'Retry signature'
  },
  footer: {
    title: 'Player Proof',
    copyright: '©2022 Player Proof. All rights reserved.',
    buildHash: 'Build hash'
  },
  error404: {
    error404: 'Error 404',
    routeNotFound: 'Route not found',
    backToExplore: 'Go back to Explore'
  },
  shared: {
    soon: 'Soon',
    connect: 'Connect',
    connectWallet: 'Connect Wallet',
    switchToEth: 'Switch Chain to ETH',
    hintAddressOrEns: '0x1234...abcd or domain123.eth',
    claimedBy: 'Claimed by {0}',
    ownedBy: 'Owned by {0}',
    perPage: 'per page',
    save: 'Save',
    cancel: 'Cancel',
    loadingPoaps: 'loading POAPs...',
    loadingNfts: 'loading NFTs...'
  },
  validations: {
    maxQuantityTokens: 'Quantity exceeds account balance'
  },
  error,
  success: {
  }
}
