<template>
  <div id="privacy-policy-view">
    <h1 class="text-start text-uppercase">
      <v-icon x-large class="mr-2">mdi-file-document-multiple-outline</v-icon>
      {{ $t('nav.privacyPolicy') }}
    </h1>
    <div id="privacy-policy-content" class="policies-content text-start pt-4 pb-8">
      <h2>1. Introduction</h2>

      <p>The Platform is owned and operated by Pipol LLC ("pipol", "we", "us," or "our" as applicable), with the participation of various partnerships. These Terms of Service ("ToS") constitute the agreement between you (“you”, “yours”, “User”, “Controller”, or “Account Holder” as applicable) and us in all matters regarding your access and use of our Platform. By clicking “Register Me” at your sign-up or "Sign Now” at your Account Upgrade, you confirm your agreement to be bound by these ToS and the Policies referenced herein, as well as consent to receive communications related to our Services and your Account.</p>

      <p>IMPORTANT NOTICE: THESE TOS INCLUDE A MANDATORY ARBITRATION INDIVIDUAL AND CLASS ACTION/JURY TRIAL WAIVER PROVISION THAT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN A JURY TRIAL OR CLASS ACTIONS, AND ALSO LIMITS THE REMEDIES AVAILABLE IN THE EVENT OF A DISPUTE. PLEASE SEE THE “ARBITRATION AGREEMENT & APPLICABLE LAW” SECTION BELOW.</p>

      <h2>2. About Us</h2>

      <p>pipol is a US company and is subject to US laws and jurisdiction. pipol is not a chartered bank or trust company, a wallet, or depository institution. It is not authorized to receive deposits or trust accounts and is not licensed or regulated by any state or federal banking authority. Also, pipol is not an electronic clearinghouse; thus, it cannot compensate for funds or securities.</p>

      <h2>3. About the Platform</h2>

      <h3>3.1. Platform</h3>
      <p>The Platform aims to facilitate transactions between parties by creating formal agreements that might be digitally signed through online interfaces without the need for any special hardware requirements and/or the purchase of any certificate. As per our partnerships, it allows the settlement of such digitally signed agreements by ACH transfers. Furthermore, based on the electronic document storage of digitally signed agreements, the Platform enables the seamless management of the underlying rights digitally set forth therein (including collection rights) and supports new transactions such as transfers or guarantees.</p>

      <h3>3.2. Availability</h3>
      <p>The Platform is only available for transactions performed within the US territory, and which also are payable in the US. Thus, the Platform is not available to facilitate the invoicing or payment of import or export of goods or services, nor to document, pay, or collect payments of transactions made in other jurisdictions than the USA. Only US companies, and US citizens, and lawful permanent residents in the USA, can be Account Holders.</p>

      <h3>3.3. Location</h3>
      <p>Without prejudice to the provisions set forth in Section 3.2. above, these ToS shall apply to all Users regardless of their location.</p>

      <h2>4. ToS</h2>

      <h3>4.1. ToS Language</h3>
      <p>The original version of these ToS, including the Privacy Policies and Cookies Statement ("Privacy Policy"), are written in English. Other language versions of this ToS and Privacy Policy are translated versions for User convenience. Thus, in case of any discrepancy with any translated version, the English language version shall prevail.</p>

      <h3>4.2. Changes to these ToS</h3>
      <p>We may modify these ToS from time to time. The most current version and the prior version of the ToS will be located at the "Terms of Service" section of the Platform. Your access to the Platform is governed by the ToS effective at such access. Unless otherwise provided or authorized by law, the ToS applicable to each Payment Agreement shall be those accepted by the Account Holder at the moment of each Payment Agreement instruction. If we make any material changes to these ToS, they will be notified by email, in-app messaging, or other reasonable means, within 30 days prior notice, unless the application of a shorter term is necessary, including without limitations for reasons of force majeure or to increase the security levels. Your use of the Platform and issue (or grant) of any Communication after the effective date of any changes to the ToS represents your acceptance of such changes. If you do not agree to any change to the ToS, you must terminate your use of the Platform and the issue or acceptance of any new Payment Agreement.</p>

      <h2>5. Definitions</h2>

      <p>The following definitions apply in these ToS:</p>

      <p><strong>Account:</strong> means the account on the Platform opened by the Account Holder agreeing to these ToS, including Personal Account and Business Account.</p>

      <p><strong>Account Holder:</strong> means the individual or company owner of the Account that transacts through the Platform.</p>

      <p><strong>Account Principal Email:</strong> means the email address used by the Account Holder (or Controller) to create the Account or at the Account Upgrade process, and to which notices and communication by us will be sent.</p>

      <p><strong>Account Secondary Email:</strong> means one or more email addresses indicated by the Account Holder on the Platform. You may designate different email addresses to improve your Payments Agreement management. For instance, a specific email address might receive the Communications related to Payment Requests, and another email addresses the Communications of DPO.</p>

      <p><strong>Actual Beneficiary:</strong> means the Beneficiary at the Due Date or later dates in which the Amount Due is paid or returned. The Actual Beneficiary might be the initial Beneficiary or the last Transferee.</p>

      <p><strong>Added Bank Account:</strong> means the financial institution account opened in the Account Holder's name in a US bank, a credit union account at a state or a federally-chartered institution, which has been associated with an Account following the procedure described below in this ToS and referred as a Funding Source in Dwolla ToS.</p>

      <p><strong>Amount Due:</strong> means the amount due by the Issuer or Guarantor, as applicable, according to the Payment Agreement or Payment Transfer, after the deduction of any partial payments made and any amounts returned.</p>

      <p><strong>Auditor:</strong> means the User allowed to view all or some of the information of the corresponding Account but is not authorized to create or sign any Payment Agreement.</p>

      <p><strong>Beneficiary:</strong> means the initial beneficiary of the Payment Agreement.</p>

      <p><strong>Business Account:</strong> means a type of Account held by a company or other legal entity, for such company transactions. This account type is mandatory for companies registered as business owners, corporations, partnerships, and LLCs (as defined in these ToS). Business Account shall be created by its Controller.</p>

      <p><strong>Business Owner:</strong> means sole proprietorship, unincorporated association, and trust.</p>

      <p><strong>Clerk:</strong> means the User allowed to create transactions on behalf of an Account Holder but is not authorized to sign them. The Clerk might also authorize transactions that require Level 1 signatures, such as accept Term Payments for the applicable Account collection.</p>

      <p><strong>Controller:</strong> means the individual who holds significant responsibilities to control, manage, or direct a US company or other US corporate entity (e.g., CEO, CFO, General Partner, President, etc.), and holds the credentials to operate against the bank accounts of the Account Holder. A company may have more than one Controller, but only one Controller's information will be collected (therefore, it should be able to act individually). The Controller of a Verified Account can perform all actions available at the Platform, thus is empowered to sign and execute Payment Agreements on behalf of the Account Holder, authorize ACH transfer against the bank accounts of the Account Holder, and grant or revoke authorization to other Users for acting on behalf of the Account Holder.</p>

      <p><strong>Corporation:</strong> means Corporation, Publicly traded corporations, and Non-profits corporations or LLCs.</p>

      <p><strong>Credit Account:</strong> means the Added Bank Account or the Virtual Balance designated by the Beneficiary for the collection of the Payment Agreement, or by the Issuer of a Direct Payment Order.</p>

      <p><strong>Debit Account:</strong> means the Added Bank Account or the Virtual Balance designated as the paying account by the Issuer or the Guarantor in the applicable Payment Agreement or Payment Transfer with payment guarantee. Therefore, it is the first account whose debit will be attempted to make the Amount Due settlement. In case the funds available in this account were insufficient, the remaining funds will be debited from the Default Account for Payments or Default Account for Guarantees (as applicable), the Virtual Balance, or other Added Bank Accounts.</p>

      <p><strong>Default Account for Guarantees:</strong> means the Virtual Balance or Added Bank Account of the Guarantor against which the Platform will attempt to make the Amount Due settlement in first term if the Designated Debit Account has not money enough to cover the Amount Due in total or partial.</p>

      <p><strong>Default Account for Payments:</strong> means the Virtual Balance or Added Bank Account of the Issuer against which the Platform will attempt to make the Amount Due settlement in the first term if the Designated Debit Account has not money enough to cover the Amount Due in total or partial.</p>

      <p><strong>Default Billing Account:</strong> means the Added Bank Account or Virtual Balance designated as the paying account for the fees, commissions, and other charges that we might bill to the Account Holder.</p>

      <p><strong>Due Date:</strong> means the date/s on which the Payment Agreement shall be settled.</p>

      <p><strong>Dwolla:</strong> means Dwolla, Inc.</p>

      <p><strong>Execution Date:</strong> means the date on which the Payment Agreement has been signed by all the necessary parties.</p>

      <p><strong>Guarantor:</strong> means a Transferor with a Verified Account who guarantees the obligations assumed by the Issuer and prior Transferor/s, within the limits of the applicable guarantee, including the guarantee cap of the Amount Due transferred.</p>

      <p><strong>Issuance Date:</strong> means the date on which the Payment Agreement is signed by the Issuer or the initial Beneficiary (on a Payment Request). For Direct Payments Orders, the Issuance Date shall be the Execution Date.</p>

      <p><strong>Issuer:</strong> means the Account Holder that issues or grants the Payment Agreement. Only an Account Holder with a Verified Account shall be able to issue or grant Term Payments.</p>

      <p><strong>LLC:</strong> means LLCs, partnerships, LP's, and LLP's.</p>

      <p><strong>Mobile App:</strong> means the mobile application software that allows the digital signing of admitted transactions.</p>

      <p><strong>Password:</strong> means the private key that the User will create at the sign-up to access and use the Platform. The Password is subject to minimum security requirements defined by the Platform at its creation. It might be modified from time to time by the User.</p>

      <p><strong>Personal Account:</strong> means a type of Account held by individuals for such individuals' transactions. Only US citizens, or lawful permanent residents, shall be able to open and have Personal Accounts.</p>

      <p><strong>Plaid:</strong> means Plaid, Inc. (f.k.a. Plaid Technologies, Inc.)</p>

      <p><strong>Platform:</strong> means one or more computerized system owned and managed by pipol, with which the Users are registered, and associated with Accounts, and such Account are linked to the bank accounts and virtual wallets owned by the Account Holders to enter or make any admitted transaction in favor or against another Account Holder that accepts and agrees upon the use of the Platform, this ToS, and the specific terms of the Payment Agreement (when applicable).</p>

      <p><strong>Services:</strong> means the services rendered by us through the Platform, including your subscription to marketing or promotional material or participation in any survey that might be conducted to improve our Platform.</p>

      <p><strong>Signer:</strong> means a User empowered on the Platform by the Controller to sign Payment Agreements through the Platform on behalf of the Account Holder. This User role has the same attribute in relation to Payments Agreements as a Controller of a Verified Account, but shall not be allowed to manage other Users upon the applicable Account.</p>

      <p><strong>Transferee:</strong> means the Account Holder who received and accepted the Transferor's credit for the total or part of the Amount Due.</p>

      <p><strong>Transferor:</strong> means the Account Holder which transfers its credit for the total or a part of the Amount Due under a Term Payment to the Transferee. The Payment Transfer might be performed with or without a payment guarantee.</p>

      <p><strong>Ultimate Beneficial Owner (UBO):</strong> means any natural person who, directly or indirectly, owns 25 percent or more of the equity interest of the company. If no individual holds such equity interest, the Platform will require a statement from the Controller confirming it at the Account Upgrade.</p>

      <p><strong>Unverified Account:</strong> means an Account on the Platform whose Account Holder, Controller, and UBO's information and identity have not been verified. These Accounts can receive money from Verified Account through the Platform if they have a verified bank account added and can set Direct Payment Order in favor of Verified Accounts from said accounts.</p>

      <p><strong>User:</strong> means the individual who has filled their basic data to sign-up at the Platform. All the Users will need at least an associate Account to operate on the Platform. Initially, the User profile would be Unverified.</p>

      <p><strong>Username:</strong> means an access identification of the User on the Platform. This identification might be modified from time to time by the User.</p>

      <p><strong>User Principal Email:</strong> means the email address used to sign-up, and to which notices and communications by us will be sent pursuant to these ToS.</p>

      <p><strong>User Secondary Email:</strong> means one or more email addresses appointed by the User. All the emails added by the User to its profile are access identification of the User so that you can use them to log in to the Platform. Also, you might designate a specific email for each Account linked to your User profile.</p>

      <p><strong>Unverified User:</strong> means a User whose information and identity have not been verified.</p>

      <p><strong>Verified Account:</strong> means an Account on the Platform whose Account Holder, Controller, and UBO's information and identity were verified, when applicable. Verified Accounts hold Virtual Balance, sign Term Payments, and send and receive money from their Virtual Balance and Added Bank Accounts.</p>

      <p><strong>Verified User:</strong> means a User whose information and identity have been verified.</p>

      <p><strong>Virtual Balance:</strong> means the funds available in your Account. A Virtual Balance represents funds held in the Account Holder benefit by Dwolla’s financial institution partners, in a sub-account of our Dwolla Master Account. This Virtual Balance will be opened as a result of the successful completion of the Account Upgrade process.</p>

      <p><strong>Website App:</strong> means the https://portal.pipol.com and other related domains or websites, which allow access to the Platform's content.</p>

      <p><strong>Week:</strong> means a seven-day period from Monday to Sunday.</p>

      <h2>6. Use of the Platform</h2>

      <h3>6.1. General Requirements</h3>
      <p>As User, Account Holder, or Controller (as applicable) you declare, as an affidavit, and agree that:</p>
      <p>a. You are at least 18 years of age or the older age applicable in your country for pipol to lawfully provide the Platform services to you without parental consent, including using personal data, and able to agree to these ToS.</p>
      <p>b. You will only have one User profile on the Platform, which must be under your real name.</p>
      <p>c. You have provided us with true, accurate, complete, and current registration information about yourself and the Account Holder, when applicable (the “Registration Data”).</p>
      <p>d. You will update the Registration Data when any relevant information is modified.</p>
      <p>e. Your Password is secret and non-transferable, and it is your responsibility to ensure that it remains confidential and secure. You assume the consequences and risks of its disclosure to third parties, releasing us from all liability arising therefrom.</p>
      <p>f. By registering to the Platform, you agree that you are fully responsible for all activities under your Username and Password. Any communications we receive under your User profile shall be deemed to be made by you.</p>
      <p>g. As Controller, you represent and warrant that the Account Holder is validly existing and in good standing, and it has the power and all necessary authorizations to enter into, perform, and deliver these ToS, the Payment Agreements, and authorized transactions available on the Platform.</p>
      <p>h. As Controller, you represent and warrant that you have the authority to make decisions on behalf of the Account Holder. You represent and warrant that you have the power and authority to enter into these ToS and take decisions on behalf of the Account Holder and agree that we and other Account Holders and Users are entitled to rely on your representations and instructions.</p>
      <p>i. As Account Holder, you represent and warrant that you shall not use the Platform for investment or arbitrage functions or purposes, or any money laundering purposes, or in contravention of any law or regulation.</p>
      <p>j. You are not restricted by us from using the Platform.</p>

      <h3>6.2. User Sign-up</h3>
      <p>On your first access, you must register as a User on the Website App by providing your basic personal data (i.e., first name, last name, business name, and a User Email), create login details (Username and Password), and select the Account type to which your User would be associated (i.e., Personal Account or Business Account). You must also read and agree to these ToS, including our service providers' Terms of Services and Privacy Policies, and our Privacy Policy, as they govern the rights and obligations of the Users, Account Holders, and us in connection with the use of the Platform. Upon such registration, a confirmation request to the User Email provided shall be sent. Users will have seven (7) days to validate the User Email. If the validation does not take place within such period, the registration process will be automatically canceled. User Email verification shall not be required when access happens through a link in an email (i) allowing access to an existing Account, or (ii) regarding a pending payment instruction inviting to sign-up. Once this procedure is completed, a User profile will be created, which will be by default associated with the User’s Personal Account. Such Account shall remain an Unverified Account until its Data Validation is completed.</p>

      <h3>6.3. Create an Account</h3>
      <p>Only US citizens (or legal US residents) and US companies or other legal entities shall be able to create and hold Accounts on the Platform. Only one Personal Account on the Platform can be held by each individual. Business Accounts can be opened and are mandatory for Sole proprietorships, Corporations, LLCs, and Partnerships. When the Account Holder is a Corporation, LLC, or a Partnership, the Business Account shall be opened by its Controller. Once such procedure is complete, the Account Holder shall have an Account on the Platform. Such Account shall remain an Unverified Account until its Data Validation is completed.</p>

      <h3>6.4. Account Upgrade</h3>
      <p>To request the Upgrade of the Account from Unverified to Verified, the Account Holder or the Controller shall file an upgrade form, which must be completed with the business or basic personal data, and the Controller and UBOs basic personal data when applicable, as well as provide supporting documents. The Account Holder or the Controller (as applicable) must also declare it has read, understood, and agreed to these ToS and the Privacy Policy and our service providers' Terms of Services and Privacy Policies. Dwolla will verify the information provided. Upon such verification, the Account will become Verified. Additional information might be requested to complete the Upgrade of the Account from Unverified to Verified.</p>

      <h3>6.5. Virtual Balance</h3>
      <p>As a result of the successful completion of the Account Upgrade process, a Virtual Balance shall be created in the Account Holder's name to settle the transactions registered on the Platform.</p>

      <h3>6.5. Log In</h3>
      <p>Subsequent accesses will be made by entering access User identifications (Username or a User Email) and Password. In case the Password is not remembered, a new one may be requested following the steps indicated on the Website App or the Mobile App.</p>

      <h3>6.6. Unregistered Users</h3>
      <p>When a Beneficiary of a Payment Agreement, other than a Direct Payment Order, is not registered as a User on the Platform, it will receive an email that will display the basic information of the Payment Agreement and the option to access the Platform for further details, create an unverified account, and accept the Payment Agreement. In case a Beneficiary wants to reject the payment offered, creating a User profile is not necessary.</p>

      <h3>6.7. Adding bank accounts</h3>
      <p>A bank account can be added even when the Account is not verified or is undergoing the verification procedure for its upgrade. Only bank accounts opened at the Account Holder name in the USA. Plaid will verify the information provided. After Plaid verification is completed, issuance or transfer of Payment Agreements will be available in case a Verified Client Account is held.</p>

      <h3>6.8. Authorized Users</h3>
      <p>Both the Account Holder and Controller of  a Verified Account can authorize User/s to use the Platform on the Account Holder’s behalf, under a Clerk, Signer, and Auditor role. In all cases, the Account Holder will be responsible for the use of the Platform made by the User associate to its Account.</p>

      <h2>7. Admitted Transactions.</h2>

      <h3>7.1. Payment Agreements (Payments)</h3>
      <p>Issuers, from time to time, may submit Term Payments, Direct Payment Orders, Other Agreements, as well as related agreements or instructions (including Payment Transfer), through the Platform (jointly, "Payment Agreements"). Each Payment Agreement will specify the Beneficiary to receive payment, the Amount Due, Due Date, and other additional data required for the Payment Agreement type. Once the Payment Agreement is signed, the Beneficiary or the Issuer (as applicable) shall be notified through the Platform for its acceptance or grant (when applicable). If the Payment Agreement is rejected, the requesting party shall be notified through the Platform, and the Payment Agreement will be deemed canceled.</p>

      <h3>7.2. Direct Payment Order (DPO)</h3>
      <p>Is an instruction and authorization to make an ACH transfer in favor of the appointed Beneficiary. No pledge is made to the Beneficiary. Beneficiary acceptance is not required. The DPO will be settled only if the Debit Account has sufficient funds to cover the Amount Due.</p>

      <h3>7.3. Other Payments</h3>
      <p>These agreements might be included on the Platform at the request of an Account Holder upon analyzing its risk and technical feasibility by pipol.</p>

      <h3>7.4. Term Payment</h3>
      <p>Is a simplified pledge and payment agreement between its Issuer and its Beneficiary which includes an instruction to the Platform, which has three essential elements: (i) a debt acknowledged of the underlying transaction (for instance, the sale of goods); (ii) an agreement on the payment conditions of such debt in a specific date that will be due on Execution Date or on a future date/s (i.e., the payment will be made upon the acceptance of this Term Payment Agreement, or upon a time period, or even in installments); and (iii) an agreement about the settlement method (i.e., the payment will be made through the Platform by the debit of the Amount Due from the Issuer Added Bank Accounts or its Virtual Balance). The Term Payment shall be issued pursuant to the provisions set forth, these ToS and the Privacy Policy in force at the Issuing Date, and the Issuer domicile law.</p>

      <h3>7.5. Term Payments Types</h3>
      <p>a. "On Acceptance". For this Term Payments, the settlement will be initiated when the receiver accepts such Term Payment (i.e., on the Execution Date). The Beneficiary has a maximum of 30 days from the Issuance Date to accept the Term Payment. Upon the Beneficiary’s acceptance, the Term Payment shall constitute a binding agreement between Issuer and beneficiary. Therefore, it will be a pledge from the Issuer to make the payment pursuant to such terms. In case the 30 day-term elapses without the Beneficiary's acceptance, the Term Payment shall automatically become null and void.</p>
      <p>b. With "Due Date/s on NN days". For this Term Payments, the settlement will be initiated on NN days from the Issuing Date. The Beneficiary will be able to accept the Term Payment until the previous day to the Due Date or first installment Due Date (if applicable). Upon the Beneficiary's acceptance, the Term Payment shall constitute a binding agreement between Issuer and Beneficiary. Therefore, it will be a pledge from the Issuer to make the payment pursuant to such terms. In case the Beneficiary rejects the Term Payment or does not accept it, the Term Payment shall automatically become null and void.</p>

      <h3>7.6. Available actions on Term Payments.</h3>
      <p>a. Create and Request. Only Verified Accounts shall be able to issue Term Payments. A Term Payment Issuance offer or request can be revoked at any time before acceptance takes place. Once the offer or request has been accepted, it cannot be revoked, the incompliance of it would be a breach of the Term Payment agreement. If an Unverified Account requests a Term Payment issuance in its favor to another Unverified Account, we shall request the applicant to conduct an Account Upgrade. However, if the Issuer continues to hold an Unverified Account at the granting time, a Direct Payment instruction will be issued instead of a Term Payment.</p>
      <p>b. Single Payment and Installments. Payments can be made in full on one date or in up to 12 installments.</p>
      <p>c. Payment Transfer. A Beneficiary or Transferee may assign its rights to receive whole or part of the Amount Due. The Issuer, Transferor/s, and Guarantor/s authorize Dwolla and us to facilitate the applicable ACH payment for the assigned Amount Due to the Actual Beneficiary or Beneficiaries. </p>
      <p>d. Transfer with Payment Guarantee - Sponsored Transfer. The Transferor may add its payment guarantee if it has a Verified Account and the Term Payment transferred is not in default or with its payment suspended by all its obligated parties. The Transferor (now Guarantor) becomes primarily liable for the payment of the transferred Amount Due and waives the requirement that the Transferee first takes actions against the Issuer or any other Guarantor. Upon the Issuer’s default, the Guarantor irrevocably authorizes and instructs Dwolla and us to perform the stated procedures to collect the unpaid Amount Due.</p>
      <p>e. Automatic Transfer. In case the Guarantor or a Transferor without guarantee honoured the Amount Due unpaid, the Platform will automatically transfer the credit to such Guarantor orTransferor, who will be able to claim the repayment to the Issuer and/or its prior Guarantors.</p>
      <p>f. Return. The Actual Beneficiary of a Term Payment might return the total or part of such Term Payment to the Issuer or its previous Transferor. In case the Term Payment, or part, is returned to the issuer, then that returned part or total of the Term Payment will be canceled and any guarantees associated with that payment will also be canceled. If the Term Payment, or part, is returned to its previous Transferor, then any guarantee associated to this return will not be registered. Nevertheless the debt acknowledged and agreed in the Term Payment returned might remain valid between the Beneficiary or Transferee and the Issuer or Transferor.. Note that we might apply a fee for return transactions. For further information, please see our <a href="https://www-stage-internal-url.pipol.com/#pricing">Fees & Charges Chart</a>.</p>
      <p>g. Change Debit Account or Deposit Account. After the Execution Date and up to the Settlement date, the Actual Beneficiary, Issuer, or Guarantor (as applicable) might instruct us to change the Debit Account or the Deposit Account to another Added Bank Account or even the Virtual Balance.</p>
      <p>h. Manual Settle & Manual Collect. Once the Due Date has elapsed, the Issuer or Guarantors might instruct the settlement of the total or partial Amount Due from their Added Bank Account or their Virtual Balance. In the same line, once the Due Date has elapsed, the Actual Beneficiary might request the collection of the Amount Due from the Issuer and Guarantors. Note that we might apply a Fee for manual settlement or manual collection transactions. For further information, please see our <a href="https://www-stage-internal-url.pipol.com/#pricing">Fees & Charges Chart</a>.</p>

      <h3>7.7. Invoice</h3>
      <p>As an additional feature to your Payment Agreements, you can create the invoices attached to your Payment Requests, which would be delivered to the Issuer (your debtor) through the Platform.</p>

      <h3>7.8. Representations, Warranties, and Covenants of the Issuer and Transferor</h3>
      <p>As Issuer or Guarantor (as applicable), you represent, warrant, and covenant to the Beneficiary, Transferee/s, Actual Beneficiary, and us that:</p>
      <p>a. Your use of the Platform is solely to settle genuine and lawful commercial transactions, arising in the ordinary course of business, for the purchase of goods or services by you, as Issuer or Guarantor, from the Beneficiary, Transferee/s, or Actual Beneficiary (as applicable). Each Payment Agreement or Payment Transfer constitutes Issuer and Guarantor's irrevocable acceptance of an agreement to pay the Actual Beneficiary the Amount Due specified in such Payment Agreement or Payment Transfer on the applicable Due Date/s for the goods or services related to it. </p>
      <p>b. Registered Information and other data provided by Issuer to us, connected with this ToS and each Payment Agreement, shall be true and accurate in all material aspects. Our service providers and we are hereby authorized to verify such information, either according to the "General Requirement" Section above, or otherwise as we reasonably determine.</p>
      <p>c. Each Payment Agreement or applicable part of such Payment Agreement (i) is of Transferor exclusive property, free and clear of all security interests, liens or claims of any kind; (ii) is based on a sale of goods or services that (A) have been delivered or settle for future delivery to and accepted by the relevant Issuer or the previous Transferor, (B) in relation to which all of the initial Beneficiary or previous Transferor's obligations have been performed by it in full, and (C) which complies with all applicable legal requirements; (iii) constitutes a valid, binding and unconditional obligation of the relevant Issuer and previous Transferor/s to pay the full amount of such Payment Agreement or Payment Transfer, free of any defense, set-off or counterclaim; and (iv) is not disputed by Issuer, the previous Transferor/s, or any other person, and is not the subject of any legal or arbitral proceeding.</p>

      <h3>7.9. Suspend Payment Agreement</h3>
      <p>As Issuer and Guarantor, you might suspend the ACH transfer instruction settlement given at a Payment Agreement. In such a case, your Account will also be suspended, and a suspension charge might also be applicable. We will inform all your creditors (by issuance and by guarantee) and your Authorized Users about the suspension of your Account and the suspension of the specific transaction. You will not be able to issue nor grant any Payment Agreement (including Payment Transfer with guarantee). If a Payment Agreement issued by you is transferred after the suspension of your Account, we might inform about the Account status or the specific Term Payment status to the possible new Beneficiary (Transferee). At any time after your payment suspension, you might unsuspend it. If you unsuspend all your Payment Agreements (previously suspended at your request), you will reinstate the payment methods to their original setting and your Account will be unsuspended. If any Debit Account has become unavailable during such suspension, the Platform will be set to debit the applicable Amount/s Due from other Added Bank Account/s or the Balance. Be aware that the suspension charge will not be reimbursed under any circumstances.</p>

      <h3>7.10. Request Refund</h3>
      <p>As Issuer or Guarantor of Payment Agreement, you might request through the Platform for the reimbursement of any amount paid by you, to your original counterparty (i.e., the Beneficiary or Transferee of the Payment Agreement you paid). This request will be managed as a request for a Direct Payment Order. Therefore, no pledge is made to the refund Beneficiary. As the refund Beneficiary, you understand that this request aims to make the refund process easier, better, and faster. However, it does not replace, nor does it constitute any advice or complaint letter that you might have to give pursuant to the Cooling-Off Rule and/or any other applicable consumer protection rule, regulation, law, statute, private agreement, store return or exchange policy, or consumer guarantee policy. Also, as the refund Beneficiary, you understand and undertake that the refund shall not be deemed as an acknowledgment of any debt or admission of commission of false advertising or any other deceptive practice by the Issuer. Any refund request or grant might only be considered a voluntary payment by the refund Issuer or supplementary note to any agreement or arrangement with the Issuer.</p>

      <h3>7.11. Payment Reversals</h3>
      <p>If, as per a Payment Agreement, you receive an ACH payment that is later reversed or invalidated, you are responsible for the reimbursement of the full amount of the payment sent to you, and a reversal or chargeback fee might also be applicable. Whenever a transaction is reversed, our service provider Dwolla will refund or reverse the transaction by the debit of your Virtual Balance (for Verified Account) in the same amount as the original transaction. If your Virtual Balance becomes negative, that negative balance represents an amount you owe to us, and herein you agree to repay us such negative balance. In that line, as Account Holder, you authorize us to debit your Billing Bank Account, and all your Added Bank Accounts, until you cancel the negative balance.</p>

      <h2>8. Settlement.</h2>

      <p><strong>8.1.</strong> As Account Holder, you understand and agree that any payment instruction made through the Platform will be facilitated by the Dwolla payment system from or to the Virtual Balance and/or the Added Bank Accounts.</p>
      <p><strong>8.2.</strong> The processing of the payment instruction will be performed by ACH (a) automatically, on the payment Due Date/s, or (b) manually, after the applicable payment Due Date, upon the Issuer, Guarantor or Actual Beneficiaries (as applicable) request, provided that sufficient funds are available in the Debit Account, the Balance, or available Added Bank Accounts. If the funds are insufficient, the payment shall be settled on a pro-rata basis and another similar process will be performed a certain number of times in order to complete the amounts due from the payment instructions.</p>
      <p><strong>8.3.</strong> The status of the Payment Agreements, including the default of payment or collection, will be reported at the Platform and by email following the provisions of the Section “The Platform Services/ Consent to receive Electronic Communications” below. Neither pipol nor Dwolla are responsible nor liable for any damage if any email does not reach its recipient.</p>
      <p><strong>8.4.</strong> Users, Account Holders, and us will consider that any Communication sent and received through the Platform to be valid, legitimate, and authentic of the authorized User and the Account Holder, without the need to make or take any other safeguards of any kind, expressly waiving to oppose any defense based on the failure of accreditation of the use of the Password, assuming as an essential condition of the use of the Platform any consequence of its use on the Account Holder behalf.</p>

      <h2>9. Fees & Limits</h2>

      <h3>9.1. Fees Chart</h3>
      <p>The Account Holder shall pay us such fees, commissions, and other amounts for the Services provided by or through the Platform, as outlined in the <a href="https://www-stage-internal-url.pipol.com/#pricing">Fees & Charges Chart</a> attached to these ToS (collectively, "Fees"). Such Fees are subject to change, at pipol discretion, within 30 days of notification. New Fees will be applicable only for the transactions performed after such fees change.</p>

      <h3>9.2. Billing</h3>
      <p>We shall invoice the Account Holder monthly indicating the total amount payable. Payments shall be debit first from the Default Billing Account and, if any amount remains unpaid, from the Balance or any other Added Bank Account.</p>

      <h3>9.3. Limits</h3>
      <p>We may, at our own discretion, set limits on the quantity of Term Payments as well as the maximum amount and aggregated total amount you can issue, transfer, guarantee and receive as issuer or beneficiary. Said amounts will be communicated and enforced at the time of issuance, acceptance, transfer or grant.</p>

      <h2>10. The Platform Services</h2>

      <h3>10.1. Consent to receive Electronic Communications</h3>
      <p>By accepting this ToS, you consent to receive all communications from other User, Account Holders, and us electronically. Such communications will be provided either (i) on the Web App or the Mobile App of the Platform, or (ii) to the Account Emails or the User Emails associated with your User profile or Account. You might customize the types of notifications and emails we sent to you and the frequency you would like to receive them, by visiting your Profile Setting section on the Platform, and then click on “Notifications”. You will be considered to have received our Communications (including those that must be made in written by law) when we send it to your Account Principal Email or User Principal Email (as applicable), or the physical address we have in our records for you, or when we post such notice on the Platform.</p>

      <h3>10.2. Communications on the Platform</h3>
      <p>You understand that the Platform aims to promote commercial credit in your community, and therefore it is of utmost importance that the Users and Account Holders members be trustworthy people. You shall use the Platform to upload your Account Registration Data or your User profile and preferences, make any available action on Payment Agreements, give us instructions, and to contact us (jointly the "Communications''). Any Communication sent or deemed to have been sent by the Account Holder or us through the Platform is valid and binding on such Account Holder and us, as well other Account Holders and Users, who are entitled to rely thereon, irrespective of any error or fraud contained therein or the identity of the individual who sent the Communication. As Account Holder you agree that the act of sending a Communication electronically in accordance with this ToS is as legally binding as if Account Holder had manually executed and delivered that Communication in written form, and that you will not contest the validity, legally binding nature, or enforceability of that Communication on the basis that the act of sending such Communication electronically is invalid or not binding on it.</p>

      <h3>10.3. Electronic Signature</h3>
      <p>By accepting this ToS, you agree to sign your Communications on the Platform electronically. The Platform allows you to sign your Communications electronically with just a click of a button on the Platform and with two security levels. The Level 1 signatures have a single factor for authentication (that is something that you know) and only require your Log-in to the Platform with your User identifications (Username or any User Email) and your Password to allow you to sign. The Level 2 signatures require a 2nd factor authentication, which means that, in addition to request something that you know, you will verify your identity by using something that you have - either using a Time-based One-time Password Algorithm (TOTP) provided by our Mobile App, Google Authenticator, or other authenticator-supporting third party application; or by filing at the Platform a randomized 6-digit code sent by the Platform via SMS to your phone number (which shall be previously verified). Only the Controller and the Signer/s of a Verified Account might have Level 2 signatures and will be able to use the Mobile App to sign Term Payments. Level 2 signatures provides an extra layer of protection, therefore it will be required to (i) Issue, Transfer with payment guarantee, or Grant Payment Agreements that makes a pledge of payment to the Beneficiary, Transferee, or Actual Beneficiary; (ii) instruct the manual settlement of an unpaid Payment Agreement, when such request is made by a Transferor who does not guarantee the payment of said Term Payment; (iii) instruct the return of a credit on the total or partial Amount Due of an outstanding or unpaid Payment Agreement to its Issuer or Transferor; (iv) make statements as affidavit; or (iii) to provide with any other Communication that, as per its nature, we might deem necessary to provide with a stronger security level.</p>

      <h3>10.4. Platform Language</h3>
      <p>The Platform was created in the English language version. Other language versions of the Platform have been translated by us for your convenience. Thus, any discrepancy or differences created in translations are not binding and have no legal effect. Also, bear in mind that the Communications forms supported on the Platform are written in English and no translated version will be provided.</p>

      <h3>10.5. Templates Disclaimer</h3>
      <p>All Communications templates (including Payment Agreements template) supported by the Platform were created willing to boost commercial credit. They pretend to be simple documents, balanced, flexible for both parties (Issuer-Beneficiary; Transferor-Transferee; Issuer-Actual Beneficiary) but do not constitute legal advice. We are not a law firm and we are not providing legal advice. All Communications templates to be addressed to your clients or suppliers, are provided without any warranty, express or implied, regarding their terms legal effect, or completeness. You shall fill these templates (following the Platform flow) to meet your own individual needs and the laws of your state. Your use of any template is at your own risk (since we could develop Other Agreements at your request). We and any of our employees, contractors, or attorneys who participated in providing the templates  expressly disclaim any warranty: they are not creating or entering into any Attorney-Client relationship by providing the samples to you.</p>

      <h3>10.6. License Grant</h3>
      <p>Subject to these ToS, pipol hereby grants you a limited, personal, non-exclusive, non-transferable license and right, without the right to further sublicense, to access and use the Platform via the Web App and the Mobile App, solely for the purposes contemplated by this ToS and during the term of this agreement (the "License"). Except as expressly set forth in these ToS, you shall have no other right (including any ownership right or intellectual property right), title or interest to or in the Platform or any portion thereof.</p>

      <h3>10.7. Warranty Disclaimer, Platform services available on "AS-IS" Basis</h3>
      <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE PLATFORM SERVICES ARE PROVIDED TO YOU ON AN "AS-IS" AND "AS AVAILABLE" BASIS. pipol DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE TOS. pipol MAKES NO REPRESENTATIONS, WARRANTIES OR GUARANTEES, EXPRESS OR IMPLIED, REGARDING THE ACCURACY, RELIABILITY OR COMPLETENESS OF THE SERVICE, AND EXPRESSLY DISCLAIMS ANY WARRANTIES OF NON-INFRINGEMENT OR FITNESS FOR A PARTICULAR PURPOSE. FURTHER, pipol DOES NOT WARRANT THAT THE FUNCTIONALITY OF THE PLATFORM WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THEY WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY THIRD-PARTY SERVICE IN CONNECTION THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK.</p>

      <h3>10.8. Security</h3>
      <p>You shall safeguard and maintain reasonable security measures to safeguard the Platform. In furtherance of the foregoing, you represent, warrant, and agree that you will not contribute any content or otherwise use the Platform or interact with it in a manner that:</p>
      <p>a. Infringes or violates the intellectual property rights or any other rights of anyone else (including our rights on the Platform or our service providers rights on the services their render);</p>
      <p>b. Violates any law or regulation or this ToS;</p>
      <p>c. Jeopardizes the security of your User or Account or anyone else's (such as allowing someone else to log in to the Platform as you);</p>
      <p>d. Attempts, in any manner, to obtain the Password, account, or other security information from any other User;</p>
      <p>e. Violates the security of any computer network, or cracks any passwords or security encryption codes;</p>
      <p>f. Runs Mail-list, Listserv, any form of auto-responder or "spam" on the Platform, or any processes that run or are activated while you are not logged into the Platform, or that otherwise interfere with the proper working of the Platform (including by placing an unreasonable load on the Platform' infrastructure) or that would bypass the navigational structure or presentation of the Platform;</p>
      <p>g. Introduce into any portion of the Platform any virus or other data or code that harms, or may adversely affect, the operation of the Platform;</p>
      <p>h. "Crawls," "scrapes," or "spiders" any page, data, or portion of or relating to the Platform or the Payment Agreements (through use of manual or automated means);</p>
      <p>i. Link to, mirror, or frame any part of the Platform;</p>
      <p>j. Decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Platform;</p>
      <p>k. Circumvent, removes, alters, deactivates, degrades, or thwarts any technological measure or content protections of the Platform; or</p>
      <p>l. Interfere with, defeat, circumvent or tamper with any Payment Agreement or other information or instruction that is to be transmitted through the Platform, or with the restrictions on the use of functionality or access to information on any portion of the Platform, or attempt to do so.</p>

      <h3>10.9. User and/or Account Suspension</h3>
      <p>If there are or are suspected of illegalities, fraud or any other act that infringes or contradicts these ToS or the current legal regulations, pipol reserves the irrevocable right to: (i) not process Payment Agreements, instructions, ACH, or any other transaction or Communication; and (ii) suspend for an indeterminate time to the User and/or the Account Holder. If we find or suspect that the Account Holder’s information or documentation provided is incorrect, we may suspend the Account's performance on the Platform. In none of the cases, the suspension of the User or the Account Holder's performance on the Platform will generate the right to claim any compensation. The execution of any such measures shall not result in any liability for pipol.</p>

      <h2>11. Service Providers</h2>

      <h3>11.1. Partnership with Dwolla, Inc.</h3>
      <p>In order to use the payment functionality of the pipol platform, you must open a "Dwolla Platform" account provided by Dwolla, Inc. and you must accept the <a href="https://www.dwolla.com/legal/tos/">Dwolla Terms of Service</a> and <a href="https://www.dwolla.com/legal/privacy/">Privacy Policy</a>. Any funds held in the Dwolla account are held by Dwolla's financial institution partners as set out in the <a href="https://www.dwolla.com/legal/tos/">Dwolla Terms of Service</a>. As Account Holder, you authorize us to collect and share with Dwolla your personal information including full name, date of birth, social security number, physical address, email address and financial information, and you are responsible for the accuracy and completeness of that data. You acknowledge and understand that you will access and manage this specific Dwolla account (and/or Virtual Balance) through the pipol platform, and Dwolla account notifications will be sent by us, not Dwolla. We will provide customer support for your Dwolla account activity, and we can be reached through any alternative mentioned below at the Section "Contact" of these ToS. </p>

      <h3>11.2. Partnership with Plaid Technologies, Inc.</h3>
      <p>We use Plaid to gather end-user data from Financial Institutions. You hereby grant both Plaid and us the right, power, and authority to act on your behalf to access and transmit your personal and financial information. You acknowledge and agree to the transferring, storing, and processing of your personal and financial information by Plaid and us in accordance with our Privacy Policy and the <a href="https://plaid.com/legal/#consumers">Plaid Privacy Policy</a>.</p>

      <h2>12. Limitation of Liability</h2>

      <h3>12.1. Errors</h3>
      <p>Users and Account Holders are responsible for the information provided when using the Platform, taking responsibility for any errors in uploading the information they may have made by themselves or through the Users authorized by them.</p>

      <h3>12.2. Relationship between the Account Holders</h3>
      <p>pipol (and its service providers) do not intervene in the Issuer, Beneficiaries, Transferors, Transferee, Actual Beneficiaries, and Guarantors’ commercial or consumer relationship. The Issuer, Transferor and Guarantor (if any) expressly assume all liability to the Beneficiaries and Transferees and undertakes to hold harmless us and our service providers, our officers, directors, legal representatives, employees, agents, affiliates and/or controlling persons and to indemnify them, including but not limited to, legal fees and costs that reasonably liable or damage or judicial, administrative or extrajudicial claim that they submit on the occasion or occasion of the operations carried out with the Issuer, Transferor and Guarantor (if any), as well as for any sanctions or claims that may be imposed on it by consumer protection agencies or commercial loyalty or any other competent body. Likewise, the Issuer, Transferor, and Guarantor shall be solely and exclusively responsible for the performance of the services and products that they market or render, and must strictly comply with the legal rules in force before any claim. Any claim by the Beneficiaries, Transferee, or Actual Beneficiary regarding the products and services they may have purchased must be made directly to the Issuer, Transferor and Guarantor, as the case may be.</p>

      <h3>12.3. Limitation of Liability.</h3>
      <p>a. We shall be entitled to rely on any communication sent or purported to be sent by the Account Holder, irrespective of any error or fraud contained in the communication or the identity of the individual who sent the communication, and shall not be liable for any action taken or omitted in reliance on any notice, direction, consent, certificate, affidavit, statement, designation or other paper or document reasonably believed by it to be genuine and to have been duly and properly signed or provided by the Account Holder. Therefore, we shall not be liable for (among others) any discrepancies between the Amount Due, payment Due Dates or any other data of the Payment Agreements in relation to the content or scope of the actual Debt, according to contracts, agreements, electronic invoices, personal records of the Account Holders, or other document supporting the main Payment Agreement or their related agreements (such as Payment Transfer), the Debt or underlying transaction.</p>
      <p>b. We shall not be liable for any losses arising out of or relating to our actions or omissions to act hereunder, except to the extent that any such losses are caused by our gross negligence or willful misconduct.</p>
      <p>c. We shall not be deemed to be in default of any of the obligations required to be performed by us under this ToS to the extent that performance thereof is delayed, hindered or becomes impossible because of any act of God or public enemy, hostilities, war (declared or undeclared),  terrorist activities, an act of sabotage, earthquake, flood, hurricane, storm, explosion, fire, labor disturbance, strike, riot, epidemic, an act of government, power interruption or transmission failure or any cause of similar nature beyond its reasonable control.</p>
      <p>d. In no event shall we be responsible or liable to you or any third party, whether in contract, warranty, tort (including negligence), or otherwise, for any indirect, special incidental, consequential, exemplary, liquidated or punitive damages, including but not limited to loss of profit, revenue or business, arising in whole or in part from your use or misuse of the Platform, for transactions made, not made or even cancelled through it, or for any loss or damage caused by your reliance on information obtained on or through the Platform, even if we have been advised of the possibility of such damages.</p>
      <p>e. Notwithstanding the foregoing, our Liability to the Account Holder or any third party, for any cause whatsoever and regardless of the form of the action, will at all times be limited up to USD 500 (five hundred united states dollars). Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the fullest extent permitted by law. Therefore, the foregoing limitations shall apply to the maximum extent permitted by law. Nothing in these ToS affects statutory rights that cannot be waived or limited by contract.</p>

      <h3>12.4. No Implied Duties</h3>
      <p>We shall be obliged to perform such duties and only such duties as are expressly set forth herein, and no implied duties or responsibilities shall be read or implied into this ToS against us.</p>

      <h3>12.5. System Failures</h3>
      <p>We shall not be liable, regardless of the cause, for the delay or failure suffered in the access and operation of the Platform, for the data uploaded by the Account Holder and their authorized Users, as well as for interruptions, suspensions or malfunctions of the Website or the Mobile App, or for any direct or indirect expenses, damages, losses, or damages caused by computer viruses or system failures of any kind, that will affect you.</p>

      <h2>13. Indemnity</h2>

      <p>You agree to indemnify us and our officers, directors, employees, successors, agents, and affiliates and/or controlling persons, for any claims, damages, losses and causes of action (including attorneys' fees and court costs) arising out of or relating to your breach of this ToS or for any materials or content in any form whatsoever that are provided by you (or through your Username and Password). You agree to cooperate as fully as reasonably required in our defense or settlement of any claim. We reserve the right, in our reasonable discretion, to assume exclusive control over the defense and settlement of any matter subject to indemnification by you.</p>

      <h2>14. Intellectual Property</h2>

      <p>The trademarks, logos, service marks, designs, inventions, developments, models, software, texts, pictures, images, and other content of any kind involved with the Services provided on the Platform whether registered or unregistered (collectively the “Trademarks”) displayed on the Website App and the Mobile App are Trademarks of pipol or others third parties (such as our service providers). You acknowledge and accept the ownership mentioned above of pipol and undertake not to question it, as well as not to disseminate, copy, reproduce, sell, or otherwise commercially use them without the prior written authorization.</p>

      <h2>15. Miscellaneous</h2>

      <h3>15.1. Waivers; Severability</h3>
      <p>No delay or failure of pipol in exercising or enforcing any right or provision of this ToS constitutes a waiver of such or of any other right, privilege, or option. If any provision of these ToS is found to be unenforceable or invalid by an arbitration court of competent jurisdiction, the remaining provisions’ validity shall not be affected thereby.</p>

      <h3>15.2. Termination; Account Closure</h3>
      <p>a. You may terminate the ToS at any time by closing your Account, which includes the close of your Virtual Balance (if applicable). To close your Account, you must withdraw your money from your Virtual Balance, and you cannot owe money to us (by our billing or your Virtual Balance negative balance). There can be no outstanding, unpaid, or suspended issued, received, or guaranteed Payment Agreements, nor any transaction in process. The Account Holder or the Controller (as applicable) needs to detach all the Users associated to the Account (otherwise, the Platform will automatically detach them). To complete this request, you shall enter your Password and your 2nd factor authentication (if you have one). Be aware that this action is NON-REVERSIBLE.</p>
      <p>b. We may, in our sole discretion, suspend, limit, or cancel your Account and your access, or the access of any User associated to your Account, to and use of the Platform, at any time for any reason, without notice or liability to you, including, but not limited to, if we suspect that your access to or use of the Platform violates these ToS or applicable law. Upon the cancelation of your Account, you must cease all use of the Platform.</p>
      <p>c. Upon your Account Closing, you agree that we may retain your data, including personal and transaction information, for ten years from the date of termination for tax audit purposes, fraud prevention procedures, commercial planning and KPI (key performance indicators) construction.</p>
      <p>d. Provisions of these ToS that, by their nature, should survive termination of your Account (including the limitation of liability provisions contained in this ToS) will survive such termination.</p>

      <h3>15.3. Notices</h3>
      <p>We will send all notices and other communications regarding the Services to you at your User Principal Email or your Account Principal Email (as applicable) following the provisions of the Section “The Platform Services/ Communications on the Platform” above; or to the physical address you provided for your Account Holder and User, as may be updated by you from time to time.</p>
      <p>Except as otherwise provided in these ToS, all notices to us that are intended to have a legal effect must be delivered via Email to <a href="mailto:support@pipol.com">support@pipol.com</a>. All such notices are deemed effective upon document receipt by us.</p>

      <h2>16. Arbitration Agreement & Applicable Law</h2>

      <p>PLEASE READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES YOU TO ARBITRATE CERTAIN DISPUTES AND CLAIMS WITH pipol AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM US.</p>
      <p>Certain portions of this section are deemed to be a "written agreement to arbitrate" pursuant to the Federal Arbitration Act. You and us hereby agree that this section satisfies the "writing" requirement of the Federal Arbitration Act.</p>

      <h3>16.1. Informal Dispute Resolution</h3>
      <p>We would like an opportunity to address your concerns without a formal legal case. Before filing a claim against us, you agree to try to resolve the dispute informally by sending an email to <a href="mailto:support@pipol.com">support@pipol.com</a>. We will try to resolve the dispute informally by contacting you in writing via email. If a dispute is not resolved within 30 calendar days of submission to us, you or we may bring a formal proceeding. All offers, promises, conduct, and statements, whether oral or written, made in the course of the negotiation by any of the parties, their agents, employees, and attorneys are confidential, privileged, and inadmissible for any purpose, including as evidence of liability or impeachment, in arbitration or other proceeding involving the parties, provided that evidence that is otherwise admissible or discoverable shall not be rendered inadmissible or non-discoverable as a result of its use in the negotiation.</p>

      <h3>16.2. Arbitration Agreement</h3>
      <p>To the fullest extent permitted by applicable law, you and pipol agree that any controversy, allegation, or claim arising out of or relating to these ToS, the Platform or the Services, including but not limited to claims for indemnification, contribution, or cross-claims in a pending action involving one or more third parties (collectively, a "Dispute") –excluding Disputes in which you or pipol seeks to bring an individual action in small claims court or seeks injunctive or other equitable relief for the alleged unlawful use of the intellectual property – shall be finally and exclusively resolved individually by final and binding arbitration before a sole arbitrator under the Consumer Arbitration Rules of the American Arbitration Association in effect at the time arbitration is initiated (the "AAA Rules"). The AAA Rules are available online at www.adr.org.</p>
      <p>You and pipol further agree to the following with respect to the arbitration of any Dispute hereunder: (a) any claim must be brought in your individual capacity, and not as a plaintiff or class member in any purported class action or representative proceeding; (b) the arbitrator may not consolidate more than one person's claims, may not otherwise preside over any form of a representative or class proceeding and may not award class-wide relief; (c) the arbitration will be held at Delaware unless you and we both agree to another location or virtual arbitration; (d) in the event that you are able to demonstrate that the costs of arbitration will be prohibitive as compared to the costs of litigation, we will pay as much of your filing and hearing fees in connection with the arbitration as the arbitrator deems necessary to prevent the arbitration from being cost-prohibitive as compared to the cost of litigation; (e) each party will pay the administrative and arbitrator's fees and other expenses in accordance with the applicable arbitration rules, but we reserve the right, in our sole discretion, to assume responsibility for any or all of the fees and costs of the arbitration; (f) the arbitration will be confidential, and neither you nor we may disclose the existence, content, or results of any arbitration, except as may be required by applicable law or for purposes of enforcement of the arbitration award; (g) the arbitrator may award any individual relief or individual remedies that are expressly permitted by applicable law; and (h) each party will pay its own attorneys' fees and expenses, unless there is a statutory provision that requires the prevailing party to be paid its fees and litigation expenses and the arbitrator awards such attorneys' fees and expenses to the prevailing party, and, in such instance, the fees and costs awarded will be determined by the applicable law.</p>
      <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE THAT (I) NO ARBITRATION SHALL BE JOINED WITH ANY OTHER; (II) THERE IS NO RIGHT OR AUTHORITY FOR ANY DISPUTE TO BE ARBITRATED ON A CLASS-ACTION BASIS OR TO UTILIZE CLASS ACTION PROCEDURES; AND (III)THERE IS NO RIGHT OR AUTHORITY FOR ANY DISPUTE TO BE BROUGHT IN A PURPORTED REPRESENTATIVE CAPACITY ON BEHALF OF THE GENERAL PUBLIC OR ANY OTHER PERSONS.</p>
      <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE TO WAIVE YOUR RIGHT TO A JURY TRIAL AND UNDERSTAND THAT ABSENT THIS PROVISION; YOU WOULD HAVE THE RIGHT TO SUE IN COURT. THE SCOPE OF THIS WAIVER IS INTENDED TO BE ALL-ENCOMPASSING OF ANY AND ALL DISPUTES THAT MAY BE FILED IN ANY COURT, AND THAT RELATE TO THE SUBJECT MATTER OF THESE TERMS OF USE, INCLUDING WITHOUT LIMITATION, CONTRACT CLAIMS, TORT CLAIMS, AND ALL OTHER COMMON LAW AND STATUTORY CLAIMS.</p>

      <h3>16.3. Governing Law</h3>
      <p>These ToS are governed by and construed in accordance with the laws of the State of Delaware, U.S.A., without giving effect to any conflict of law principles, except as may be otherwise provided in the Arbitration Agreement above or in supplemental terms applicable to your jurisdiction. The foregoing choice of law and forum selection provisions do not apply to the Arbitration Agreement or to any arbitrable disputes as defined therein. Instead, the Federal Arbitration Act (FAA) and AAA Rules shall preempt all state laws to the fullest extent permitted by law.</p>

      <h3>16.4. Exceptions to Arbitration; Jurisdiction</h3>
      <p>Claims that cannot be arbitrated (if any) are subject to the exclusive jurisdiction in, and the exclusive venue of, the state and federal courts located within the State of Delaware, USA, and you consent to the personal jurisdiction of these courts to litigate any such claim.</p>

      <h2>17. California Consumer Rights Notice</h2>

      <p>Under California Civil Code Section 1789.3, California users of the Platform receive the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at (800) 952- 5210.</p>

      <h2>18. Contact</h2>

      <p>If you have questions, comments, or complaints regarding these ToS, the Platform or any of the services provided by us, you may contact us through our "Contact" section of the Platform at the Website App, or emailing us at <a href="mailto:support@pipol.com">support@pipol.com</a>. In case we contact you, we would not require any User identifications but the Support PIN available in the Platform.</p>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo () {
    return {
      title: 'PlayerProof - ' + this.$t('nav.privacyPolicy')
    }
  }
}
</script>

<style lang="scss">
  @import '@/styles/main.scss';

</style>
