import service from './main'
import urls from './urls'
import { cleanNetworkId } from '@/data/web3constants'

const openSeaChainIdentifier = {
  ethereum: 'ethereum',
  matic: 'matic'
}
const getOpenSeaChainIdentifier = networkId => openSeaChainIdentifier[cleanNetworkId(networkId)]

const getAssetsFromOpenSeaV2 = (networkId, ownerAddress, nextUrl) => {
  if (nextUrl && nextUrl.startsWith(urls.external.openseaV2.assets())) {
    return service.getRequest(nextUrl, {}, { dontEncodeUrl: true })
  }
  const chainIdentifier = getOpenSeaChainIdentifier(networkId)
  return chainIdentifier ? service.getRequest(urls.external.openseaV2.assets, {
    chain_identifier: chainIdentifier,
    owner_address: ownerAddress
  }) : Promise.reject(new Error('Unsupported network'))
}

const getAssetsFromPoapV1 = ownerAddress => service.getRequest(urls.external.poap.scan, {
  address: ownerAddress
})

const getAssetsForEventFromPoapV0 = (eventId, limit = 10, offset = 0) => service.getRequest(urls.external.poap.eventPoaps, {
  eventId,
  limit,
  offset
})
const MAX_LIMIT_ASSETS_FOR_EVENT_POAP = 300

export default {
  openSeaChainIdentifier,
  getOpenSeaChainIdentifier,
  getAssetsFromOpenSeaV2,
  getAssetsFromPoapV1,
  getAssetsForEventFromPoapV0,
  MAX_LIMIT_ASSETS_FOR_EVENT_POAP
}
