<template>
  <pp-dialog
    v-if="tokenData"
    v-model="detailsOpen"
    max-width="1000"
    :card-attrs="{
      color: 'primary'
    }"
  >
    <v-row justify="center" align="center" class="pa-12" v-if="preventPreRender">
      <v-progress-circular indeterminate color="white" />
    </v-row>
    <v-row justify="center" align="start" class="pt-8 pb-2" v-else>
      <v-col cols="24" md="10" class=" d-none d-md-block py-2 px-4">
        <pp-token-and-tools :token-data="tokenData" :show-for-account="showForAccount" />
      </v-col>
      <v-col cols="24" md="14" class="px-sm-4 pr-md-0">
        <v-row justify="space-between" align="center" class="widget-section-title pb-2 flex-nowrap maxw-100">
          <div class="text-h2 font-weight-regular text-uppercase text-start text-truncate">{{ tokenData.name || ('Token # ' + tokenData.tokenId) }}</div>
          <v-spacer class="pp-token-details-title-spacer"/>
          <v-icon @click="detailsOpen = false" class="ml-4">mdi-close</v-icon>
        </v-row>
        <v-row justify="space-between" align="center" class="flex-nowrap maxw-100 pt-2">
          <div class="text-start text-h3 pb-4" v-if="tokenData.collection.name">
            {{ tokenData.collection.name }}
            <v-btn icon x-small plain class="ml-2" :href="tokenData.collection.externalUrl" target="_blank" v-if="tokenData.collection.externalUrl">
              <v-icon small>mdi-open-in-new</v-icon>
            </v-btn>
          </div>
          <v-btn large icon @click="updateHidden(!tokenData.hidden)" :ripple="false" plain :loading="isUpdatingToken || loadingExtededData" :disabled="isUpdatingToken" v-if="tokenInMyWallet">
            <v-icon :color="tokenData.hidden ? 'secondary' : 'white-primary'" x-large>{{ tokenData.hidden ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
          </v-btn>
          <v-btn large icon @click="updateReaction('fav', !favState)" :ripple="false" plain class="relative" :loading="isUpdatingReaction || loadingExtededData" :disabled="isUpdatingReaction" v-else>
            <v-icon class="absolute" :color="favState ? 'accent' : 'white-primary'" x-large>{{ favState ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
            <v-icon color="white-primary" x-large>mdi-heart-outline</v-icon>
          </v-btn>
        </v-row>
        <div class="d-md-none py-4 pp-token-details-centered-token">
          <pp-token-and-tools :token-data="tokenData" />
        </div>
        <template v-if="tokenData.description">
          <div class="text-start text-h3 font-weight-medium text-uppercase mx-4 pt-2">
            {{ $t('token.description') }}
          </div>
          <div class="text-start pl-6 pr-4 py-2">
            {{ tokenData.description }}
          </div>
        </template>
        <template v-if="tokenData.attributes && tokenData.attributes.length">
          <div class="text-start text-h3 font-weight-medium text-uppercase mx-4 pt-2">
            {{ $t('token.attributes') }}
          </div>
          <v-row class="pl-4 pr-2 py-2 text-start">
            <v-col
              v-for="(trait, $iTrait) of tokenData.attributes"
              :key="'trait-' + $iTrait"
              cols="6"
              class="px-2"
            >
              <div class="text-body-3 font-weight-bold">{{ capitalCase(trait.type) }}</div>
              <div class="text-body-2">{{ trait.value }}</div>
            </v-col>
          </v-row>
        </template>
        <template v-if="tokenData.type === 'poap'">
          <div class="text-start text-h3 font-weight-medium text-uppercase mx-4 pt-2 d-flex justify-space-between" style="border-bottom: 1px solid white">
            <div>
              {{ $t('token.otherHolders') }}
            </div>
            <div>#{{ formattedNumberOfHolders }}</div>
          </div>
          <div class="py-8 mx-auto maxw-50" v-if="isLoadingOtherHolders && (!otherHolders || !otherHolders.length)">
            <div class="pb-2">
              {{ $t('token.loadingHolders') }}
            </div>
            <v-progress-linear indeterminate color="white" />
          </div>
          <div class="pl-6 pr-6 py-2" v-else-if="otherHolders">
            <v-virtual-scroll
              :bench="5"
              :items="otherHolders"
              height="140"
              item-height="28"
            >
              <template v-slot:default="{ item: holder }">
                <v-row
                  justify="space-between"
                  class="py-1"
                  style="border-bottom: 1px solid #FFFFFF40"
                >
                  <v-btn text small class="px-1 h-100 text-none" :to="{ name: 'profile', params: { tokens_holder: holder.owner } }">
                    <v-icon x-small class="pb-2p">mdi-ethereum</v-icon>
                    {{ uppercaseAddressOrName(holder.owner) }}
                  </v-btn>
                  <div class="mr-1">
                    {{ $t('token.poapsOwned', [holder.ownerPoapsOwned]) }}
                  </div>
                </v-row>
              </template>
            </v-virtual-scroll>
          </div>
        </template>
        <template v-else>
          <div class="text-start text-h3 font-weight-medium text-uppercase mx-4 pt-2">
            {{ $t('token.viewOn') }}
          </div>
          <div class="pl-6 pr-4 py-2 d-flex justify-start">
            <v-btn :href="viewOnOpenSeaUrl" target="_blank" class="mr-2" icon v-if="viewOnOpenSeaUrl">
              <div class="relative">
                <img src="assets/img/opensea.png" width="32" height="32" class="absolute ml-2p mt-2p w-100 h-100 image-to-shadow-small" />
                <img src="assets/img/opensea.png" width="32" height="32" class="relative" />
              </div>
            </v-btn>
            <v-btn :href="viewOnRaribleUrl" target="_blank" class="mr-2" icon v-if="viewOnRaribleUrl">
              <div class="relative">
                <img src="assets/img/rarible.png" width="32" height="32" class="absolute ml-2p mt-2p w-100 h-100 image-to-shadow-small" />
                <img src="assets/img/rarible.png" width="32" height="32" class="relative" />
              </div>
            </v-btn>
            <v-btn :href="viewOnLooksRareUrl" target="_blank" class="mr-2" icon v-if="viewOnLooksRareUrl">
              <div class="relative">
                <img src="assets/img/looksrare.png" width="32" height="32" class="absolute ml-2p mt-2p w-100 h-100 image-to-shadow-small" />
                <img src="assets/img/looksrare.png" width="32" height="32" class="relative" />
              </div>
            </v-btn>
          </div>
        </template>
      </v-col>
    </v-row>
  </pp-dialog>
</template>

<script>
import PpDialog from '@/components/general/PpDialog.vue'
import PpTokenAndTools from '@/components/widgets/PpTokenAndTools.vue'
import { uppercaseAddressOrName, capitalCase } from '@/filters'
import { getOpenSeaUrl, getRaribleUrl, getLooksRareUrl } from '@/data/web3constants'
import { externalApisService, tokenService } from '@/services'
import { mapGetters } from 'vuex'

export default {
  components: {
    PpDialog,
    PpTokenAndTools
  },
  props: {
    showForAccount: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      preventPreRender: true,
      tokenData: null,
      detailsOpen: false,
      isLoadingOtherHolders: false,
      otherHolders: null,
      fetchHoldersTimeout: 0,
      loadingExtededData: true,
      isUpdatingToken: false,
      isUpdatingReaction: false
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'userAddress'
    ]),
    tokenOwner () {
      if (!this.tokenData) { return null }
      if (this.tokenData.owner) { return this.tokenData.owner }
      if (this.tokenData.owners) { return this.tokenData.owners[0] || null }
      return null
    },
    tokenInMyWallet () {
      if (!this.userAddress) { return false }
      if (!this.tokenOwner) { return false }
      return this.userAddress === this.tokenOwner.toLowerCase()
    },
    viewOnOpenSeaUrl () {
      if (!this.tokenData || !this.tokenData.networkId || !this.tokenData.contractAddress || this.tokenData.tokenId === undefined || this.tokenData.tokenId == null) {
        return null
      }
      return getOpenSeaUrl(this.tokenData.networkId, this.tokenData.contractAddress, this.tokenData.tokenId)
    },
    viewOnRaribleUrl () {
      if (!this.tokenData || !this.tokenData.networkId || !this.tokenData.contractAddress || this.tokenData.tokenId === undefined || this.tokenData.tokenId == null) {
        return null
      }
      return getRaribleUrl(this.tokenData.networkId, this.tokenData.contractAddress, this.tokenData.tokenId)
    },
    viewOnLooksRareUrl () {
      if (!this.tokenData || !this.tokenData.networkId || !this.tokenData.contractAddress || this.tokenData.tokenId === undefined || this.tokenData.tokenId == null) {
        return null
      }
      return getLooksRareUrl(this.tokenData.networkId, this.tokenData.contractAddress, this.tokenData.tokenId)
    },
    formattedNumberOfHolders () {
      if (!this.otherHolders) { return '000' }
      return String(this.otherHolders.length).padStart(3, '0')
    },
    favState () {
      if (this.tokenInMyWallet) { return null }
      console.log('comute favState')
      return this.tokenData.requesterReactions ? this.tokenData.requesterReactions.includes('fav') : false
    }
  },
  methods: {
    openDetails (item, preloaded = false) {
      this.preventPreRender = true
      this.tokenData = null
      this.tokenData = Object.assign(item, {
        hidden: item.hidden || false,
        requesterReactions: item.requesterReactions || []
      })

      const getTokenPromise = this.getToken()
      if (preloaded) {
        this.preventPreRender = false
        this.getOtherHolders()
      } else {
        getTokenPromise.then(this.getOtherHolders)
      }
      this.detailsOpen = true
    },
    getToken () {
      this.loadingExtededData = true
      return tokenService.getToken(
        this.tokenData.networkId,
        this.tokenData.type === 'poap' ? 'poap' : this.tokenData.contractAddress,
        this.tokenData.tokenId,
        this.showForAccount
      )
        .then(fetchResponse => {
          const attributesData = this.tokenData.attributes || []
          const retrievedAttrs = fetchResponse.attributes || []
          retrievedAttrs.forEach(newAttr => {
            const attributeInArray = attributesData.find(prevAttr => prevAttr.type === newAttr.type)
            if (attributeInArray) {
              attributeInArray.value = newAttr.value
              attributeInArray.displayType = newAttr.displayType
            } else {
              attributesData.push(newAttr)
            }
          })
          const collectionData = {
            ...(fetchResponse.collection || {}),
            ...(this.tokenData.collection || {})
          }
          const eventData = {
            ...(this.tokenData.event || {}),
            ...(fetchResponse.event || {})
          }
          this.tokenData = Object.assign(this.tokenData, {
            ...fetchResponse,
            imageUri: this.tokenData.imageUri,
            hidden: fetchResponse.hidden || false,
            requesterReactions: fetchResponse.requesterReactions || [],
            attributes: attributesData,
            collection: collectionData,
            event: eventData
          })

          if (this.tokenData.banned || (this.tokenData.hidden && !this.tokenInMyWallet)) {
            this.detailsOpen = false
          }
          this.preventPreRender = false
        })
        .catch(error => {
          this.$store.dispatch('alertShow', { error })
        })
        .finally(() => {
          this.loadingExtededData = false
        })
    },
    getOtherHolders (offset = 0, timeout = 100) {
      if (this.tokenData.type === 'poap') {
        this.isLoadingOtherHolders = true
        if (!offset) {
          this.otherHolders = []
          if (this.fetchHoldersTimeout) {
            clearTimeout(this.fetchHoldersTimeout)
          }
        }
        const holderAccount = (this.showForAccount || '').toLowerCase()
        const fetchEventId = this.tokenData.event.id
        const maxLimit = externalApisService.MAX_LIMIT_ASSETS_FOR_EVENT_POAP
        tokenService.getHolders(
          this.tokenData.networkId,
          this.tokenData.type === 'poap' ? 'poap' : this.tokenData.contractAddress,
          this.tokenData.tokenId,
          maxLimit,
          offset
        )
          .then(fetchResponse => {
            if (fetchEventId !== this.tokenData.event.id) { return }
            if (fetchResponse.total > offset + maxLimit) {
              this.fetchHoldersTimeout = setTimeout(() => {
                this.getOtherHolders(offset + maxLimit, timeout * 3)
              }, timeout * 3)
            }
            if (fetchResponse.tokens && fetchResponse.tokens instanceof Array) {
              const newHolders = fetchResponse.tokens
                .filter(token => token.owner.toLowerCase() !== holderAccount)
              this.otherHolders = [...this.otherHolders, ...newHolders]
                .sort((a, b) => b.ownerPoapsOwned - a.ownerPoapsOwned)
            }
          })
          .catch(error => {
            this.$store.dispatch('alertShow', { error })
          })
          .finally(() => {
            this.isLoadingOtherHolders = false
          })
      }
    },
    updateHidden (newState) {
      if (!this.tokenInMyWallet) { return }
      this.$root.$emit('signin-with-wallet', () => {
        if (newState && !this.tokenData.hidden) {
          this.isUpdatingToken = true
          tokenService.updateToken(
            this.tokenData.networkId,
            this.tokenData.type === 'poap' ? 'poap' : this.tokenData.contractAddress,
            this.tokenData.tokenId, {
              hidden: true,
              type: this.tokenData.type,
              standard: this.tokenData.standard,
              name: this.tokenData.name,
              imageUri: this.tokenData.imageUri,
              collection: {
                id: this.tokenData.collection.id,
                name: this.tokenData.collection.name,
                imageUri: this.tokenData.collection.imageUri
              }
            }
          )
            .then(() => {
              const tokenData = this.tokenData
              tokenData.hidden = true
              this.tokenData = null
              this.tokenData = tokenData
            })
            .catch(error => {
              this.$store.dispatch('alertShow', { error })
            })
            .finally(() => {
              this.isUpdatingToken = false
            })
        } else if (!newState && this.tokenData.hidden) {
          this.isUpdatingToken = true
          tokenService.updateToken(
            this.tokenData.networkId,
            this.tokenData.type === 'poap' ? 'poap' : this.tokenData.contractAddress,
            this.tokenData.tokenId, {
              hidden: false,
              type: this.tokenData.type,
              standard: this.tokenData.standard,
              name: this.tokenData.name,
              imageUri: this.tokenData.imageUri,
              collection: {
                id: this.tokenData.collection.id,
                name: this.tokenData.collection.name,
                imageUri: this.tokenData.collection.imageUri
              }
            }
          )
            .then(() => {
              const tokenData = this.tokenData
              tokenData.hidden = false
              this.tokenData = null
              this.tokenData = tokenData
            })
            .catch(error => {
              this.$store.dispatch('alertShow', { error })
            })
            .finally(() => {
              this.isUpdatingToken = false
            })
        }
      })
    },
    updateReaction (reactionType, newState) {
      if (this.tokenInMyWallet) { return }
      this.$root.$emit('signin-with-wallet', () => {
        if (newState && !this.favState) {
          this.isUpdatingReaction = true
          tokenService.createReaction(
            this.tokenData.networkId,
            this.tokenData.type === 'poap' ? 'poap' : this.tokenData.contractAddress,
            this.tokenData.tokenId,
            reactionType, {
              type: this.tokenData.type,
              standard: this.tokenData.standard,
              name: this.tokenData.name,
              imageUri: this.tokenData.imageUri,
              collection: {
                id: this.tokenData.collection.id,
                name: this.tokenData.collection.name,
                imageUri: this.tokenData.collection.imageUri
              }
            }
          )
            .then(() => {
              const tokenData = this.tokenData
              tokenData.requesterReactions = [...(this.tokenData.requesterReactions || []).filter(x => x !== reactionType), reactionType]
              this.tokenData = null
              this.tokenData = tokenData
            })
            .catch(error => {
              this.$store.dispatch('alertShow', { error })
            })
            .finally(() => {
              this.isUpdatingReaction = false
            })
        } else if (!newState && this.favState) {
          this.isUpdatingReaction = true
          tokenService.updateReaction(
            this.tokenData.networkId,
            this.tokenData.type === 'poap' ? 'poap' : this.tokenData.contractAddress,
            this.tokenData.tokenId,
            reactionType,
            'remove', {
              type: this.tokenData.type,
              standard: this.tokenData.standard,
              name: this.tokenData.name,
              imageUri: this.tokenData.imageUri,
              collection: {
                id: this.tokenData.collection.id,
                name: this.tokenData.collection.name,
                imageUri: this.tokenData.collection.imageUri
              }
            }
          )
            .then(() => {
              const tokenData = this.tokenData
              tokenData.requesterReactions = this.tokenData.requesterReactions.filter(x => x !== reactionType)
              this.tokenData = null
              this.tokenData = tokenData
            })
            .catch(error => {
              this.$store.dispatch('alertShow', { error })
            })
            .finally(() => {
              this.isUpdatingReaction = false
            })
        }
      })
    },
    closeOnTokenTransfer ({ tokenFullId }) {
      if (!this.tokenData) { return }
      if (tokenFullId === this.tokenData.fullId) {
        this.detailsOpen = false
      }
    },
    uppercaseAddressOrName,
    capitalCase
  },
  watch: {
    $route () {
      this.detailsOpen = false
    }
    // tokenData: {
    //   deep: true,
    //   handler () {
    //     console.log('watcher PpTokenAndTools')
    //     this.$forceUpdate()
    //   }
    // }
  },
  created () {
    this.$on('open', this.openDetails)
    this.$root.$on('token-transfer', this.closeOnTokenTransfer)
  },
  destroyed () {
    this.$off('open')
    this.$root.$off('token-transfer', this.closeOnTokenTransfer)
    if (this.fetchHoldersTimeout) {
      clearTimeout(this.fetchHoldersTimeout)
    }
  }
}
</script>

<style lang="scss">
  @import '@/styles/main.scss';

  .pp-token-details-centered-token {
    @include smAndDown {
      max-width: 300px;
      margin: 0 auto;
    }
  }
</style>
