<template>
  <pp-dialog
    v-model="showInternal"
    :title="$t($vb.xs ? 'walletConnect.shortTitle' : 'walletConnect.title')"
    close-icon
    width="600"
    :card-attrs="{
      color: 'primary'
    }"
  >
    <div>
      <div class="text-body-2 text-start">
        {{ $t('walletConnect.web2Message') }}
      </div>
      <v-row justify="center" class="py-2 mx-auto" style="max-width: 480px">
        <v-btn text v-for="(web2Option, $iOption) of web2Options"
          :key="'web2-option-' + $iOption"
          class="my-2 mx-sm-2 px-1 col-sm-7 col-11"
          @click="() => {
            $emit('connect-web2', web2Option)
            showInternal=false
          }"
          height="100"
        >
          <div class="pp-wallet-connect-web2-options">
            <img height="64" width="64" class="" :src="web2Option.logo" />
            <div class="font-weight-medium pt-2 text-wrap">{{ web2Option.name}}</div>
          </div>
        </v-btn>
      </v-row>
    </div>
    <div>
      <div class="text-body-2 text-start pb-1">
        {{ $t('walletConnect.web3Message') }}
      </div>
      <v-row justify="center" class="py-2">
        <v-btn text v-for="(web3Option, $iOption) of web3Options"
          :key="'web3-option-' + $iOption"
          class="ma-2 px-1 col-sm-8 col-10"
          @click="() => {
            $emit('connect-web3', web3Option)
            showInternal=false
          }"
          height="100"
        >
          <div class="pp-wallet-connect-web3-options">
            <img height="64" width="64" class="" :src="web3Option.logo" />
            <div class="font-weight-medium pt-2 text-wrap">{{ web3Option.name}}</div>
          </div>
        </v-btn>
      </v-row>
    </div>
  </pp-dialog>
</template>

<script>
import PpDialog from '@/components/general/PpDialog.vue'

export default {
  components: { PpDialog },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    web2Options: {
      type: Array,
      default: () => []
    },
    web3Options: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  computed: {
    showInternal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style lang="scss">
  .pp-wallet-connect-web2-options {
    max-width: 150px;
    img {
      height: 64px;
      width: 64px;
    }
  }
  .pp-wallet-connect-web3-options {
    max-width: 150px;
    img {
      height: 64px;
      width: 64px;
    }
  }
</style>
