<template>
  <pp-dialog
    v-model="signInWeb3Open"
    width="400"
    :card-attrs="{
      color: 'primary'
    }"
    :title="$t('signinWeb3.title')"
    close-icon
    :buttons="[{
      name: 'retry',
      text: $t('signinWeb3.retry'),
      primary: true,
      btnAttrs: {
        loading: web3SignatureInProgress || signingIn || isLoggedIn,
        disabled: web3SignatureInProgress || signingIn || isLoggedIn
      }
    },
    {
      name: 'cancel',
      text: $t('shared.cancel'),
      secondary: true
    }]"
    @retry="$root.$emit('request-signin-signature', signatureCallback)"
    @cancel="signInWeb3Open = false"
    class="transfer-token-dialog"
  >
    <div class="text-center pa-4">
      <v-icon x-large class="mx-2">mdi-wallet-outline</v-icon>
      <v-icon x-large class="mx-2">mdi-draw</v-icon>
    </div>
    <div class="text-body-2 text--primary pa-2">
      {{ $t('signinWeb3.messageRequest' )}}
    </div>
    <div class="text-body-3 pa-2">
      {{ $t('signinWeb3.messageExplain' )}}
    </div>
  </pp-dialog>
</template>

<script>
import PpDialog from '@/components/general/PpDialog.vue'
import { mapGetters } from 'vuex'

export default {
  components: { PpDialog },
  props: {
    signingIn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      signInWeb3Open: false,
      signatureCallback: () => {}
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'web3SignatureInProgress'
    ])
  },
  watch: {
    isLoggedIn (newValue) {
      if (newValue) {
        this.signInWeb3Open = false
      }
    }
  },
  created () {
    this.$on('open', (callback = () => {}) => {
      if (!this.isLoggedIn) {
        this.signInWeb3Open = true
        this.signatureCallback = callback
      }
    })
    this.$on('close', () => {
      this.signInWeb3Open = false
    })
  },
  destroyed () {
    this.$off('open')
    this.$off('close')
  }
}
</script>

<style lang="scss">

</style>
