const networkIdTranslate = {
  'homestead': 'ethereum',
  'mainnet': 'ethereum',
  'polygon': 'matic',
  'gnosis': 'xdai'
}
const cleanNetworkId = networkId => (networkIdTranslate[networkId] || networkId)

const nativeToken = {
  'ethereum': {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18
  },
  'xdai': {
    name: 'xDai',
    symbol: 'XDAI',
    decimals: 18
  },
  'sokol': {
    name: 'Test xDai',
    symbol: 'TESTXDAI',
    decimals: 18
  },
  'matic': {
    name: 'Matic',
    symbol: 'MATIC',
    decimals: 18
  },
  'bsc': {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18
  },
  'rsk': {
    name: 'Smart Bitcoin',
    symbol: 'RBTC',
    decimals: 18
  },
  'oe': {
    name: 'Ether on Optimism',
    symbol: 'OETH',
    decimals: 18
  },
  'fantom': {
    name: 'FTM',
    symbol: 'FTM',
    decimals: 18
  },
  'avalanche': {
    name: 'AVAX',
    symbol: 'AVAX',
    decimals: 18
  },
  'arbitrum': {
    name: 'Ether on Arbitrum',
    symbol: 'AETH',
    decimals: 18
  }
}
const getNativeToken = networkId => nativeToken[cleanNetworkId(networkId)]

const chainIds = {
  'ethereum': 1,
  'xdai': 100,
  'sokol': 77,
  'matic': 137,
  'bsc': 56,
  'rsk': 30,
  'oe': 10,
  'fantom': 250,
  'avalanche': 43114,
  'arbitrum': 42161
}
const getChainId = networkId => chainIds[cleanNetworkId(networkId)]
const getChainIdHex = networkId => '0x' + getChainId(networkId)
  .toString(16)

const networkIdsForChain = Object.fromEntries(Object.entries(chainIds)
  .map(([networkId, chainId]) => [chainId, networkId]))
const getNetworkIdForChain = chainId => networkIdsForChain[Number(chainId)]

const validNetworkIds = Object.keys(chainIds)
  .concat(Object.keys(networkIdTranslate))
const isValidNetworkId = networkId => validNetworkIds.includes(networkId)

const validChainIds = Object.values(chainIds)
const isValidChainId = chainId => validChainIds.includes(chainId)

const networkNames = {
  'ethereum': 'Ethereum',
  'xdai': 'Gnosis Smart Chain',
  'sokol': 'Gnosis Testnet',
  'matic': 'Polygon',
  'bsc': 'BNB Chain',
  'rsk': 'RSK',
  'oe': 'Optimism',
  'fantom': 'Fantom Opera',
  'avalanche': 'Avalanche',
  'arbitrum': 'Arbitrum One'
}
const getNetworkName = networkId => networkNames[cleanNetworkId(networkId)]

const networkLogos = {
  'ethereum': {
    icon: 'mdi-ethereum'
  },
  'xdai': {
    image: 'assets/img/gnosis.png'
  },
  'matic': {
    image: 'assets/img/polygon.png'
  }
}
const getNetworkLogo = networkId => networkLogos[cleanNetworkId(networkId)]

const rpcUrl = {
  'ethereum': 'https://mainnet.infura.io/v3/',
  'xdai': 'https://rpc.gnosischain.com/',
  'sokol': 'https://sokol.poa.network/',
  'matic': 'https://polygon-rpc.com/',
  'bsc': 'https://bscrpc.com/',
  'rsk': 'https://public-node.rsk.co/',
  'oe': 'https://mainnet.optimism.io/',
  'fantom': 'https://rpcapi.fantom.network/',
  'avalanche': 'https://api.avax.network/ext/bc/C/rpc/',
  'arbitrum': 'https://arb1.arbitrum.io/rpc/'
}
const getRpcUrl = networkId => rpcUrl[cleanNetworkId(networkId)]

const explorerUrl = {
  'ethereum': 'https://etherscan.io/',
  'xdai': 'https://blockscout.com/xdai/mainnet/',
  'sokol': 'https://blockscout.com/xdai/testnet/',
  'matic': 'https://polygonscan.com/',
  'bsc': 'https://bscscan.com/',
  'rsk': 'https://explorer.rsk.co/',
  'oe': 'https://optimistic.etherscan.io/',
  'fantom': 'https://ftmscan.com/',
  'avalanche': 'https://snowtrace.io/',
  'arbitrum': 'https://arbiscan.io/'
}
const getExplorerUrl = networkId => explorerUrl[cleanNetworkId(networkId)]
const getExploreAddressUrl = (networkId, addressToExplore) => `${getExplorerUrl(networkId)}address/${addressToExplore}`
const getExploreTokenUrl = (networkId, tokenToExplore) => `${getExplorerUrl(networkId)}token/${tokenToExplore}`
const getExploreTransactionUrl = (networkId, txToExplore) => `${getExplorerUrl(networkId)}tx/${txToExplore}`

const openSeaUrl = {
  ethereum: 'https://opensea.io/assets/',
  matic: 'https://opensea.io/assets/matic/'
}
const getOpenSeaUrl = (networkId, contractAddress, tokenId) => {
  const networkUrl = openSeaUrl[cleanNetworkId(networkId)]
  return networkUrl ? `${networkUrl}${contractAddress}/${tokenId}` : null
}

const raribleUrl = {
  ethereum: 'https://rarible.com/token/',
  matic: 'https://rarible.com/token/polygon/'
}
const getRaribleUrl = (networkId, contractAddress, tokenId) => {
  const networkUrl = raribleUrl[cleanNetworkId(networkId)]
  return networkUrl ? `${networkUrl}${contractAddress}:${tokenId}` : null
}

const looksRareUrl = {
  ethereum: 'https://looksrare.org/collections/'
}
const getLooksRareUrl = (networkId, contractAddress, tokenId) => {
  const networkUrl = looksRareUrl[cleanNetworkId(networkId)]
  return networkUrl ? `${networkUrl}${contractAddress}/${tokenId}` : null
}

const poapContractAddresses = {
  'ethereum': '0x22c1f6050e56d2876009903609a2cc3fef83b415',
  'xdai': '0x22c1f6050e56d2876009903609a2cc3fef83b415',
  'sokol': '0x22c1f6050e56d2876009903609a2cc3fef83b415'
}
const getPoapContractAddress = networkId => poapContractAddresses[cleanNetworkId(networkId)]

export {
  cleanNetworkId,
  nativeToken,
  getNativeToken,
  chainIds,
  getChainId,
  getChainIdHex,
  networkIdsForChain,
  getNetworkIdForChain,
  validNetworkIds,
  isValidNetworkId,
  validChainIds,
  isValidChainId,
  networkNames,
  getNetworkName,
  networkLogos,
  getNetworkLogo,
  rpcUrl,
  getRpcUrl,
  explorerUrl,
  getExplorerUrl,
  getExploreAddressUrl,
  getExploreTokenUrl,
  getExploreTransactionUrl,
  openSeaUrl,
  getOpenSeaUrl,
  raribleUrl,
  getRaribleUrl,
  looksRareUrl,
  getLooksRareUrl,
  poapContractAddresses,
  getPoapContractAddress
}
