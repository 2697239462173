var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c('v-row',{staticClass:"widget-section-title pb-2 flex-nowrap",attrs:{"justify":"space-between","align":"center"}},[_c('div',{staticClass:"text-h2 font-weight-regular text-uppercase text-start pr-2"},[_vm._v(" "+_vm._s(_vm.$t('widgets.recentSearches.title'))+" ")]),_c('v-tooltip-ext',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onClearHistory = ref.on;
return [(_vm.recentSearches.length > 0)?_c('v-btn',_vm._g({attrs:{"plain":"","icon":"","small":""},on:{"click":_vm.clearRecent}},onClearHistory),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1):_vm._e()]}}])},[_vm._v(" "+_vm._s(_vm.$t('widgets.recentSearches.empty'))+" ")])],1),_c('v-list',{attrs:{"color":"transparent"}},_vm._l((_vm.recentSearches),function(recentSearch,$iRecentSearch){return _c('v-list-item',{key:'recent-search-' + $iRecentSearch,attrs:{"to":{
        name: 'profile',
        params: {
          tokens_holder: recentSearch.searchTerm
        }
      }}},[_c('v-list-item-icon',{staticClass:"d-md-none d-lg-block"},[_c('v-avatar',{attrs:{"color":"accent"}},[(recentSearch.avatarUri)?_c('img',{attrs:{"src":recentSearch.avatarUri,"alt":""},on:{"error":function($event){recentSearch.avatarUri = null}}}):_c('v-icon',{attrs:{"size":"28","color":"white"}},[_vm._v("mdi-ethereum")])],1)],1),_c('v-list-item-title',{staticClass:"text-h3 text-lg-start text-truncate"},[_vm._v(" "+_vm._s(recentSearch.displayName)+" ")])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }