<template>
  <div id="page-container" class="container-width">
    <pp-top-bar />
    <div class="center-image-container text-center">
      <img
        :src="isLoadingSite ? 'assets/img/intro.png' : 'assets/img/play.png'"
        class="center-image"
        :class="isLoadingSite ? 'splash-image' : 'static-image'"
      />
      <div class="mt-n8" v-if="isLoadingSite">
        <v-progress-circular indeterminate color="white-primary" class="mx-auto" />
      </div>
    </div>
    <v-row justify="space-between" align="stretch" class="relative">
      <v-col sm="1" md="1" lg="6" class="d-none d-sm-block pr-4 page-nav-panel">
        <pp-nav-drawer />
      </v-col>
      <v-col cols="24" sm="full-less-nav" md="21" lg="18" class="flex-grow-1 maxw-unset minh-50vh">
        <router-view class="page-container-content" v-if="!isLoadingSite"></router-view>
      </v-col>
    </v-row>
    <div class="w-100 text-end" v-if="showClaimBtn && !isLoadingSite">
      <v-btn x-large rounded color="accent" elevation="12" class="px-6 floating-claim-button" :to="{ name: 'claim' }">
        <v-icon class="mr-1 ml-n1">mdi-plus</v-icon>
        {{ $t('nav.claim') }}
      </v-btn>
    </div>
    <pp-footer />
  </div>
</template>

<script>
import { langs } from '@/plugins/translates'
import PpTopBar from '@/components/layout/PpTopBar.vue'
import PpNavDrawer from '@/components/layout/PpNavDrawer.vue'
import PpFooter from '@/components/layout/PpFooter.vue'

const hideClaimBtn = ['claim', 'terms-of-service', 'privacy-policy']

export default {
  components: {
    PpTopBar,
    PpNavDrawer,
    PpFooter
  },
  data () {
    return {
      langs: langs,
      isLoadingSite: true
    }
  },
  computed: {
    showClaimBtn () {
      return hideClaimBtn.indexOf(this.$route.name) === -1
    }
  },
  methods: {
    updateLang (lang) {
      if (this.langs.indexOf(lang) !== -1) {
        this.$i18n.locale = lang
        this.$vuetify.lang.current = lang
        document.getElementsByTagName('html')[0].setAttribute('lang', lang)
        document.getElementsByTagName('html')[0].setAttribute('xml:lang', lang)
        this.$root.$emit('changed-lang', lang)
      } else {
        this.changeLang(langs[0])
      }
    },
    changeLang (lang) {
      if (this.$i18n.locale === lang) { return }
      if (this.langs.indexOf(lang) !== -1) {
        const newRoute = {
          name: this.$route.name || 'homepage',
          params: this.$route.params || {},
          query: this.$route.query || {}
        }
        newRoute.params.lang = lang
        this.$router.replace(newRoute)
      }
    }
  },
  watch: {
    isLoadingSite: {
      immediate: true,
      handler (newValue) {
        this.$emit('update:loading', newValue)
      }
    },
    $route: {
      immediate: true,
      handler (newRoute) {
        this.updateLang(newRoute.params.lang)
      }
    }
  },
  mounted () {
    this.$root.$on('change-lang', this.changeLang)
    if (Object.keys(this.$route.query).length || this.$store.getters.isLoggedIn) {
      this.isLoadingSite = false
    } else {
      this.isLoadingSite = true
      setTimeout(() => {
        this.isLoadingSite = false
      }, 2000)
    }
  },
  destroyed () {
    this.$root.$off('change-lang')
  }
}
</script>

<style lang="scss">
  @import '@/styles/main.scss';

  #page-container {
    overflow: hidden;
    height: 100%;
    &.container-width, .container-width {
      min-width: 300px;
      width: 90%;
      max-width: 1280px;
      margin: 0 auto;
    }
    .page-nav-panel {
      min-width: 84px;
    }
    .col-sm-full-less-nav {
      @include sm {
        flex-basis: calc(100% - 84px) !important;
        max-width: calc(100% - 84px) !important;
      }
    }
    .floating-claim-button.v-btn {
      position: fixed;
      bottom: 10vh;
      @include smAndUp {
        bottom: 20vh;
      }
      transform: translateX(-100%);
      z-index: 1;
    }
    .center-image-container {
      position: fixed;
      left: calc(50% - 200px);
      top: calc(42.2vh - 200px);
      z-index: 0;
      .center-image {
        width: 400px;
        height: 400px;
        &.static-image {
          opacity: 0.25;
          transition: opacity 1s;
        }
      }
    }
  }
</style>
