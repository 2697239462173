var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"explore-view"}},[_c('v-row',{attrs:{"align":"stretch"}},[_c('v-col',{staticClass:"px-sm-4",attrs:{"cols":"24","md":"16"}},[_c('div',{staticClass:"px-sm-4 py-2 d-flex explore-search-bar"},[_c('v-text-field',{staticClass:"rounded-0 font-weight-medium explore-search-text",attrs:{"placeholder":_vm.$t('explore.searchPlaceholder'),"background-color":"primary","color":"white-primary","filled":"","solo":"","hint":_vm.$t('shared.hintAddressOrEns'),"autofocus":"","rules":[_vm.rules.validAddressOrEns]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchProfile.apply(null, arguments)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"large":"","color":"white-primary"}},[_vm._v("mdi-account-outline")])]},proxy:true}]),model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('div',{staticClass:"d-flex justify-center align-center accent explore-search-btn"},[_c('v-btn',{attrs:{"icon":"","color":"white-primary","disabled":!_vm.searchValid},on:{"click":_vm.searchProfile}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-magnify")])],1)],1)],1),_c('div',{staticClass:"pt-2"},[(_vm.userAddressOrEns)?_c('v-btn',{staticClass:"px-6 text-wrap",attrs:{"rounded":"","large":"","color":"accent","to":{
            name: 'profile',
            params: {
              tokens_holder: _vm.userAddressOrEns
            }
          }}},[_vm._v(" "+_vm._s(_vm.$t('explore.exploreCollection'))+" ")]):_c('v-btn',{staticClass:"px-6 text-wrap",attrs:{"rounded":"","large":"","color":"accent"},on:{"click":function($event){this.$root.$emit('open-wallet-connect', function () { return _vm.$router.push({
              name: 'profile',
              params: {
                tokens_holder: _vm.userAddressOrEns
              }
            }); }
          )}}},[_vm._v(" "+_vm._s(_vm.$t('explore.connectAndExplore'))+" ")])],1),(_vm.isLoadingPoaps)?_c('div',{staticClass:"my-12 py-8 mx-auto maxw-50"},[_c('div',{staticClass:"pb-2"},[_vm._v(" "+_vm._s(_vm.$t('shared.loadingPoaps'))+" ")]),_c('v-progress-linear',{attrs:{"indeterminate":"","color":"white"}})],1):(_vm.featuredCollectionsUpdate > 0)?_c('v-row',{staticClass:"my-12"},_vm._l((_vm.exampleCollections.concat( _vm.featuredCollections)),function(featureCollection){return _c('v-col',{key:'collection-' + featureCollection.addressOrEns,staticClass:"my-4",attrs:{"cols":"24"}},[_c('v-row',{staticClass:"widget-section-title pb-2 flex-nowrap",attrs:{"justify":"space-between","align":"center"}},[_c('div',{staticClass:"text-h2 font-weight-regular text-uppercase text-start pr-2"},[(!featureCollection.preventOpen)?_c('span',[_vm._v(" "+_vm._s(featureCollection.displayTitle)+" ")]):_c('router-link',{staticClass:"text-decoration-none text--primary",attrs:{"to":{ name: 'profile', params: { tokens_holder: featureCollection.addressOrEns } }}},[_vm._v(" "+_vm._s(featureCollection.displayTitle)+" ")])],1),(!featureCollection.preventOpen)?_c('v-btn',{attrs:{"plain":"","icon":"","to":{ name: 'profile', params: { tokens_holder: featureCollection.addressOrEns } },"small":""}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1):_vm._e()],1),_c('pp-tokens-table',{attrs:{"items":featureCollection.poaps,"items-per-page-options":[]},on:{"view-token":function($event){return _vm.$refs.TokenDetails.$emit('open', $event, true)}}})],1)}),1):_vm._e()],1),_c('v-col',{staticClass:"pt-16 pt-md-2 pb-2 pl-4",attrs:{"cols":"24","md":"8"}},[_c('pp-featured-accounts',{attrs:{"wide":_vm.$vb.smAndDown}})],1)],1),_c('pp-token-details',{ref:"TokenDetails",attrs:{"show-for-account":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }