<template>
  <div id="pp-footer-container" class="d-flex align-end">
    <v-footer id="pp-footer" :absolute="!isLongPage">
      <div class="pp-footer-content">
        <v-row align="center">
          <v-col md="24" class="text-start" v-if="isLongPage">
            <img src="assets/img/player-proof.png" width="250px" class="d-md-none ml-n5" />
            <img src="assets/img/player-proof.png" width="250px" class="d-none d-md-inline py-4 ml-n5" />
          </v-col>
          <v-spacer />
          <div class="d-md-none">
            <!-- <v-col class="icon-col-sm d-md-none"> -->
              <!-- <v-btn icon plain class="ml-1 my-1">
                <v-icon size="30">$vuetify.icons.discord</v-icon>
              </v-btn> -->
            <!-- </v-col> -->
            <!-- <v-col class="icon-col-sm d-md-none"> -->
              <v-btn icon plain class="ml-1 my-1">
                <v-icon size="34">mdi-twitter</v-icon>
              </v-btn>
            <!-- </v-col> -->
            <!-- <v-col class="icon-col-sm d-md-none"> -->
              <v-btn icon plain class="ml-1 my-1">
                <v-icon size="30">mdi-instagram</v-icon>
              </v-btn>
            <!-- </v-col> -->
          </div>
        </v-row>
        <v-row align="center">
          <v-col class="text-start">
            <!-- <div class="pt-5 footer-title">
              <span>{{ $t('footer.title') }} </span>
            </div> -->
            <div class="pt-2 pb-1 footer-subscript">
              {{ $t('footer.copyright') }}
              <br class="d-md-none">
              <router-link :to="{ name: 'terms-of-service' }">{{ $t('nav.termsOfService') }}</router-link>.
              <router-link :to="{ name: 'privacy-policy' }">{{ $t('nav.privacyPolicy') }}</router-link>.
            </div>
            <div class="pt-1 pb-4 footer-subscript" v-if="ipfsHash">
              {{ $t('footer.buildHash') }}:
              <a :href="ipfsWebGatewayUri(ipfsHash)" target="_blank">{{ ipfsHash }}</a>.
            </div>
          </v-col>
          <!-- <v-btn icon plain class="d-none d-md-block mb-10 mr-3">
            <v-icon size="30">$vuetify.icons.discord</v-icon>
          </v-btn> -->
          <v-btn icon plain class="d-none d-md-block mb-10 mr-2">
            <v-icon size="34">mdi-twitter</v-icon>
          </v-btn>
          <v-btn icon plain class="d-none d-md-block mb-10 mr-n1">
            <v-icon size="30">mdi-instagram</v-icon>
          </v-btn>
        </v-row>
      </div>
    </v-footer>
  </div>
</template>

<script>
import { ipfsWebGatewayUri } from '@/data/constants.js'

export default {
  data () {
    return {
      ipfsHash: null,
      isLongPage: false
    }
  },
  methods: {
    ipfsWebGatewayUri,
    getIpfsHash () {
      fetch('./', { method: 'GET' })
        .then(fetchResponse => {
          this.ipfsHash = (() => {
            if (!fetchResponse.ok) { return null }
            const ipfsPath = fetchResponse.headers.get('X-Ipfs-Path')
            if (!ipfsPath) { return null }
            const ipfsPathSegments = ipfsPath.split('/')
            if (ipfsPathSegments[0] || ipfsPathSegments[1] !== 'ipfs' || !ipfsPathSegments[2]) { return null }
            return ipfsPathSegments[2]
          })()
        })
        .catch(() => {
          this.ipfsHash = null
        })
    },
    calcPageLength () {
      this.isLongPage = document.body.clientHeight > window.innerHeight + 200
    }
  },
  watch: {
    $route () {
      this.calcPageLength()
    }
  },
  mounted () {
    this.getIpfsHash()
    setInterval(this.calcPageLength, 1000)
  }
}
</script>

<style lang="scss">
  @import '@/styles/main.scss';

  #pp-footer-container {
    height: 350px;
  }

  #pp-footer {
    background: transparent;
    width: 100%;
    margin: 0;
    padding: 0;
    .pp-footer-content {
      min-width: 300px;
      width: 80%;
      max-width: 1280px;
      margin: 0 auto;
      padding: 50px 0;
      // .icon-col-sm {
      //   max-width: 15%;
      //   text-align: right;
      //   padding-right: 4px;
      // }
      .v-btn.v-btn--icon .v-btn__content {
        opacity: 1;
        i { color: white; }
        svg { fill: white }
        &:hover {
          i { color: $accent; }
          svg { fill: $accent }
        }
      }
      .footer-title {
        font-size: 20pt;
        font-weight: 700;
      }
      .footer-subtitle {
        color: white;
        font-size: 15pt;
        font-weight: 700;
      }
      .footer-subscript {
        color: $whiteSecondary;
        a {
          color: inherit;
        }
      }
    }
  }

</style>
