// Libraries
import Vue from 'vue'
import Router from 'vue-router'

// Import scripting files
// import { vm } from './main'
// import Store from './store'
import {
  scrollTo
} from './functions'
import {
  langs
} from './plugins/translates'

// Containers
import PageContainer from './containers/PageContainer.vue'

// Unlogged ViewsSearch
import UrlNotFound from './views/UrlNotFound.vue'
// import UnloggedContactUs from './views/unlogged/ContactUs.vue'

// Shared Views
import TermsOfService from './views/policy/TermsOfService.vue'
import PrivacyPolicy from './views/policy/PrivacyPolicy.vue'

// Connected Views
import Explore from './views/Explore.vue'
import Profile from './views/Profile.vue'
import Favorites from './views/Favorites.vue'
import Claim from './views/Claim.vue'

Vue.use(Router)
const defaultLang = langs[0]
let retryAnchorScroll = 0
let intervalAnchorScroll = null

// Avoid route duplication error
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location)
    .catch(err => {
      if (err.name !== 'NavigationDuplicated') {
        throw err
      }
    })
}
const router = new Router({
  routes: [{
    path: '/',
    redirect: '/' + defaultLang + '/'
  }, {
    path: '/:lang',
    name: 'empty',
    props: true,
    redirect: {
      name: 'explore'
    },
    component: PageContainer,
    children: [
      {
        path: 'explore',
        name: 'explore',
        component: Explore
      }, {
        path: 'profile/:tokens_holder',
        name: 'profile',
        component: Profile,
        props: true
      }, {
        path: 'favs',
        name: 'favs',
        component: Favorites
      }, {
        path: 'claim',
        name: 'claim',
        component: Claim
      }, {
        path: 'terms-of-service',
        name: 'terms-of-service',
        component: TermsOfService
      }, {
        path: 'privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicy
      }, {
        path: 'error-404',
        name: 'error-404',
        component: UrlNotFound
      }, {
        path: '*',
        redirect: {
          name: 'error-404'
        }
      }
      // {
      //   path: 'contact-us',
      //   name: 'contact-us',
      //   component: UnloggedContactUs
      // },
    ]
  }, {
    path: '*',
    redirect: {
      name: 'error-404'
    }
  }],
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      // Interval is used to try scroll 10 times over 5 seconds, letting asyncronous content load (based on api request results)
      //  and conditional render finish before giving up on anchor scrolling. No error is thrown if anchor is never found.
      if (intervalAnchorScroll) {
        clearInterval(intervalAnchorScroll)
      }
      retryAnchorScroll = 0
      intervalAnchorScroll = setInterval(() => {
        if (retryAnchorScroll++ < 10) {
          const elementSelected = document.getElementById(to.hash.substr(1))
          if (elementSelected) {
            clearInterval(intervalAnchorScroll)
            scrollTo(elementSelected.getBoundingClientRect()
              .top + window.scrollY)
          }
        } else {
          clearInterval(intervalAnchorScroll)
        }
      }, 500)
      return {
        selector: to.hash
      }
    } else if (to.name === from.name || !(!to.params.self)) {
      return false
    }
    return scrollTo(0)
  },
  duplicateNavigationPolicy: 'ignore'
})

router.beforeEach((to, from, next) => {
  let replacePath = to.fullPath
  if (location.search) {
    location.replace(location.origin + location.pathname + location.hash + ((location.hash || '').includes('?') ? '&' : '?') + location.search.slice(1))
  }
  const language = to.params.lang || ''
  if (langs.indexOf(language) === -1) {
    next(
      replacePath.split('/')
        .map((item, index) => index === 1 ? defaultLang : item)
        .join('/')
    )
  } else {
    next(true)
  }
})

export default router
