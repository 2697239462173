<template>
  <div id="profile-view">
    <v-row align="stretch">
      <v-col cols="24" md="16" class="px-4">
        <template v-if="isLoadingProfile">
          <div class="py-8 mx-auto maxw-50" v-if="isLoadingPoaps">
            <div class="pb-2">
              {{ $t('profile.loadingProfile') }}
            </div>
            <v-progress-linear indeterminate color="white" />
          </div>
        </template>
        <template v-else-if="!profileLoaded">
          <v-alert type="error">
            {{ $t('profile.couldNotLoad') }}
          </v-alert>
        </template>
        <template v-else>
          <v-row align="end">
            <v-col cols="24" xl="16">
              <v-row justify="start" align="center" class="flex-nowrap pb-1">
                <pp-file-upload-avatar
                  v-model="profileAvatarUri"
                  :size="$vb.xs ? 48 : 64"
                  :editable="isAvatarEditable"
                  :loading="isLoadingProfile"
                  :updating="isUpdatingAvatar"
                  :require-logged="openAvatarDialog"
                  @update-avatar="updateAvatar"
                />
                <div class="pl-4 text-start text-truncate">
                  <div class="d-flex justify-start align-end flex-nowrap">
                    <template v-if="!profileNameEditing || !isNameEditable">
                      <h1 v-if="!profileNameText && isNameEditable" class="text-h2 font-weight-regular pt-1 pr-6 text-shadow text--secondary">{{ $t('profile.editNamePlaceholder') }}</h1>
                      <h1 class="text-h2 font-weight-regular text-uppercase pt-1 text-truncate pr-6 text-shadow" v-else>{{ profileNameText || truncatedAddress }}</h1>
                      <v-icon @click="editName" size="20" class="ml-n4 pb-1" v-if="isNameEditable">mdi-pencil</v-icon>
                      <v-icon @click="$root.$emit('copy-text', profileAddress)" small class="ml-n4 pb-1" v-else>mdi-content-copy</v-icon>
                    </template>
                    <div class="d-flex" v-click-outside="cancelEditName" v-else>
                      <v-text-field
                        v-model="profileNameEdit"
                        color="white-primary"
                        class="mr-1 mr-8"
                        dense
                        filled
                        autofocus
                        hide-details
                        v-mask="{
                          mask: 'S'.repeat(profileNameEdit.length + 2 ),
                          tokens: { S: { pattern: /[0-9a-zA-Z\-'\s]/, transform: v => v.toUpperCase() } }
                        }"
                        @keyup.enter="updateName"
                      />
                      <div class="ml-n7">
                        <v-btn icon small class="d-block my-n1" @click="cancelEditName" :disabled="isUpdatingName">
                          <v-icon small>mdi-close</v-icon>
                        </v-btn>
                        <v-btn icon small class="d-block my-n1" @click="updateName" :loading="isUpdatingName" :disabled="isUpdatingName">
                          <v-icon small>mdi-check</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                  <div class="text-h3 text-shadow" v-if="profileAddress && (profileNameText || isNameEditable)">
                    {{ truncatedAddress }}
                    <v-icon @click="$root.$emit('copy-text', profileAddress)" small class="ml-2 pb-2p">mdi-content-copy</v-icon>
                  </div>
                </div>
              </v-row>
            </v-col>
            <v-col cols="24" xl="8" class="d-flex justify-space-around flex-wrap pt-2 d-xl-block text-end pt-xl-0" v-if="!isMyProfile">
              <div v-for="socialPlatform of existingSocialPlatforms" :key="'platform-handle-' + socialPlatform.id">
                <v-btn text class="mb-1 px-1 mx-2 text-none" max-height="24" :href="socialPlatform.getUrl(socialPlatform.handle)" target="_blank">
                  {{ socialPlatform.getText(socialPlatform.handle) }}
                  <v-icon small class="ml-1 mb-1p">{{ socialPlatform.icon }}</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row justify="start" align="end" class="py-2 flex-nowrap" v-if="!profileBioEditing || !isBioEditable">
            <div v-if="!profileBio && isBioEditable"
              class="text-start text-shadow text--secondary" :class="isBioEditable ? 'pr-8' : ''">
              {{ $t('profile.editBioPlaceholder') }}
            </div>
            <v-col cols="24" class="text-start text-shadow" :class="isBioEditable ? 'pr-8' : ''" v-else>
              {{ profileBio }}
            </v-col>
            <v-btn icon class="ml-n7 mb-n1" @click="editBio" v-if="isBioEditable">
              <v-icon size="20">mdi-pencil</v-icon>
            </v-btn>
          </v-row>
          <v-row align="center" class="py-2 flex-nowrap" v-click-outside="cancelEditBio" v-else>
            <v-col cols="24" class="text-start pr-8">
              <v-textarea
                v-model="profileBioEdit"
                color="white-primary"
                dense
                filled
                autofocus
                no-resize
                class="pt-0 text-body-1"
                height="60"
                counter="140"
                @keyup.enter="updateBio"
              />
            </v-col>
            <div class="ml-n7 mb-4">
              <v-btn icon class="d-block" @click="cancelEditBio" :disabled="isUpdatingBio">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn icon class="d-block" @click="updateBio" :loading="isUpdatingBio" :disabled="isUpdatingBio">
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </div>
          </v-row>
          <v-row justify="center" align="center" class="pt-2" v-if="isMyProfile">
            <v-col :cols="noSocialPlatforms ? 6 : 12" :md="noSocialPlatforms ? 4 : 8" v-for="socialPlatform of socialPlatforms"
              :key="'social-platform-' + socialPlatform.id"
              class="py-2 px-1"
            >
              <v-btn v-if="socialPlatform.handle"
                text
                class="d-flex text-none px-1 mb-1 maxw-100"
                :class="socialPlatform.hidden ? 'half-opacity' : ''"
                :href="socialPlatform.getUrl(socialPlatform.handle)"
                target="_blank"
              >
                <img
                  :src="socialPlatform.logo"
                  width="20"
                  height="20"
                  class="mr-2"
                />
                <span class="text-truncate">{{ socialPlatform.getText(socialPlatform.handle) }}</span>
              </v-btn>
              <div class="d-flex align-center mt-n2 w-minc mx-auto" v-if="socialPlatform.handle">
                <v-btn
                  icon
                  small
                  class="d-block mr-2"
                  @click="updateSocial(socialPlatform, 'hideunhide')"
                  :loading="isUpdatingSocial === socialPlatform.id + '-hideunhide'"
                  :disabled="!!isUpdatingSocial"
                >
                  <v-icon small>{{ socialPlatform.hidden ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
                </v-btn>
                <v-btn
                  icon
                  small
                  class="d-block ml-2"
                  @click="updateSocial(socialPlatform, 'remove')"
                  :loading="isUpdatingSocial === socialPlatform.id + '-remove'"
                  :disabled="!!isUpdatingSocial"
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </div>
              <div class="d-flex align-center pb-1 pl-7 w-minc mx-auto" v-else>
                <img
                  :src="socialPlatform.logo"
                  width="32"
                  height="32"
                  :class="{ 'pointer': !isUpdatingSocial }"
                  v-on="!isUpdatingSocial ? { click: () => addSocial(socialPlatform) } : {}"
                />
                <v-btn
                  icon
                  small
                  class="d-block"
                  @click="addSocial(socialPlatform)"
                  :loading="isUpdatingSocial === socialPlatform.id + '-add'"
                  :disabled="!!isUpdatingSocial"
                >
                  <v-icon small>mdi-plus-thick</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <div class="pt-8">
            <div class="py-4">
              <div class="pb-2 d-flex justify-space-between align-baseline" style="border-bottom: 2px white solid">
                <h2 class="text-start">POAPs</h2>
                <div class="text-end text-h2">#{{ formattedNumberOfPoaps }}</div>
              </div>
              <div class="py-8 mx-auto maxw-50" v-if="isLoadingPoaps && !poapsInProfile.length">
                <div class="pb-2">
                  {{ $t('shared.loadingPoaps') }}
                </div>
                <v-progress-linear indeterminate color="white" />
              </div>
              <div class="py-8 mx-auto maxw-50" v-else-if="!renderedPoaps || !renderedPoaps.length">
                <div class="pb-2">
                  {{ $t('profile.noPoaps') }}
                </div>
              </div>
              <pp-tokens-table
                v-else
                :items="renderedPoaps"
                :items-per-page-options="[12, 24, 48]"
                @view-token="$refs.TokenDetails.$emit('open', $event, true)"
              />
            </div>
            <div class="pt-4">
              <div class="pb-2 d-flex justify-space-between align-baseline" style="border-bottom: 2px white solid">
                <h2 class="text-start">NFTs</h2>
                <div class="text-end text-h2">#{{ formattedNumberOfNFTs }}</div>
              </div>
              <div class="py-8 mx-auto maxw-50" v-if="isLoadingNFTs && !filteredNFTs.length">
                  {{ $t('shared.loadingNfts') }}
                <v-progress-linear indeterminate color="white" />
              </div>
              <div class="py-8 mx-auto maxw-50" v-else-if="!filteredNFTs || !nftsInProfile.length">
                <div class="pb-2">
                  {{ $t('profile.noNfts') }}
                </div>
              </div>
              <div v-else>
                <div class="px-4">
                  <v-autocomplete
                    v-model="selectedCollection"
                    :items="nftCollections"
                    cleareable
                    item-text="name"
                    item-value="id"
                    :placeholder="$t('profile.collectionsPlaceholder')"
                    color="white"
                    :menu-props="{
                      contentClass: 'white-primary--border-2 rounded',
                      nudgeBottom: 10
                    }"
                  >
                    <template #item="{ item, on: onItem }">
                      <v-list-item class="secondary" v-on="onItem">
                        <v-list-item-avatar>
                          <img :src="item.imageUri" v-if="item.imageUri">
                        </v-list-item-avatar>
                        <v-list-item-title>
                          <span class="text-truncate">{{ item.name }}</span>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template #selection="{ item }">
                      <v-list-item>
                        <v-list-item-avatar>
                          <img :src="item.imageUri" v-if="item.imageUri">
                        </v-list-item-avatar>
                        <v-list-item-title>
                          <span class="text-truncate">{{ item.name }}</span>
                        </v-list-item-title>
                        <v-list-item-action>
                          <v-icon color="white" @click="selectedCollection = null">mdi-close</v-icon>
                        </v-list-item-action>
                      </v-list-item>
                      <!-- <img :src="item.imageUri" width="32" height="32" v-if="item.imageUri">
                      <span class="text-truncate">{{ item.name }}</span> -->
                    </template>
                  </v-autocomplete>
                </div>
                <pp-tokens-table
                  :items="filteredNFTs"
                  :items-per-page-options="[12, 24, 48]"
                  @view-token="$refs.TokenDetails.$emit('open', $event, true)"
                />
              </div>
            </div>
          </div>
        </template>
      </v-col>
      <v-col cols="24" md="8" class="pt-8 pt-md-2 pb-2 px-4 pr-md-0">
        <pp-featured-accounts :wide="$vb.smAndDown" :skip-most-recent="true" :force-updater.sync="updateRecentSearches" />
      </v-col>
    </v-row>
    <pp-token-details ref="TokenDetails" :show-for-account="profileAddress" />
  </div>
</template>

<script>
import { translateIpfsUri, uppercaseAddressOrName } from '@/filters'
import { profileService, filesMgmtService, tokenService, externalApisService } from '@/services'
import { socialPlatforms } from '@/data/social'
import { cleanNetworkId, getPoapContractAddress } from '@/data/web3constants'
import { mapGetters } from 'vuex'
import PpFeaturedAccounts from '@/components/widgets/PpFeaturedAccounts.vue'
import PpTokensTable from '@/components/tables/PpTokensTable.vue'
import PpTokenDetails from '@/components/dialogs/PpTokenDetails.vue'
import PpFileUploadAvatar from '@/components/files/PpFileUploadAvatar.vue'

export default {
  components: {
    PpFeaturedAccounts,
    PpTokensTable,
    PpTokenDetails,
    PpFileUploadAvatar
  },
  props: {
    tokens_holder: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isLoadingProfile: true,
      isLoadingPoaps: true,
      isLoadingNFTs: true,
      profileLoaded: false,
      profileNameText: '',
      profileNameSource: '',
      profileNameEdit: '',
      profileNameEditing: false,
      isUpdatingName: false,
      profileAddress: '',
      profileAvatarUri: '',
      profileAvatarSource: '',
      isUpdatingAvatar: false,
      socialPlatforms: [],
      isUpdatingSocial: null,
      profileBio: '',
      profileBioEdit: '',
      profileBioEditing: false,
      isUpdatingBio: false,
      poapsInProfile: [],
      nftsInProfile: [],
      lastNftLoadTimestamp: 0,
      lastTimeout: {},
      selectedCollection: null,
      updateRecentSearches: 0
    }
  },
  metaInfo () {
    return {
      title: 'PlayerProof - ' + this.tokens_holder
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'userAddress',
      'isWeb3Connected',
      'web3ChainId',
      'web3Address'
    ]),
    isMyProfile () {
      if (!this.profileLoaded) { return false }
      if (!this.userAddress) { return false }
      return this.userAddress.toLowerCase() === (this.profileAddress || '').toLowerCase()
    },
    truncatedAddress () {
      if (!this.profileLoaded) { return null }
      return uppercaseAddressOrName(this.profileAddress)
    },
    profileAddressOrEns () {
      if (!this.profileLoaded) { return null }
      return this.profileNameSource === 'ens' ? this.profileNameText : this.profileAddress
    },
    profileTitle () {
      if (!this.profileLoaded) { return null }
      return this.profileNameText || this.truncatedAddress
    },
    isNameEditable () {
      if (!this.isMyProfile) { return false }
      return this.profileNameSource !== 'ens'
    },
    isAvatarEditable () {
      if (!this.isMyProfile) { return false }
      return true
    },
    isBioEditable () {
      if (!this.isMyProfile) { return false }
      return true
    },
    formattedNumberOfPoaps () {
      if (!this.poapsInProfile) { return '000' }
      return String(this.poapsInProfile.length).padStart(3, '0')
    },
    formattedNumberOfNFTs () {
      if (!this.nftsInProfile) { return '000' }
      return String(this.nftsInProfile.length).padStart(3, '0')
    },
    noSocialPlatforms () {
      return this.socialPlatforms.every(socialPlatform => !socialPlatform.handle)
    },
    existingSocialPlatforms () {
      return this.socialPlatforms.filter(socialPlatform => socialPlatform.handle)
    },
    renderedPoaps () {
      if (!this.poapsInProfile) { return [] }
      if (!this.poapsInProfile.length) { return [] }
      return this.poapsInProfile
        .filter(poapItem => {
          if (poapItem.banned) { return false }
          if (poapItem.hidden) { return this.isMyProfile }
          return true
        })
    },
    renderedNFTs () {
      if (!this.nftsInProfile) { return [] }
      if (!this.nftsInProfile.length) { return [] }
      return this.nftsInProfile
        .filter(nftItem => {
          if (nftItem.standard !== 'erc721' && nftItem.standard !== 'erc1155') { return false }
          if (nftItem.banned) { return false }
          if (nftItem.hidden) { return this.isMyProfile }
          return true
        })
    },
    filteredNFTs () {
      if (!this.selectedCollection) {
        return this.renderedNFTs
      }
      return this.renderedNFTs
        .filter(nftItem => nftItem.collection.id === this.selectedCollection)
    },
    nftCollections () {
      if (!this.renderedNFTs) { return [] }
      if (!this.renderedNFTs.length) { return [] }
      const collectionsMap = {}
      this.renderedNFTs
        .forEach(nftItem => {
          if (!nftItem.collection) { return }
          const collectionId = nftItem.collection.id
          if (!collectionsMap[collectionId]) {
            collectionsMap[collectionId] = {
              id: collectionId,
              name: nftItem.collection.name,
              imageUri: nftItem.collection.imageUri
            }
          }
        })
      return Object.values(collectionsMap)
    }
  },
  methods: {
    getProfileData () {
      this.isLoadingProfile = true
      this.profileLoaded = false
      const tokenHolder = this.tokens_holder.toLowerCase()

      return profileService.getProfile(tokenHolder)
        .then(fetchResponse => {
          if (tokenHolder !== this.tokens_holder.toLowerCase()) { return }
          const retrievedProfile = fetchResponse

          this.profileAddress = retrievedProfile.address

          this.getPoaps()
          this.getNFTs('ethereum')
          this.getNFTs('matic')

          if (retrievedProfile.name) {
            this.profileNameText = retrievedProfile.name.text
            this.profileNameSource = retrievedProfile.name.source
          } else {
            this.profileNameText = ''
            this.profileNameSource = ''
          }

          if (retrievedProfile.biography) {
            this.profileBio = retrievedProfile.biography
          } else {
            this.profileBio = ''
          }

          this.socialPlatforms = socialPlatforms(['facebook', 'twitter', 'instagram', 'linkedin', 'twitch', 'youtube'])
            .map(platformObj => {
              if (!retrievedProfile.platformHandles) { return platformObj }
              const retrievedPlatform = retrievedProfile.platformHandles.find(pH => pH.platform === platformObj.id)
              if (retrievedPlatform) {
                platformObj.handle = retrievedPlatform.handle
                platformObj.hidden = retrievedPlatform.hidden
              }
              return platformObj
            })

          if (retrievedProfile.avatar) {
            this.profileAvatarSource = retrievedProfile.avatar.source
            return filesMgmtService.downloadFileToDataUrl(retrievedProfile.avatar.uri).catch(err => false)
          }
          return Promise.resolve()
        })
        .then(avatarDataUrl => {
          if (tokenHolder !== this.tokens_holder.toLowerCase()) { return }
          if (avatarDataUrl) {
            this.profileAvatarUri = avatarDataUrl
          } else {
            this.profileAvatarUri = null
            this.profileAvatarSource = null
            this.getEnsAvatar().catch(() => null)
          }
          this.profileLoaded = true
          this.saveRecentSearch()
        })
        .catch(error => {
          this.$store.dispatch('alertShow', { error })
        })
        .finally(() => {
          this.isLoadingProfile = false
        })
    },
    getPoaps () {
      this.isLoadingPoaps = true
      this.poapsInProfile = []
      let newPoapsToAdd
      const tokenHolder = this.tokens_holder.toLowerCase()
      return profileService.getTokensList(this.profileAddress, 'poap')
        .then(fetchResponse => {
          if (tokenHolder !== this.tokens_holder.toLowerCase()) { return }
          if (!(fetchResponse.tokens instanceof Array)) {
            throw new Error('Incorrect API response format')
          }
          newPoapsToAdd = fetchResponse.tokens.map(poapDetails => {
            if (poapDetails.event.eventUrl) {
              poapDetails.event.originalEventUrl = poapDetails.event.eventUrl
              poapDetails.event.eventUrl = translateIpfsUri(poapDetails.event.eventUrl)
            }
            if (poapDetails.event.imageUrl) {
              poapDetails.event.originalImageUrl = poapDetails.event.imageUrl
              poapDetails.event.imageUrl = translateIpfsUri(poapDetails.event.imageUrl)
            }
            if (poapDetails.externalUrl) {
              poapDetails.originalExternalUrl = poapDetails.externalUrl
              poapDetails.externalUrl = translateIpfsUri(poapDetails.externalUrl)
            }
            if (poapDetails.imageUri) {
              poapDetails.originalImageUri = poapDetails.imageUri
              poapDetails.imageUri = translateIpfsUri(poapDetails.imageUri)
            }
            poapDetails.fullId = `${poapDetails.networkId}-${poapDetails.contractAddress}-${poapDetails.tokenId}`
            return poapDetails
          })

          if (!newPoapsToAdd.length) {
            return Promise.resolve({ tokens: [] })
          }

          const newPoapsConcatIds = newPoapsToAdd.map(newToken => newToken.fullId)

          return tokenService.getTokens(newPoapsConcatIds, this.profileAddress)
            .catch(err => {
              console.error(err)
              return {}
            })
        })
        .then(fetchResponse => {
          if (tokenHolder !== this.tokens_holder.toLowerCase()) { return }
          if (fetchResponse.tokens && fetchResponse.tokens instanceof Array) {
            newPoapsToAdd.forEach(newPoap => Object.assign(
              newPoap,
              fetchResponse.tokens
                .find(offchainToken => offchainToken.fullId.toLowerCase() === newPoap.fullId.toLowerCase()) || {}
            ))
          }
        })
        .catch(error => {
          this.$store.dispatch('alertShow', { error })
        })
        .finally(() => {
          if (tokenHolder !== this.tokens_holder.toLowerCase()) { return }
          this.poapsInProfile = newPoapsToAdd
          this.isLoadingPoaps = false
        })
    },
    getNFTs (networkId, nextUrl, timeout = 200) {
      this.isLoadingNFTs = true
      if (networkId === 'ethereum' && !nextUrl) {
        this.nftsInProfile = []
        this.selectedCollection = null
        Object.values(this.lastTimeout).forEach(clearTimeout)
        this.lastTimeout = {}
      }
      if (nextUrl) {
        if (!nextUrl.includes(this.profileAddress)) {
          return
        }
      }
      let newNftsToAdd = []
      const tokenHolder = this.tokens_holder.toLowerCase()
      return externalApisService.getAssetsFromOpenSeaV2(networkId, this.profileAddress, nextUrl)
        .then(fetchResponse => {
          if (tokenHolder !== this.tokens_holder.toLowerCase()) { return }
          if (!fetchResponse.results || !(fetchResponse.results instanceof Array)) {
            throw new Error('Incorrect API response format')
          }
          if (fetchResponse.next) {
            this.lastTimeout[networkId] = setTimeout(() => { this.getNFTs(networkId, fetchResponse.next.replace(/^http:\/\//, 'https://'), timeout * 3) }, timeout * 3)
          }
          newNftsToAdd = fetchResponse.results
            .map(nftDetails => {
              const nftContract = nftDetails.asset_contract || {}
              const nftCollection = nftDetails.collection || {}
              const nftMetadata = nftDetails.metadata || {}
              const nftExternalUri = nftMetadata.external_url || nftCollection.external_url
              const nftCollectionExternalUri = nftCollection.external_url || nftMetadata.external_url
              const nftToAdd = {
                type: 'nft',
                standard: (nftContract.contract_standard || '').toLowerCase(),
                networkId: cleanNetworkId(nftDetails.chain_identifier),
                contractAddress: nftContract.address.toLowerCase(),
                tokenId: nftDetails.token_id,
                originalTokenUri: nftMetadata.metadata_url || null,
                tokenUri: nftMetadata.metadata_url ? translateIpfsUri(nftMetadata.metadata_url) : null,
                name: nftMetadata.name || `${nftCollection.name || nftContract.name} # ${nftDetails.token_id}`,
                description: nftMetadata.description || null,
                originalImageUri: nftMetadata.image_url || null,
                imageUri: nftMetadata.image_url ? translateIpfsUri(nftMetadata.image_url) : null,
                originalExternalUrl: nftExternalUri || null,
                externalUri: nftExternalUri ? translateIpfsUri(nftExternalUri) : null,
                attributes: (nftMetadata.traits || [])
                  .map(trait => {
                    const nftAttribute = {
                      type: trait.trait_type,
                      value: trait.value
                    }
                    if (trait.display_type) {
                      nftAttribute.displayType = trait.display_type
                    }
                    return nftAttribute
                  }),
                collection: {
                  id: nftCollection.slug || (nftCollection.name || nftContract.name).toLowerCase().replace(/[\s_.|]/g, '-'),
                  name: nftCollection.name || nftContract.name || nftMetadata.name,
                  symbol: nftContract.symbol || null,
                  originalImageUri: nftCollection.image_url || null,
                  imageUri: nftCollection.image_url ? translateIpfsUri(nftCollection.image_url) : null,
                  originalExternalUrl: nftCollectionExternalUri || null,
                  externalUri: nftCollectionExternalUri ? translateIpfsUri(nftCollectionExternalUri) : null
                },
                hidden: false,
                banned: false,
                requesterReactions: []
              }
              nftToAdd.fullId = `${nftToAdd.networkId}-${nftToAdd.contractAddress}-${nftToAdd.tokenId}`
              if (nftToAdd.standard === 'erc721') {
                nftToAdd.totalOwners = 1
                nftToAdd.owner = this.profileAddress
                nftToAdd.accountBalance = 1
              } else if (nftToAdd.standard === 'erc1155') {
                nftToAdd.owners = [this.profileAddress]
              }
              return nftToAdd
            })
            .filter(nftToAdd => {
              if (!nftToAdd.tokenUri) { return false }
              if (nftToAdd.contractAddress.toLowerCase() === (getPoapContractAddress(nftToAdd.networkId) || '').toLowerCase()) {
                return false
              }
              if (!['erc721', 'erc1155'].includes(nftToAdd.standard)) { return false }
              return true
            })

          if (!newNftsToAdd.length) {
            return Promise.resolve({ tokens: [] })
          }

          const newNftsConcatIds = newNftsToAdd.map(newToken => newToken.fullId)

          return tokenService.getTokens(newNftsConcatIds, this.profileAddress)
        })
        .then(fetchResponse => {
          if (tokenHolder !== this.tokens_holder.toLowerCase()) { return }
          if (fetchResponse.tokens && fetchResponse.tokens instanceof Array) {
            newNftsToAdd.forEach(newNft => Object.assign(
              newNft,
              fetchResponse.tokens
                .find(offchainToken => offchainToken.fullId.toLowerCase() === newNft.fullId.toLowerCase()) || {}
            ))
          }
        })
        .finally(() => {
          if (tokenHolder !== this.tokens_holder.toLowerCase()) { return }
          this.nftsInProfile.push(...newNftsToAdd)
          this.isLoadingNFTs = false
        })
    },
    async getEnsAvatar () {
      let ensAvatarUri
      const tokenHolder = this.tokens_holder.toLowerCase()
      if (this.isWeb3Connected && this.web3ChainId === 1) {
        const ensName = this.profileNameSource === 'ens'
          ? this.profileNameText
          : (await this.$root.ethProvider.lookupAddress(this.profileAddress).catch(() => null))
        if (!ensName) { return }
        ensAvatarUri = await this.$root.ethProvider.getAvatar(ensName).catch(() => null)
      } else {
        const resolveAvatarResponse = await profileService.resolveAvatar(
          this.profileNameSource === 'ens' ? this.profileNameText : this.profileAddress
        ).catch(() => null)
        if (!resolveAvatarResponse) { return }
        ensAvatarUri = resolveAvatarResponse.avatar
      }
      if (ensAvatarUri) {
        if (tokenHolder !== this.tokens_holder.toLowerCase()) { return }
        this.profileAvatarUri = translateIpfsUri(ensAvatarUri)
        this.profileAvatarSource = 'ens'
        this.saveRecentSearch()
      }
    },
    saveRecentSearch () {
      let storedRecentSearches
      try {
        storedRecentSearches = JSON.parse(localStorage.recentSearches)
      } catch {}
      const prevRecentSearches = storedRecentSearches instanceof Array ? storedRecentSearches : []
      localStorage.recentSearches = JSON.stringify([{
        searchTerm: this.profileAddressOrEns,
        name: this.profileTitle,
        address: this.profileAddress,
        avatarUri: this.profileAvatarUri
      }, ...(
        prevRecentSearches.filter(prevSearch => typeof prevSearch === 'object' && prevSearch.address !== this.profileAddress)
      )].slice(0, 20))
      this.updateRecentSearches = Date.now()
    },
    editName () {
      this.profileNameEdit = this.profileNameText
      this.profileNameEditing = true
    },
    cancelEditName () {
      this.profileNameEditing = false
    },
    updateName () {
      if (this.isUpdatingName) { return }
      this.isUpdatingName = true
      this.$root.$emit('signin-with-wallet', () => {
        profileService.updateProfile(this.profileAddress, {
          name: this.profileNameEdit
        })
          .then(() => {
            this.profileNameText = this.profileNameEdit
            this.$store.dispatch('updateUserProfile', {
              name: {
                text: this.profileNameEdit,
                source: 'off-chain'
              }
            })
            if (this.userAddress === this.web3Address) {
              this.$store.dispatch('web3UpdateInfo', {
                name: {
                  text: this.profileNameEdit,
                  source: 'off-chain'
                }
              })
            }
            this.profileNameEditing = false
          })
          .catch(error => {
            this.$store.dispatch('alertShow', { error })
          })
          .finally(() => {
            this.isUpdatingName = false
          })
      }, () => {
        this.isUpdatingName = false
      })
    },
    openAvatarDialog (callback = () => {}) {
      this.$root.$emit('signin-with-wallet', callback)
    },
    updateAvatar ({ name, previewUrl }) {
      if (this.isUpdatingAvatar) { return }
      this.isUpdatingAvatar = true
      this.$root.$emit('signin-with-wallet', () => {
        profileService.updateProfile(this.profileAddress, {
          avatar: name || null
        })
          .then(() => {
            this.profileAvatarUri = previewUrl
            this.$store.dispatch('updateUserProfile', {
              avatar: {
                uri: previewUrl,
                source: 'off-chain'
              }
            })
            if (this.userAddress === this.web3Address) {
              this.$store.dispatch('web3UpdateInfo', {
                avatar: {
                  uri: previewUrl,
                  source: 'off-chain'
                }
              })
            }
          })
          .catch(error => {
            this.$store.dispatch('alertShow', { error })
          })
          .finally(() => {
            this.isUpdatingAvatar = false
          })
      }, () => {
        this.isUpdatingAvatar = false
      })
    },
    addSocial (socialPlatform) {
      this.$root.$emit('signin-with-wallet', () => {
        this.$root.$emit('connect-social', { id: socialPlatform.id }, 'web2-link')
      })
    },
    updateSocial (socialPlatform, updateAction, updateParams) {
      if (this.isUpdatingSocial) { return }
      this.isUpdatingSocial = socialPlatform.id + '-' + updateAction
      this.$root.$emit('signin-with-wallet', () => {
        const requestAction = {
          hideunhide: socialPlatform.hidden ? 'unhide' : 'hide'
        }[updateAction] || updateAction
        profileService.updateSocialPlatform(
          this.profileAddress,
          socialPlatform.id,
          requestAction,
          updateParams
        )
          .then(fetchResponse => {
            if (requestAction === 'add') {
              socialPlatform.handle = fetchResponse.handle
              socialPlatform.hidden = false
            } else if (requestAction === 'remove') {
              socialPlatform.handle = null
            } else if (requestAction === 'hide') {
              socialPlatform.hidden = true
            } else if (requestAction === 'unhide') {
              socialPlatform.hidden = false
            }
          })
          .catch(error => {
            this.$store.dispatch('alertShow', { error })
          })
          .finally(() => {
            this.isUpdatingSocial = null
          })
      })
    },
    editBio () {
      this.profileBioEdit = this.profileBio
      this.profileBioEditing = true
    },
    cancelEditBio () {
      this.profileBioEditing = false
    },
    updateBio () {
      if (this.isUpdatingBio) { return }
      this.isUpdatingBio = true
      this.$root.$emit('signin-with-wallet', () => {
        profileService.updateProfile(this.profileAddress, {
          biography: this.profileBioEdit
        })
          .then(() => {
            this.profileBio = this.profileBioEdit
            this.$store.dispatch('updateUserProfile', {
              biography: this.profileBioEdit
            })
            this.profileBioEditing = false
          })
          .catch(error => {
            this.$store.dispatch('alertShow', { error })
          })
          .finally(() => {
            this.isUpdatingBio = false
          })
      }, () => {
        this.isUpdatingBio = false
      })
    },
    closeOnTokenTransfer ({ tokenFullId }) {
      const matchInPoapsList = this.poapsInProfile ? this.poapsInProfile.find(item => item.fullId === tokenFullId) : null
      const matchInNftsList = this.nftsInProfile ? this.nftsInProfile.find(item => item.fullId === tokenFullId) : null
      if (matchInPoapsList || matchInNftsList) {
        this.getProfileData()
      }
    }
  },
  watch: {
    tokens_holder () {
      this.getProfileData()
    },
    isLoggedIn () {
      if (!this.profileAddress) { return }
      if (!this.userAddress) { return }
      if (this.profileAddress.toLowerCase() === this.userAddress.toLowerCase()) {
        this.getProfileData()
      }
    }
  },
  mounted () {
    this.getProfileData()
      .then(() => {
        const authScope = this.$route.params.authScope
        const authPlatform = this.$route.params.authPlatform
        const authCode = this.$route.params.authCode
        if (authScope === 'web2-link' && authPlatform && authCode) {
          const callbackUrl = sessionStorage['web2_session_callback']
          if (!callbackUrl) { return }
          this.updateSocial(
            this.socialPlatforms.find(sP => sP.id === authPlatform),
            'add', {
              connection_token: authCode,
              redirect_uri: callbackUrl
            }
          )
        }
      })
  },
  created () {
    this.$root.$on('token-transfer', this.closeOnTokenTransfer)
  },
  destroyed () {
    this.$root.$off('token-transfer', this.closeOnTokenTransfer)
  }

}
</script>

<style>

</style>
