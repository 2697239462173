<template>
  <div id="claim-view">
    <v-row align="stretch">
      <v-col cols="24" md="16" class="px-sm-4">
        <h1 class="d-flex justify-start align-center text-uppercase text-shadow">
          <v-icon x-large class="mr-2">mdi-bookmark-plus-outline</v-icon>
          {{ $t('nav.claim') }}
        </h1>
        <div class="pt-12" v-if="!poapData">
          <div class="text-body-2">
            {{ $t('claim.enterCodeMessage') }}
          </div>
          <div class="pt-6">
            <v-text-field
              v-model="claimCode"
              :placeholder="$t('claim.enterCodePlaceholder')"
              background-color="primary"
              color="white-primary"
              class="rounded-0 font-weight-medium claim-code-text text-h2 text-center mx-auto"
              filled
              solo
              autofocus
              v-mask="{
                mask: 'ssssss',
                tokens: { s: { pattern: /[0-9a-zA-Z]/, transform: v => v.toLocaleLowerCase() } }
              }"
              @keyup.enter="fetchClaimInfo"
            >
              <template #prepend-inner>
                <v-icon large color="white-primary">mdi-qrcode</v-icon>
              </template>
            </v-text-field>
          </div>
          <div class="pt-4">
            <v-btn
              rounded
              x-large
              color="primary"
              class="text-body-2"
              :disabled="!claimCode.match(/^[0-9a-z]{6}$/) || isLoadingClaim"
              :loading="isLoadingClaim"
              @click="fetchClaimInfo"
            >
              {{ $t('claim.previewPoap') }}
            </v-btn>
          </div>
        </div>
        <div class="pt-12" v-else>
          <div class="text-h2">{{ poapData.event.name }}</div>
          <div class="pt-8">
            <div class="mx-auto poap-container">
              <v-img
                :src="poapData.event.imageUrl"
                aspect-ratio="1"
                :alt="poapData.event.name"
              />
            </div>
          </div>
          <div class="pt-12" v-if="claimSuccess">
            <v-alert type="success">
              POAP claimed successfully.<br>Redirecting to profile page in 10 seconds to give POAP time to mint the token.
            </v-alert>
            <div class="pa-8" text-center>
              <v-progress-circular color="white" indeterminate />
            </div>
          </div>
          <template v-else>
            <div class="pt-8" v-if="poapData.claimed">
              {{ $t('shared.claimedBy', [uppercaseAddressOrName(poapData.beneficiary)]) }}
            </div>
            <div class="pt-8" v-else>
              <v-btn v-if="userAddressOrEns"
                rounded
                large
                color="accent"
                class="px-6 text-wrap"
                @click="claimPoap(userAddressOrEns)"
                :disabled="isProcessingClaim"
                :loading="isProcessingClaim"
              >
                {{ $t('claim.claimPoap', [shortAddressOrEns]) }}
              </v-btn>
              <template v-else>
                <v-btn
                  rounded
                  large
                  color="accent"
                  class="px-6 text-wrap"
                  @click="$root.$emit('open-wallet-connect', () => claimPoap(userAddress))"
                  :disabled="isProcessingClaim"
                  :loading="isProcessingClaim"
                >
                  {{ $t('claim.connectAndClaim') }}
                </v-btn>
                <div class="pt-8 text-body-2">
                  {{ $t('claim.manualClaimMessage') }}
                </div>
                <div class="pt-4">
                  <v-text-field
                    v-model="manualClaimAddress"
                    :placeholder="$t('claim.manualClaimPlaceholder')"
                    background-color="primary"
                    color="white-primary"
                    class="rounded-0 font-weight-medium claim-address-text"
                    filled
                    solo
                    :hint="$t('shared.hintAddressOrEns')"
                    @keyup.enter="claimPoap(manualClaimAddress)"
                    :disabled="isProcessingClaim"
                    autofocus
                    :rules="[rules.validAddressOrEns]"
                  >
                    <template #prepend-inner>
                      <v-icon large color="white-primary">mdi-account-outline</v-icon>
                    </template>
                  </v-text-field>
                </div>
                <div class="pt-4">
                  <v-btn
                    rounded
                    large
                    color="accent"
                    class="px-6 text-wrap"
                    @click="claimPoap(manualClaimAddress)"
                    :disabled="isProcessingClaim"
                    :loading="isProcessingClaim"
                  >
                    {{ $t('claim.manualClaimAction') }}
                  </v-btn>
                </div>
              </template>
            </div>
            <div class="pt-8">
              <v-btn text small class="white-primary--text" @click="resetClaimCode">
                {{ $t('claim.resetClaimAction') }}
              </v-btn>
            </div>
          </template>
        </div>
      </v-col>
      <v-col cols="24" md="8" class="pt-16 pt-md-2 pb-2 pl-4">
        <pp-featured-accounts :wide="$vb.smAndDown" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PpFeaturedAccounts from '@/components/widgets/PpFeaturedAccounts.vue'
import { uppercaseAddressOrName } from '@/filters'
import validations from '@/validations'
import { mapGetters } from 'vuex'
import { tokenService } from '@/services'

export default {
  components: {
    PpFeaturedAccounts
  },
  data () {
    return {
      claimCode: '',
      isLoadingClaim: false,
      isProcessingClaim: false,
      claimSuccess: false,
      redirectTimeout: 0,
      poapData: null,
      manualClaimAddress: ''
    }
  },
  metaInfo () {
    return {
      title: 'PlayerProof - ' + this.$t('nav.claim')
    }
  },
  computed: {
    ...mapGetters([
      'userAddress',
      'userAddressOrEns'
    ]),
    shortAddressOrEns () {
      if (!this.userAddressOrEns) { return null }
      return uppercaseAddressOrName(this.userAddressOrEns)
    },
    rules () {
      return {
        validAddressOrEns: value => validations.validAddressOrEns(value, this.$t('shared.hintAddressOrEns'))
      }
    }
  },
  methods: {
    uppercaseAddressOrName,
    resetClaimCode () {
      this.claimCode = ''
      this.poapData = null
    },
    fetchClaimInfo () {
      if (!this.claimCode.match(/^[0-9a-z]{6}$/)) { return }
      this.$router.replace({
        name: 'claim',
        query: {
          'qr_hash': this.claimCode
        },
        params: {}
      }).catch(() => {})
      this.isLoadingClaim = true
      tokenService.getClaimWithQR('poap', this.claimCode.toLowerCase())
        .then(fetchResponse => {
          this.poapData = fetchResponse
          this.isLoadingClaim = false
        })
        .catch(error => {
          this.$store.dispatch('alertShow', { error })
          this.claimCode = ''
          this.poapData = null
          this.isLoadingClaim = false
          const currentQuery = Object.assign({}, this.$route.query)
          delete currentQuery.qr_hash
          const cleanRoute = {
            name: 'claim',
            query: (Object.keys(currentQuery).length ? Object.assign({}, currentQuery) : {}),
            params: {}
          }
          this.$router.replace(cleanRoute).catch(() => {})
        })
    },
    claimPoap (addressOrEns) {
      if (!addressOrEns.match(/^((0x[0-9a-fA-F]{40})|([-a-zA-Z0-9@:%._\+~#=]{1,256}\.[-a-zA-Z0-9@:%._\+~#=]{1,6}))$/)) { return }
      this.isProcessingClaim = true
      tokenService.claimToken({
        type: 'poap',
        qrHash: this.claimCode.toLowerCase(),
        secret: this.poapData.claimSecret,
        addressOrEns
      })
        .then(fetchResponse => {
          this.claimSuccess = true
          this.redirectTimeout = setTimeout(() => {
            this.$router.push({
              name: 'profile',
              params: {
                tokens_holder: addressOrEns
              }
            })
          }, 10 * 1000)
        })
        .catch(error => {
          this.$store.dispatch('alertShow', { error })
          this.claimCode = ''
          this.poapData = null
        })
        .finally(() => {
          this.isProcessingClaim = false
        })
    },
    loadCodeFromPath (newRoute) {
      if (!newRoute.query.qr_hash) {
        this.poapData = null
      } else if (newRoute.query.qr_hash !== this.claimCode) {
        this.claimCode = newRoute.query.qr_hash
        this.poapData = null
        this.fetchClaimInfo()
      }
    }
  },
  watch: {
    $route (newRoute) {
      this.loadCodeFromPath(newRoute)
    }
  },
  mounted () {
    this.claimCode = ''
    this.isLoadingClaim = false
    this.isProcessingClaim = false
    this.claimSuccess = false
    this.loadCodeFromPath(this.$route)
  },
  destroyed () {
    if (this.redirectTimeout) {
      clearTimeout(this.redirectTimeout)
      this.redirectTimeout = 0
    }
  }
}
</script>

<style lang="scss">
  @import '@/styles/main.scss';

  #claim-view {
    .poap-container {
      max-width: 200px;
    }
    .claim-code-text {
      max-width: 250px;
      .v-input__slot {
        border-width: 0;
      }
      .v-messages__message {
        text-align: center;
        width: 100%;
      }
    }
    .claim-address-text {
      .v-input__slot {
        border-width: 0;
        input::placeholder {
          color: $whitePrimary;
          opacity: 0.8;
        }
      }
      .v-messages__message {
        text-align: center;
        width: 100%;
      }
    }
  }

</style>
