import { ethers } from 'ethers'
import { vm } from '@/main'
import { profileService, filesMgmtService } from '@/services'
import { uppercaseAddressOrName, translateIpfsUri } from '@/filters'
import {
  getNativeToken,
  getChainIdHex,
  getNetworkName,
  getRpcUrl,
  getExplorerUrl
} from '@/data/web3constants'

window.ethers = ethers

const WEB3_POLLING_INTERVAL = 1000

const web3Store = {
  state: {
    connected: false,
    address: null,
    name: null,
    avatar: null,
    chainId: 0,
    lastChangeTimestamp: 0,
    signatureInProgress: false
  },
  getters: {
    isWeb3Connected (state) {
      return state.connected
    },
    web3Address (state) {
      return state.address ? state.address.toLowerCase() : null
    },
    web3AddressOrEns (state, getters) {
      if (!state.name) {
        return getters.web3Address
      }
      return state.name.source === 'ens'
        ? state.name.text
        : getters.web3Address
    },
    web3NameText (state) {
      if (!state.name) { return null }
      return state.name.text
    },
    web3AccountTitle (state, getters) {
      if (!state.name) { return getters.web3Address }
      return uppercaseAddressOrName(state.name.text || getters.web3Address)
    },
    web3AvatarUri (state) {
      if (!state.avatar) { return null }
      return translateIpfsUri(state.avatar.uri)
    },
    web3ChainId (state) {
      return state.chainId
    },
    web3LastChangeTimestamp (state) {
      return state.lastChangeTimestamp
    },
    web3SignatureInProgress (state) {
      return state.signatureInProgress
    }
  },
  mutations: {
    setWeb3Address (state, payload) {
      state.address = payload
      state.connected = !!payload
    },
    setWeb3Name (state, payload) {
      state.name = payload ? {
        text: payload.text,
        source: payload.source
      } : null
    },
    setWeb3Avatar (state, payload) {
      state.avatar = payload ? {
        uri: payload.uri,
        source: payload.source
      } : null
    },
    setWeb3ChainId (state, payload) {
      state.chainId = payload
    },
    setWeb3SignatureInProgress (state, payload) {
      state.signatureInProgress = payload
    },
    updateLastTimestamp (state) {
      state.lastChangeTimestamp = Date.now()
    }
  },
  actions: {
    async web3Init ({ dispatch }, web3Modal) {
      console.log('web3Init', !!web3Modal)
      if (!web3Modal) { return }
      vm.$root.web3Modal = web3Modal
      if (web3Modal.cachedProvider) {
        const connectTimeout = setTimeout(web3Modal.onClose, 2000)
        web3Modal.connect()
          .then(web3Provider => {
            const ethProvider = new ethers.providers.Web3Provider(web3Provider)
            clearTimeout(connectTimeout)
            return dispatch('web3Connect', ethProvider)
          })
          .catch(() => {
            clearTimeout(connectTimeout)
            dispatch('web3Reset')
          })
      } else {
        return dispatch('web3Reset')
      }
    },
    async web3Connect ({ commit, dispatch, getters }, ethProvider) {
      console.log('web3Connect')
      ethProvider.pollingInterval = WEB3_POLLING_INTERVAL

      const web3AccountsPromise = ethProvider.listAccounts()
      const web3NetworkPromise = ethProvider.getNetwork()

      const web3Accounts = await web3AccountsPromise
      console.log('web3Connect web3Accounts', web3Accounts)
      const ethAccount = web3Accounts && web3Accounts.length ? web3Accounts[0] : null

      const ethNetwork = await web3NetworkPromise

      vm.$root.ethProvider = ethProvider
      commit('setWeb3Address', ethAccount)
      commit('setWeb3Name', null)
      commit('setWeb3Avatar', null)
      commit('setWeb3ChainId', ethNetwork.chainId)
      commit('setWeb3SignatureInProgress', false)
      commit('updateLastTimestamp')

      profileService.getProfile(ethAccount)
        .then(fetchResponse => {
          commit('setWeb3Name', fetchResponse.name)
          commit('updateLastTimestamp')
          const fetchedAvatar = fetchResponse.avatar
          if (fetchedAvatar) {
            filesMgmtService.downloadFileToDataUrl(fetchedAvatar.uri)
              .then(contentDataUrl => {
                commit('setWeb3Avatar', {
                  source: fetchedAvatar.source,
                  uri: contentDataUrl
                })
                commit('updateLastTimestamp')
              })
              .catch(error => {
                this.$store.dispatch('alertShow', { error })
              })
          }
        })
        .catch(error => {
          this.$store.dispatch('alertShow', { error })
        })

      ethProvider.provider.on('connect', async connectInfo => {
        console.log('ethProvider.connect', connectInfo)
        const ethAccounts = await ethProvider.listAccounts()
        if (ethAccounts.length) {
          commit('setWeb3Address', ethAccounts[0])
          commit('updateLastTimestamp')
        }
      })

      ethProvider.provider.on('disconnect', async () => {
        console.log('ethProvider.disconnect')
        const ethAccounts = await ethProvider.listAccounts()
        if (!ethAccounts.length) {
          commit('setWeb3Address', null)
          commit('updateLastTimestamp')
        }
      })

      ethProvider.provider.on('accountsChanged', web3Accounts => {
        console.log('ethProvider.accountsChanged', web3Accounts)
        const ethAccount = web3Accounts && web3Accounts.length ? web3Accounts[0] : null
        commit('setWeb3Address', ethAccount)
        commit('setWeb3Name', null)
        commit('setWeb3Avatar', null)
        commit('updateLastTimestamp')

        profileService.getProfile(ethAccount)
          .then(fetchResponse => {
            commit('setWeb3Name', fetchResponse.name)
            commit('setWeb3Avatar', fetchResponse.avatar)
            commit('updateLastTimestamp')
          })
          .catch(error => {
            this.$store.dispatch('alertShow', { error })
          })
      })

      ethProvider.provider.on('chainChanged', ethChainIdHex => {
        console.log('ethProvider.chainChanged', ethChainIdHex)
        vm.$root.ethProvider = new ethers.providers.Web3Provider(vm.$root.web3Provider)
        commit('setWeb3ChainId', ethers.BigNumber.from(ethChainIdHex).toNumber())
        commit('updateLastTimestamp')
      })
    },
    async web3Reset ({ commit }) {
      const ethProvider = ethers.providers.getDefaultProvider()
      ethProvider.pollingInterval = WEB3_POLLING_INTERVAL
      const ethNetwork = await ethProvider.getNetwork()

      vm.$root.ethProvider = ethProvider
      commit('setWeb3Address', null)
      commit('setWeb3Name', null)
      commit('setWeb3Avatar', null)
      commit('setWeb3ChainId', ethNetwork.chainId)
      commit('setWeb3SignatureInProgress', false)
      commit('updateLastTimestamp')

      ethProvider.on('chainChanged', async web3ChainId => {
        console.log('ethProvider.chainChanged', web3ChainId)
        commit('setWeb3ChainId', ethers.BigNumber.from(web3ChainId).toNumber())
        commit('updateLastTimestamp')
      })
    },
    async web3Remove ({ commit }) {
      if (vm.$root.web3Modal) {
        await vm.$root.web3Modal.clearCachedProvider()
      }
      if (vm.$root.ethProvider) {
        await vm.$root.ethProvider.send('wallet_requestPermissions', [
          {
            eth_accounts: {}
          }
        ])
      }
    },
    async web3SwitchChain ({ state, dispatch }, networkId) {
      if (vm.$root.ethProvider) {
        const nativeCurrency = getNativeToken(networkId)
        const chainIdHex = getChainIdHex(networkId)
        const chainName = getNetworkName(networkId)
        const rpcUrl = getRpcUrl(networkId)
        const explorerUrl = getExplorerUrl(networkId)
        return vm.$root.ethProvider
          .send('wallet_switchEthereumChain', [{
            chainId: chainIdHex
          }])
          .catch(() => {
            return vm.$root.ethProvider
              .send('wallet_addEthereumChain', [{
                chainId: chainIdHex,
                chainName,
                nativeCurrency,
                rpcUrls: [rpcUrl],
                blockExplorerUrls: [explorerUrl]
              }])
          })
          .then(() => {
            vm.$root.ethProvider = new ethers.providers.Web3Provider(vm.$root.web3Provider)
          })
          .catch(error => {
            dispatch('web3Connect', vm.$root.ethProvider)
            console.error(error) // eslint-disable-line no-console
            dispatch('alertShow', { error: 'T8 Unable to switch network' })
          })
      }
      return Promise.reject(new Error('No provider or signer'))
    },
    async web3SignMsg ({ state, commit }, payload) {
      if (vm.$root.ethProvider && vm.$root.ethSigner) {
        commit('setWeb3SignatureInProgress', true)
        return vm.$root.ethSigner.signMessage(payload)
          .finally(() => {
            commit('setWeb3SignatureInProgress', false)
          })
      }
      return Promise.reject(new Error('No provider or signer'))
    },
    web3UpdateInfo ({ commit }, payload) {
      if (payload.name) {
        commit('setWeb3Name', payload.name)
      }
      if (payload.avatar) {
        commit('setWeb3Avatar', payload.avatar)
      }
    }
  }
}
export default web3Store
