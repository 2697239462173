<template>
  <v-list v-bind="$attrs" v-on="$listeners">
    <slot name="before-list"></slot>
    <template v-if="showNav">
      <v-list-item :to="{ name: 'explore' }" v-bind="itemProps">
        <v-list-item-icon><v-icon v-bind="iconProps">mdi-magnify</v-icon></v-list-item-icon>
        <v-list-item-title v-bind="titleProps">{{ $t('nav.explore') }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        :to="userAddressOrEns
          ? {
            name: 'profile',
            params: {
              tokens_holder: userAddressOrEns
            }
          } : undefined"
        v-on="!userAddressOrEns
          ? {
            click: () => {
              this.$root.$emit('open-wallet-connect', () => $router.push({
                  name: 'profile',
                  params: {
                    tokens_holder: userAddressOrEns
                  }
                })
              )
            }
          } : undefined"
        v-bind="itemProps"
        :disabled="!$root.web2Ready || !$root.web3Ready"
      >
        <v-list-item-icon><v-icon v-bind="iconProps">mdi-account-outline</v-icon></v-list-item-icon>
        <v-list-item-title v-bind="titleProps">{{ $t('nav.profile') }}</v-list-item-title>
      </v-list-item>
      <v-list-item :to="{ name: 'favs'  }" v-bind="itemProps" :disabled="!$root.web2Ready || !$root.web3Ready">
        <v-list-item-icon><v-icon v-bind="iconProps">mdi-heart-outline</v-icon></v-list-item-icon>
        <v-list-item-title v-bind="titleProps">{{ $t('nav.favorites') }}</v-list-item-title>
      </v-list-item>
      <v-list-item class="white-secondary--text" v-bind="itemProps">
        <v-list-item-icon><v-icon color="white-secondary" v-bind="iconProps">mdi-trophy-outline</v-icon></v-list-item-icon>
        <v-list-item-title v-bind="titleProps">
          {{ $t('nav.leaderboard') }}
          <span class="text-body-3 font-italic text-lowercase">({{ $t('shared.soon') }})</span>
        </v-list-item-title>
      </v-list-item>
    </template>
    <slot name="after-list"></slot>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    itemProps: {
      type: Object,
      default: () => ({
        class: 'py-1',
        activeClass: 'transparent'
      })
    },
    iconProps: {
      type: Object,
      default: () => ({
      })
    },
    titleProps: {
      type: Object,
      default: () => ({
        class: 'text-body-1'
      })
    },
    showNav: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters([
      'userAddressOrEns'
    ])
  }
}
</script>

<style lang="scss">

</style>
