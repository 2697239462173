import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import i18n from '@/plugins/i18n'
import web3Store from './web3.js'
import { sessionMgmtService, profileService } from '@/services'
import { uppercaseAddressOrName, translateIpfsUri } from '@/filters'

Vue.use(Vuex)

let tokenRefreshTimeout = null
let userProfileRefreshTimeout = null
const dataRefreshPeriod = 5 * 60 * 1000
const retryFetchPeriod = 5 * 1000

export default new Vuex.Store({
  state: {
    snackbarAlerts: [],
    token: {},
    userProfile: {}
  },
  getters: {
    exportStoreState (state) {
      const exportState = {
        token: state.token
      }
      return exportState
    },
    token (state) {
      return state.token
    },
    isLoggedIn (state, getters) {
      const token = state.token
      if (
        token.scope === 'web3-login' &&
        (token.clientId || '').toLowerCase() !== (getters.web3Address || '').toLowerCase()
      ) {
        return false
      }
      if (!!token && !!token.accessToken) {
        return Date.now() < token.expiresOn
      }
      return false
    },

    userProfile (state) {
      return state.userProfile
    },
    userAddress (state, getters) {
      if (!getters.isLoggedIn) {
        return getters.web3Address
      }
      if (!state.userProfile) { return null }
      return state.userProfile.address ? state.userProfile.address.toLowerCase() : null
    },
    userAddressOrEns (state, getters) {
      if (!getters.isLoggedIn) {
        return getters.web3AddressOrEns
      }
      if (!state.userProfile) { return null }
      if (!state.userProfile.name) {
        return getters.userAddress
      }
      return state.userProfile.name.source === 'ens'
        ? state.userProfile.name.text
        : getters.userAddress
    },
    userNameText (state, getters) {
      if (!getters.isLoggedIn) {
        return getters.web3NameText
      }
      if (!state.userProfile) { return null }
      if (!state.userProfile.name) { return null }
      return state.userProfile.name.text
    },
    userAccountTitle (state, getters) {
      if (!getters.isLoggedIn) {
        return getters.web3AccountTitle
      }
      if (!state.userProfile) { return null }
      if (!state.userProfile.name) { return getters.userAddress }
      return uppercaseAddressOrName(state.userProfile.name.text || getters.userAddress)
    },
    userAvatarUri (state, getters) {
      if (!getters.isLoggedIn) {
        return getters.web3AvatarUri
      }
      if (!state.userProfile) { return null }
      if (!state.userProfile.avatar) { return null }
      return translateIpfsUri(state.userProfile.avatar.uri)
    },

    snackbarAlerts (state) {
      return state.snackbarAlerts
    }
  },
  mutations: {
    setToken (state, payload) {
      state.token = payload || {}
    },
    setUserProfile (state, payload, markFresh = true) {
      const userProfile = payload || {}
      userProfile.storeTimestamp = markFresh ? Date.now() : state.userProfile.storeTimestamp + 1
      state.userProfile = userProfile
    },
    setSnackbarAlerts (state, payload) {
      state.snackbarAlerts = payload
    },
    pushSnackbarAlert (state, payload) {
      state.snackbarAlerts.push(payload)
    },
    clearSnackbarAlerts (state, payload) {
      state.snackbarAlerts = state.snackbarAlerts
        .filter(snackbarAlert => (
          payload.indexOf(snackbarAlert.id) === -1
        ))
    }
  },
  actions: {
    initStore () {

    },
    importStoreState ({ dispatch }, payload) {
      dispatch('setToken', payload.token)
    },

    setToken ({ dispatch, commit }, payload) {
      if (!payload.accessToken || !payload.tokenType || !payload.scope || !payload.expiresIn) {
        return Promise.reject(new Error('Bad token format'))
      }
      if (tokenRefreshTimeout) { clearTimeout(tokenRefreshTimeout) }
      if (!payload.expiresOn) {
        payload.expiresOn = Date.now() + (payload.expiresIn * 1000)
      }
      const timeToExpire = payload.expiresOn - Date.now()
      if (payload.refreshToken) {
        const randomTimeToRefresh = timeToExpire * (0.7 + Math.random() * 0.2)
        tokenRefreshTimeout = setTimeout(() => {
          dispatch('refreshToken')
        }, randomTimeToRefresh)
      }
      const profileData = payload.profile
      if (profileData) {
        delete payload.profile
        commit('setUserProfile', profileData)
      }
      return commit('setToken', payload)
    },
    initRefreshToken ({ dispatch, state }) {
      const timeToExpire = state.token.expiresOn - Date.now()
      const refreshWindow = 10 * 1000
      if (timeToExpire < refreshWindow) {
        dispatch('refreshToken')
      } else {
        tokenRefreshTimeout = setTimeout(() => {
          dispatch('refreshToken')
        }, timeToExpire - refreshWindow + 1000)
      }
    },
    refreshToken ({ dispatch, state }) {
      sessionMgmtService.refreshToken({
        clientId: state.token.clientId,
        refreshToken: state.token.refreshToken,
        scope: state.token.scope
      })
        .then(fetchResponse => {
          if (fetchResponse.scope !== state.token.scope) {
            return Promise.reject(new Error('Bad token scope'))
          }
          return dispatch('setToken', {
            clientId: state.token.clientId,
            accessToken: fetchResponse.access_token,
            tokenType: fetchResponse.token_type,
            expiresIn: fetchResponse.expires_in,
            refreshToken: fetchResponse.refresh_token,
            scope: fetchResponse.scope
          })
        })
        .then(() => {
          // vm.$root.$emit('export-store-token')
        })
        .catch(error => {
          dispatch('alertShow', { error })
        })
    },
    updateToken ({ commit, state }, updateFields) {
      const tokenObject = state.token
      Object.keys(updateFields).map(mappingKey => {
        tokenObject[mappingKey] = updateFields[mappingKey]
      })
      commit('setToken', tokenObject, false)
    },
    removeToken ({ commit, state }) {
      if (tokenRefreshTimeout) { clearTimeout(tokenRefreshTimeout) }
      if (userProfileRefreshTimeout) { clearTimeout(userProfileRefreshTimeout) }
      commit('setUserProfile', null)
      return commit('setToken', null)
    },
    setTokenAdditionalData ({ dispatch }, payload) {
      dispatch('initUserProfile', payload.profile)
    },

    initUserProfile ({ commit, dispatch, getters }, payload) {
      if (!getters.isLogged) { return }
      if (userProfileRefreshTimeout) { clearTimeout(userProfileRefreshTimeout) }
      commit('setUser', payload)
      userProfileRefreshTimeout = setTimeout(() => {
        dispatch('fetchUserProfile')
          .catch(error => {
            dispatch('alertShow', { error })
          })
      }, dataRefreshPeriod)
    },
    fetchUserProfile ({ commit, dispatch, getters }) {
      if (!getters.isLogged) { return }
      if (userProfileRefreshTimeout) { clearTimeout(userProfileRefreshTimeout) }
      const fetchPromise = profileService.getProfile()
        .then((fetchResponse = {}) => {
          commit('setUserProfile', fetchResponse)
          userProfileRefreshTimeout = setTimeout(() => {
            dispatch('fetchUserProfile')
              .catch(error => {
                dispatch('alertShow', { error })
              })
          }, dataRefreshPeriod)
          return Promise.resolve(fetchResponse)
        })
        .catch((fetchError = {}) => {
          userProfileRefreshTimeout = setTimeout(() => {
            dispatch('fetchUserProfile')
              .catch(error => {
                dispatch('alertShow', { error })
              })
          }, retryFetchPeriod)
          return Promise.reject(fetchError)
        })
      return fetchPromise
    },
    setUserProfile ({ commit }, payload) {
      commit('setUserProfile', payload)
    },
    updateUserProfile ({ commit, state }, updateFields) {
      const userProfileObject = state.userProfile
      Object.keys(updateFields).map(mappingKey => {
        userProfileObject[mappingKey] = updateFields[mappingKey]
      })
      commit('setUserProfile', userProfileObject, false)
    },
    updateUserProfileParamFields ({ commit, state }, updateFields) {
      const userProfileObject = state.userProfile
      const paramName = Object.keys(updateFields)[0]
      const paramObject = updateFields[paramName]
      userProfileObject[paramName] ||= {}
      Object.keys(paramObject).map(mappingKey => {
        userProfileObject[paramName][mappingKey] = paramObject[mappingKey]
      })
      commit('setUserProfile', userProfileObject, false)
    },

    alertShow ({ commit }, payload) {
      let shouldThrow = false
      const snackAlert = { id: Date.now() }

      if (payload.error) {
        snackAlert.type = 'error'
        snackAlert.color = 'error'
        if (typeof payload.error === 'string') {
          snackAlert.message = payload.error
        } else if (typeof payload.error === 'object' && payload.error != null && payload.error.fmc != null) {
          snackAlert.message = i18n.t('error.' + payload.error.fmc)
        } else {
          snackAlert.message = i18n.t('error.generic')
          shouldThrow = true
        }
      } else if (payload.warning) {
        snackAlert.type = 'warning'
        snackAlert.color = 'warning'
        snackAlert.message = payload.warning
      } else if (payload.success) {
        snackAlert.type = 'success'
        snackAlert.color = 'success'
        snackAlert.message = payload.success
      } else if (payload.info) {
        snackAlert.type = 'info'
        snackAlert.color = 'info'
        snackAlert.message = payload.info
      }

      commit('pushSnackbarAlert', snackAlert)
      if (!payload.persist) {
        setTimeout(() => {
          commit('clearSnackbarAlerts', [snackAlert.id])
        }, payload.timeout || 3000)
      }
      if (shouldThrow) {
        throw payload
      }
    },
    alertsHide ({ commit }, alertIds) {
      if (alertIds) {
        commit('clearSnackbarAlerts', alertIds)
      } else {
        commit('setSnackbarAlerts', [])
      }
    }
  },
  modules: {
    web3: web3Store
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ]
})
