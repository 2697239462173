<template>
  <div id="pp-nav" class="text-start">
    <v-navigation-drawer
      permanent
      sfloating
      :expand-on-hover="$vb.mdAndDown"
      absolute
      height="min-content"
      mini-variant-width="68"
      class="rounded"
    >
      <pp-nav-list color="transparent" class="text-start text-body-2" dense :icon-props="{ large: true}" :title-props="{ class: 'text-body-2' }" />
      <v-img src="assets/img/poker.png" class="mx-auto mt-4 mt-lg-8 mb-8" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import PpNavList from './PpNavList.vue'
export default {
  components: { PpNavList },
  computed: {
  }
}
</script>

<style lang="scss">
  @import '@/styles/main.scss';

  #pp-nav {
    .v-list-item--active::before {
      opacity: 0.05;
    }
    .v-navigation-drawer {
      background-color: transparent!important;
      border: transparent 2px solid!important;
      .v-image {
        width: 50%;
      }
      .v-list-item__title {
        line-height: unset;
      }
      @include mdAndDown {
        max-width: 68px;
        .v-list-item__title {
          display: none;
        }
        .v-image {
          width: 100%;
        }
        &.v-navigation-drawer--is-mouseover {
          background-color: $secondary!important;
          border: 2px solid $whitePrimary!important;
          max-width: 100%;
          .v-list-item__title {
            display: block;
            line-height: unset;
          }
          .v-image {
            width: 50%;
          }
        }
      }
    }
    .v-list-item {
      justify-content: start;
    }
  }
</style>
