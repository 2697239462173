var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pp-dialog',{attrs:{"title":_vm.$t(_vm.$vb.xs ? 'walletConnect.shortTitle' : 'walletConnect.title'),"close-icon":"","width":"600","card-attrs":{
    color: 'primary'
  }},model:{value:(_vm.showInternal),callback:function ($$v) {_vm.showInternal=$$v},expression:"showInternal"}},[_c('div',[_c('div',{staticClass:"text-body-2 text-start"},[_vm._v(" "+_vm._s(_vm.$t('walletConnect.web2Message'))+" ")]),_c('v-row',{staticClass:"py-2 mx-auto",staticStyle:{"max-width":"480px"},attrs:{"justify":"center"}},_vm._l((_vm.web2Options),function(web2Option,$iOption){return _c('v-btn',{key:'web2-option-' + $iOption,staticClass:"my-2 mx-sm-2 px-1 col-sm-7 col-11",attrs:{"text":"","height":"100"},on:{"click":function () {
          _vm.$emit('connect-web2', web2Option)
          _vm.showInternal=false
        }}},[_c('div',{staticClass:"pp-wallet-connect-web2-options"},[_c('img',{attrs:{"height":"64","width":"64","src":web2Option.logo}}),_c('div',{staticClass:"font-weight-medium pt-2 text-wrap"},[_vm._v(_vm._s(web2Option.name))])])])}),1)],1),_c('div',[_c('div',{staticClass:"text-body-2 text-start pb-1"},[_vm._v(" "+_vm._s(_vm.$t('walletConnect.web3Message'))+" ")]),_c('v-row',{staticClass:"py-2",attrs:{"justify":"center"}},_vm._l((_vm.web3Options),function(web3Option,$iOption){return _c('v-btn',{key:'web3-option-' + $iOption,staticClass:"ma-2 px-1 col-sm-8 col-10",attrs:{"text":"","height":"100"},on:{"click":function () {
          _vm.$emit('connect-web3', web3Option)
          _vm.showInternal=false
        }}},[_c('div',{staticClass:"pp-wallet-connect-web3-options"},[_c('img',{attrs:{"height":"64","width":"64","src":web3Option.logo}}),_c('div',{staticClass:"font-weight-medium pt-2 text-wrap"},[_vm._v(_vm._s(web3Option.name))])])])}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }