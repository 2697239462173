import Vue from 'vue'
import VueI18n from 'vue-i18n'
import translates from './translates'
Vue.use(VueI18n)

const dateTimeFormats = {
  en: {
    dateNumbers: {
      month: '2-digit', day: '2-digit', year: '2-digit'
    },
    dateShort: {
      month: 'short', day: 'numeric', year: 'numeric'
    },
    dateLong: {
      month: 'long', day: 'numeric', year: 'numeric'
    },
    dateLongNoYear: {
      month: 'long', day: 'numeric'
    }
  },
  es: {
    dateNumbers: {
      day: '2-digit', month: '2-digit', year: '2-digit'
    },
    dateShort: {
      day: 'numeric', month: 'short', year: 'numeric'
    },
    dateLong: {
      month: 'long', year: 'numeric'
    },
    dateLongNoYear: {
      day: 'numeric', month: 'long'
    }
  }
}

const i18n = new VueI18n({
  dateTimeFormats,
  locale: 'en', // set locale
  fallbackLocale: 'es', // set fallback locale
  messages: translates // set locale messages
})

export default i18n
