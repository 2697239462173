var baseApiUrl = ''
// var baseWebUrl = ''

export function setBaseUrls ({ apiUrl }) { // ({ apiUrl, webUrl })
  if (apiUrl) { baseApiUrl = apiUrl }
  // if (webUrl) { baseWebUrl = webUrl }
}

const urls = {
  sessionMgmt: {
    token: () => baseApiUrl + 'oauth2/token', // POST application/x-www-form-urlencoded => application/json
    revoke: () => baseApiUrl + 'oauth2/revoke', // POST application/json => application/json
    social: () => baseApiUrl + 'oauth2/social-callback' // POST multipart/form-data => 303 redirect
  },
  filesMgmt: {
    uploadUrl: () => baseApiUrl + 'files/upload-url' // GET query_params => application/json
  },
  profile: {
    profile: () => baseApiUrl + 'profile/:addressOrEns', // GET query_params => application/json, PUT application/json => application/json
    ens: () => baseApiUrl + 'profile/:addressOrEns/ens/:resolveMode', // GET query_params => application/json
    tokens: () => baseApiUrl + 'profile/:addressOrEns/tokens', // GET query_params => application/json
    reactions: () => baseApiUrl + 'profile/reaction/:reactionType', // GET query_params => application/json
    socialPlatform: () => baseApiUrl + 'profile/:addressOrEns/:social/:action' // PUT application/json => application/json
  },
  token: {
    token: () => baseApiUrl + 'token', // GET query_params => application/json
    claim: () => baseApiUrl + 'token/claim', // GET  query_params => application/json, POST application/json => application/json
    tokenById: () => baseApiUrl + 'token/:networkId/:contractAddress/:tokenId', // GET query_params => application/json, PUT application/json => application/json
    transfer: () => baseApiUrl + 'token/:networkId/:contractAddress/:tokenId/transfer', // POST application/json => application/json
    holders: () => baseApiUrl + 'token/:networkId/:contractAddress/:tokenId/holders', // GET query_params => application/json
    attestOwnership: () => baseApiUrl + 'token/:networkId/:contractAddress/:tokenId/attest-ownership', // GET  query_params => application/json
    reaction: () => baseApiUrl + 'token/:networkId/:contractAddress/:tokenId/reaction/:reactionType', // POST application/json => application/json
    reactionAction: () => baseApiUrl + 'token/:networkId/:contractAddress/:tokenId/reaction/:reactionType/:reactionAction' // PUT application/json => application/json
  },
  external: {
    openseaV2: {
      assets: () => 'https://api.opensea.io/api/v2/beta/assets' // GET query_params => application/json
    },
    poap: {
      scan: () => 'https://api.poap.tech/actions/scan/:address', // GET query_params => application/json
      eventPoaps: () => 'https://api.poap.xyz/event/:eventId/poaps' // GET query_params => application/json
    }
  }
}

export default urls
