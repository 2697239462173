import service from './main'
import urls from './urls'

const getTokens = (tokenTuplesList, holderAddress) => service.getRequest(urls.token.token, {
  tokens: tokenTuplesList.join('|'),
  account: holderAddress
})

const getToken = (networkId, contractAddress, tokenId, account) => service.getRequest(urls.token.tokenById, { networkId, contractAddress, tokenId, account })
const updateToken = (networkId, contractAddress, tokenId, updates) => service.putRequest(urls.token.tokenById, { networkId, contractAddress, tokenId, ...updates })

const getHolders = (networkId, contractAddress, tokenId, limit = 10, offset = 0) => service.getRequest(urls.token.holders, { networkId, contractAddress, tokenId, limit, offset })

const transferToken = (networkId, contractAddress, tokenId, params) => service.postRequest(urls.token.transfer, { networkId, contractAddress, tokenId, ...params })
const attestOwnership = (networkId, contractAddress, tokenId) => service.getRequest(urls.token.attestOwnership, { networkId, contractAddress, tokenId })

const getClaimWithQR = (type, qrHash) => service.getRequest(urls.token.claim, { qr_hash: qrHash, type })
const claimToken = (claimParams) => service.postRequest(urls.token.claim, claimParams)

const createReaction = (networkId, contractAddress, tokenId, reactionType, tokenData) => service.postRequest(urls.token.reaction, {
  ...tokenData,
  networkId,
  contractAddress,
  tokenId,
  reactionType
})
const updateReaction = (networkId, contractAddress, tokenId, reactionType, reactionAction, tokenData) => service.putRequest(urls.token.reactionAction, {
  ...tokenData,
  networkId,
  contractAddress,
  tokenId,
  reactionType,
  reactionAction
})

export default {
  getTokens,
  getToken,
  updateToken,
  getHolders,
  transferToken,
  attestOwnership,
  getClaimWithQR,
  claimToken,
  createReaction,
  updateReaction
}
