import fetcher from './fetcher'
import Store from '@/store'
// import Router from '@/router'
import { hyphenCaseToLowerCamelCase } from '@/filters'
// import apiErrors from './apiErrors'
// import supportService from './support'
// import apiUrls from './urls'

// var errorsReported = 0
// const maxErrorsReported = 3

function injectAuthorization (requestContent) {
  if (requestContent.headers == null) {
    requestContent.headers = {}
  }
  if (Store.getters.isLoggedIn) {
    if (Store.getters.token.tokenType.match(/^(B|b)earer$/)) {
      requestContent.headers.Authorization = 'Bearer ' + Store.getters.token.accessToken
    }
  }
}

function reportError (fetchResponse, forceReport = false) {
  // if (fetchResponse.requestContent.requestUrl === apiUrls.support.sendLogged()) { return }
  // if (!Store.getters.token.accessToken) { return }

  // const errorClassify = apiErrors[hyphenCaseToLowerCamelCase(fetchResponse.messageCode)] || 'backend-error'
  // if (errorClassify === 'backend-error' || errorClassify === 'validation-error' || forceReport) {
  //   if (errorsReported >= maxErrorsReported) { return }
  //   errorsReported++
  //   supportService.sendLogged({
  //     subject: 'api-error',
  //     email: Store.getters.token.loginEmail || '',
  //     message: JSON.stringify(fetchResponse, null, 2).replace(/:\/\//g, '_protocol:'),
  //     attachments: []
  //   })
  //     .catch(x => {})
  // }
}

fetcher.setBefore(injectAuthorization)

fetcher.setCallback(401, fetchResponse => {
  reportError(fetchResponse)
  fetchResponse.fmc = fetchResponse.messageCode != null ? hyphenCaseToLowerCamelCase(fetchResponse.messageCode) : 'genericApiError'
  Store.dispatch('alertShow', { error: fetchResponse })
  Store.dispatch('removeToken')
  // if (Router.currentRoute.name === 'home') {
  //   Router.push({ name: 'login' })
  // } else if (Router.currentRoute.name !== 'login') {
  //   Router.push('/' + Router.currentRoute.params.lang + (
  //     Router.currentRoute.name !== 'logout'
  //       ? ('/login?redirect=' + encodeURIComponent(Router.currentRoute.fullPath) +
  //         (Router.currentRoute.query.client_id || Router.currentRoute.query.clientId
  //           ? ('&client_id=' + (Router.currentRoute.query.client_id || Router.currentRoute.query.clientId)) : '') +
  //         (Router.currentRoute.query.client_secret ? ('&client_secret=' + Router.currentRoute.query.client_secret) : '')
  //       ) : ''
  //   ))
  // }
})

fetcher.setCallback(403, fetchResponse => {
  reportError(fetchResponse)
  fetchResponse.fmc = fetchResponse.messageCode != null ? hyphenCaseToLowerCamelCase(fetchResponse.messageCode) : 'genericApiError'
  Store.dispatch('alertShow', { error: fetchResponse })
  // if (Store.getters.userLogged) {
  //   Router.push({ name: 'home' })
  // } else {
  //   Router.push('/' + Router.currentRoute.params.lang + (
  //     Router.currentRoute.name !== 'logout' ? '/login?redirect=' + Router.currentRoute.fullPath : ''
  //   ))
  // }
})

fetcher.setCallback(2, fetchResponse => {
  if (fetchResponse.body != null) {
    if (fetchResponse.message != null) {
      fetchResponse.body.responseMessage = fetchResponse.message
    }
    if (fetchResponse.messageCode != null) {
      fetchResponse.body.responseMessageCode = fetchResponse.messageCode
    }
    return Promise.resolve(fetchResponse.body)
  } else {
    return Promise.resolve(fetchResponse)
  }
})

fetcher.setCallback(4, fetchResponse => {
  reportError(fetchResponse)
  fetchResponse.fmc = fetchResponse.messageCode != null ? hyphenCaseToLowerCamelCase(fetchResponse.messageCode) : 'genericApiError'
  return Promise.reject(fetchResponse)
})

fetcher.setCallback(5, fetchResponse => {
  reportError(fetchResponse, true)
  fetchResponse.fmc = 'genericApiError'
  return Promise.reject(fetchResponse)
})

export default fetcher
