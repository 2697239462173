<template>
  <div id="explore-view">
    <v-row align="stretch">
      <v-col cols="24" md="16" class="px-sm-4">
        <div class="px-sm-4 py-2 d-flex explore-search-bar">
          <v-text-field
            v-model="searchTerm"
            :placeholder="$t('explore.searchPlaceholder')"
            background-color="primary"
            color="white-primary"
            class="rounded-0 font-weight-medium explore-search-text"
            filled
            solo
            :hint="$t('shared.hintAddressOrEns')"
            @keyup.enter="searchProfile"
            autofocus
            :rules="[rules.validAddressOrEns]"
          >
            <template #prepend-inner>
              <v-icon large color="white-primary">mdi-account-outline</v-icon>
            </template>
          </v-text-field>
          <div class="d-flex justify-center align-center accent explore-search-btn">
            <v-btn
              icon
              color="white-primary"
              @click="searchProfile"
              :disabled="!searchValid"
            >
              <v-icon large>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="pt-2">
          <v-btn v-if="userAddressOrEns"
            rounded
            large
            color="accent"
            class="px-6 text-wrap"
            :to="{
              name: 'profile',
              params: {
                tokens_holder: userAddressOrEns
              }
            }"
          >
            {{ $t('explore.exploreCollection') }}
          </v-btn>
          <v-btn v-else
            rounded
            large
            color="accent"
            class="px-6 text-wrap"
            @click="this.$root.$emit('open-wallet-connect', () => $router.push({
                name: 'profile',
                params: {
                  tokens_holder: userAddressOrEns
                }
              })
            )"
          >
            {{ $t('explore.connectAndExplore') }}
          </v-btn>
        </div>
        <div class="my-12 py-8 mx-auto maxw-50" v-if="isLoadingPoaps">
          <div class="pb-2">
            {{ $t('shared.loadingPoaps') }}
          </div>
          <v-progress-linear indeterminate color="white" />
        </div>
        <v-row class="my-12" v-else-if="featuredCollectionsUpdate > 0">
          <v-col
            v-for="featureCollection of [...exampleCollections, ...featuredCollections]"
            :key="'collection-' + featureCollection.addressOrEns"
            cols="24"
            class="my-4"
          >
            <v-row justify="space-between" align="center" class="widget-section-title pb-2 flex-nowrap">
              <div class="text-h2 font-weight-regular text-uppercase text-start pr-2">
                <span v-if="!featureCollection.preventOpen">
                  {{ featureCollection.displayTitle }}
                </span>
                <router-link :to="{ name: 'profile', params: { tokens_holder: featureCollection.addressOrEns } }" class="text-decoration-none text--primary" v-else>
                  {{ featureCollection.displayTitle }}
                </router-link>
              </div>
              <v-btn plain icon :to="{ name: 'profile', params: { tokens_holder: featureCollection.addressOrEns } }" small v-if="!featureCollection.preventOpen">
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </v-row>
            <pp-tokens-table
              :items="featureCollection.poaps"
              :items-per-page-options="[]"
              @view-token="$refs.TokenDetails.$emit('open', $event, true)"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="24" md="8" class="pt-16 pt-md-2 pb-2 pl-4">
        <pp-featured-accounts :wide="$vb.smAndDown" />
      </v-col>
    </v-row>
    <pp-token-details ref="TokenDetails" show-for-account="" />
  </div>
</template>

<style lang="scss">
  @import '@/styles/main.scss';

  #explore-view {
    .explore-search-bar {
      .explore-search-text {
        .v-input__slot {
          border-width: 0;
          input::placeholder {
            color: $whitePrimary;
            opacity: 0.8;
          }
        }
        .v-messages__message {
          text-align: center;
          margin-left: 28px;
          width: 100%;
        }
      }
      .explore-search-btn {
        height: 56px;
        padding: 0;
        width: 56px;
        min-width: 56px;

      }
    }
    .v-image.grow-poap .v-image__image.v-image__image--cover {
      left: -1%;
      top: -1%;
      width: 102%;
      height: 102%;
    }
  }

</style>

<script>
// import { totalTokens, defaultMetadataUri } from '@/data/constants.js'
import PpFeaturedAccounts from '@/components/widgets/PpFeaturedAccounts.vue'
import PpTokensTable from '@/components/tables/PpTokensTable.vue'
import PpTokenDetails from '@/components/dialogs/PpTokenDetails.vue'
import validations from '@/validations'
import { externalApisService, profileService } from '@/services'
import { translateIpfsUri, uppercaseAddressOrName } from '@/filters'
import { cleanNetworkId, getPoapContractAddress } from '@/data/web3constants'
import { mapGetters } from 'vuex'

export default {
  components: {
    PpFeaturedAccounts,
    PpTokensTable,
    PpTokenDetails
  },
  data () {
    return {
      searchTerm: '',
      featuredCollectors: [
        'fioscan.eth',
        'vitalik.eth'
      ],
      featuredCollections: [],
      featuredCollectionsUpdate: 0,
      isLoadingPoaps: true,
      exampleCollections: [{
        preventOpen: true,
        addressOrEns: 'andreakkari.eth',
        displayTitle: 'ANDREAKKARI.ETH',
        poaps: [{
          preventOpen: true,
          fullId: 'xdai-poap-10000000001',
          name: 'Bodog Black Diamond',
          imageUri: 'assets/img/examples/example1.gif',
          class: 'rounded-circle grow-poap'
        }, {
          preventOpen: true,
          fullId: 'xdai-poap-10000000002',
          name: 'Bodog Million',
          imageUri: 'assets/img/examples/example2.gif',
          class: 'rounded-circle grow-poap'
        }, {
          preventOpen: true,
          fullId: 'xdai-poap-10000000003',
          name: 'Crypto Poker Open',
          imageUri: 'assets/img/examples/example3.gif',
          class: 'rounded-circle grow-poap'
        }, {
          preventOpen: true,
          fullId: 'xdai-poap-10000000004',
          name: 'Final Table of WSOP 2022',
          imageUri: 'assets/img/examples/example4.gif',
          class: 'rounded-circle grow-poap'
        }]
      }, {
        preventOpen: true,
        addressOrEns: 'neymarjr.eth',
        displayTitle: 'NEYMARJR.ETH',
        poaps: [{
          preventOpen: true,
          fullId: 'xdai-poap-10000000005',
          name: 'Final Table BSOP 2022',
          imageUri: 'assets/img/examples/example5.png',
          class: 'rounded-circle grow-poap'
        }, {
          preventOpen: true,
          fullId: 'xdai-poap-10000000006',
          name: 'Game Over Finalists',
          imageUri: 'assets/img/examples/example6.png',
          class: 'rounded-circle grow-poap'
        }, {
          preventOpen: true,
          fullId: 'xdai-poap-10000000007',
          name: 'Poker Players at Bodog',
          imageUri: 'assets/img/examples/example7.gif',
          class: 'rounded-circle grow-poap'
        }, {
          preventOpen: true,
          fullId: 'xdai-poap-10000000008',
          name: 'Super Champions League',
          imageUri: 'assets/img/examples/example8.png',
          class: 'rounded-circle grow-poap'
        }]
      }]
    }
  },
  metaInfo () {
    return {
      title: 'PlayerProof - ' + this.$t('nav.explore')
    }
  },
  computed: {
    ...mapGetters([
      'userAddressOrEns'
    ]),
    rules () {
      return {
        validAddressOrEns: value => validations.validAddressOrEns(value, this.$t('shared.hintAddressOrEns'))
      }
    },
    searchValid () {
      if (!this.searchTerm) { return false }
      return validations.validAddressOrEns(this.searchTerm, false)
    }
  },
  methods: {
    searchProfile () {
      if (!this.searchValid) { return }
      this.$router.push({
        name: 'profile',
        params: {
          tokens_holder: this.searchTerm
        }
      })
    },
    getFeaturedCollections () {
      this.isLoadingPoaps = true
      this.featuredCollections = []
      return Promise.all(this.featuredCollectors.map(featuredCollector => {
        return profileService.getTokensList(featuredCollector, 'poap')
          .then(fetchResponse => {
            if (!(fetchResponse.tokens instanceof Array)) {
              throw new Error('Incorrect API response format')
            }
            this.featuredCollections.push({
              addressOrEns: featuredCollector,
              displayTitle: uppercaseAddressOrName(featuredCollector),
              poaps: fetchResponse.tokens.slice(0, 4).map(poapDetails => {
                if (poapDetails.event.eventUrl) {
                  poapDetails.event.originalEventUrl = poapDetails.event.eventUrl
                  poapDetails.event.eventUrl = translateIpfsUri(poapDetails.event.eventUrl)
                }
                if (poapDetails.event.imageUrl) {
                  poapDetails.event.originalImageUrl = poapDetails.event.imageUrl
                  poapDetails.event.imageUrl = translateIpfsUri(poapDetails.event.imageUrl)
                }
                if (poapDetails.externalUrl) {
                  poapDetails.originalExternalUrl = poapDetails.externalUrl
                  poapDetails.externalUrl = translateIpfsUri(poapDetails.externalUrl)
                }
                if (poapDetails.imageUri) {
                  poapDetails.originalImageUri = poapDetails.imageUri
                  poapDetails.imageUri = translateIpfsUri(poapDetails.imageUri)
                }
                poapDetails.fullId = `${poapDetails.networkId}-${poapDetails.contractAddress}-${poapDetails.tokenId}`
                return poapDetails
              })
            })
          })
      }))
        .then(() => {
          this.featuredCollections = [...this.featuredCollections]
          this.featuredCollectionsUpdate++
        })
        .catch(error => {
          this.$store.dispatch('alertShow', { error })
        })
        .finally(() => {
          this.isLoadingPoaps = false
        })
    }
  },
  watch: {
  },
  mounted () {
    this.getFeaturedCollections()
  }
}
</script>
