<template>
  <div class="relative">
    <v-row justify="space-between" align="center" class="widget-section-title pb-2 flex-nowrap">
      <div class="text-h2 font-weight-regular text-uppercase text-start pr-2">
        {{ $t('widgets.recentSearches.title') }}
      </div>
      <v-tooltip-ext top color="primary">
        <template #activator="{ on: onClearHistory }">
          <v-btn plain icon @click="clearRecent" v-on="onClearHistory" v-if="recentSearches.length > 0" small>
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </template>
        {{ $t('widgets.recentSearches.empty') }}
      </v-tooltip-ext>
    </v-row>
    <v-list color="transparent">
      <v-list-item
        v-for="(recentSearch, $iRecentSearch) of recentSearches"
        :key="'recent-search-' + $iRecentSearch"
        :to="{
          name: 'profile',
          params: {
            tokens_holder: recentSearch.searchTerm
          }
        }"
      >
        <v-list-item-icon class="d-md-none d-lg-block">
          <v-avatar color="accent">
            <img
              v-if="recentSearch.avatarUri"
              :src="recentSearch.avatarUri"
              alt=""
              @error="recentSearch.avatarUri = null"
            >
            <v-icon size="28" color="white" v-else>mdi-ethereum</v-icon>
          </v-avatar>
        </v-list-item-icon>
        <v-list-item-title class="text-h3 text-lg-start text-truncate">
          {{ recentSearch.displayName }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { uppercaseAddressOrName } from '@/filters'

export default {
  props: {
    skipMostRecent: {
      type: Boolean,
      default: false
    },
    forceUpdater: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
    }
  },
  computed: {
    recentSearches () {
      if (this.forceUpdater < 0) { return [] }
      let storedRecentSearches
      const recordOffset = this.skipMostRecent ? 1 : 0
      try {
        storedRecentSearches = JSON.parse(localStorage.recentSearches)
      } catch {}
      const recentSearches = storedRecentSearches instanceof Array
        ? storedRecentSearches
          .slice(recordOffset, 5 + recordOffset)
          .filter(recentSearch => typeof recentSearch === 'object' && recentSearch.name && recentSearch.address && recentSearch.searchTerm)
        : []
      recentSearches.forEach(recentSearch => {
        recentSearch.displayName = uppercaseAddressOrName(recentSearch.name)
      })
      return recentSearches
    }
  },
  methods: {
    clearRecent () {
      localStorage.recentSearches = '[]'
      this.$emit('update:force-updater', Date.now())
    }
  }
}
</script>

<style lang="scss">
  .widget-section-title {
    border-bottom: 2px solid white;
  }
</style>
