// Library modules
/* eslint-disable */
import 'babel-polyfill'
import Vue from 'vue'
import VueTheMask from 'vue-the-mask'

// Vue Application entry point
import App from './App.vue'

// JS Scripts
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import meta from './plugins/meta'
import filters from './filters'


// CSS Scripts
import '@/styles/main.scss'

// Vue Global Components


// Vue constructor initialization
Object.keys(filters).map(key => {
  Vue.filter(key, filters[key])
})
Vue.use(VueTheMask)
Vue.prototype.$vue = Vue
Vue.prototype.$vb = vuetify.framework.breakpoint
Vue.prototype.$slotsWithScope = function () {
  // This function is created to solve a bug that is generated when component templates are extended and 
  //  all slots of the new component are passed to the extended component. In that case when you try to bind
  //  the scopeProps of a scope without scopeProps, bug occurs. Therefore $slotsWithScope is constructed by taking
  //  $scopedSlots (which has every slot despite its name) and removing slots in $slots (which has slots without scope).
  // Scoped slots and unscoped slots are then passed separately to avoid the bug. 
  const slotsWithScope = {}

  Object.keys(this.$scopedSlots).forEach(slotKey => {
    if (slotKey.indexOf('$') === -1 && !this.$slots[slotKey]) {
      slotsWithScope[slotKey] = this.$scopedSlots[slotKey]
    }
  })

  return slotsWithScope
}
Vue.config.productionTip = false

// Vue constructor instantiation
window.vueInstance = Vue
const vm = new Vue({
  data: {
    web2Ready: false,
    web3Modal: null,
    ethProvider: null
  },
  computed: {
    web3Ready () {
      return !!this.ethProvider
    },
    web3Provider () {
      return this.ethProvider ? this.ethProvider.provider : null 
    },
    ethSigner () {
      if (!this.ethProvider) { return null }
      if (!this.$store.getters.web3Address) { return null }
      return this.ethProvider.getSigner()
    }
  },
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
window.vueMachine = vm

if (process.env.NODE_ENV === 'development') {
  window.console.json = object => {
    // eslint-disable-line
    console.log(JSON.parse(JSON.stringify(object)))
  }
}

// Vue Machine configuration
vm.searchComponent = function (searchFunc) {
  function recursiveComponentSearch (parent, searchFunc) {
    let result = []
    if (parent.$children) {
      parent.$children.forEach(child => {
        recursiveComponentSearch(child, searchFunc).forEach(subcomponent => { result.push(subcomponent) })
      })
    }
    if (searchFunc(parent)) {
      result.push(parent)
    }
    return result
  }
  return recursiveComponentSearch(vm, searchFunc)
}

export {
  vm
}
