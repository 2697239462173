<template>
  <v-row id="pp-top-bar" align="center">
    <v-col cols="8" sm="4" md="6" class="text-start mt-1">
      <a href="../">
        <img src="assets/img/player-proof.png" width="250px" class="d-md-none" />
        <img src="assets/img/player-proof.png" width="250px" class="d-none d-md-inline py-4" />
      </a>
    </v-col>
    <v-col cols="16" sm="20" md="18">
      <div class="text-end" v-if="$root.web2Ready && $root.web3Ready">
        <v-menu-ext left nudge-bottom="25" content-class="white-primary--border-2">
          <template #activator="{ on: onNavMenu }">
            <span>
              <template v-if="isWeb3Connected || isLoggedIn">
                <v-tooltip-ext bottom color="primary" v-if="!isLoggedIn">
                  <template #activator="{ on: onSignInButton }">
                    <v-btn
                      large
                      rounded
                      class="mr-2 d-none d-sm-inline-flex text-none"
                      color="primary"
                      @click="$root.$emit('signin-with-wallet')"
                      v-on="onSignInButton"
                    >
                      {{ formatteduserAccountTitle }}
                      <v-icon right class="mb-1p">mdi-help-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  {{ $t('nav.signInWithWallet') }}
                </v-tooltip-ext>
                <v-btn v-else
                  large
                  rounded
                  class="mr-2 d-none d-sm-inline-flex text-none"
                  color="primary"
                  :to="{
                    name: 'profile',
                    params: {
                      tokens_holder: userAddressOrEns
                    }
                  }"
                >
                  {{ formatteduserAccountTitle }}
                  <v-icon right class="mb-1p">mdi-check-circle</v-icon>
                </v-btn>
                <v-btn icon large plain v-on="onNavMenu">
                  <v-avatar size="44" color="accent">
                    <img
                      v-if="userAvatarUri"
                      :src="userAvatarUri"
                      :alt="userAccountTitle"
                    >
                    <v-icon v-else size="24" color="white">mdi-ethereum</v-icon>
                  </v-avatar>
                </v-btn>
              </template>
              <template v-else>
                <v-btn large rounded class="mr-2 d-none d-sm-inline-flex" color="primary" @click="$root.$emit('open-wallet-connect')">
                  {{ $t('shared.connect') }}
                </v-btn>
                <v-btn icon large plain @click="$root.$emit('open-wallet-connect')">
                  <v-icon size="48" color="accent">mdi-account-circle-outline</v-icon>
                </v-btn>
              </template>
              <v-btn icon large class="ml-2 d-sm-none" v-on="onNavMenu">
                <v-icon large>mdi-menu</v-icon>
              </v-btn>
            </span>
          </template>
          <pp-nav-list dense color="secondary" class="text-end" :show-nav="$vb.xs">
            <template #before-list v-if="web3Address">
              <v-list-item class="justify-end d-sm-none">
                {{ userAccountTitle }}
              </v-list-item>
              <v-divider class="white-disabled--border mx-2 mt-1 d-sm-none" />
            </template>
            <template #after-list>
              <v-divider class="white-disabled--border mx-2 mt-1" v-if="$vb.xs" />
              <v-list-item @click="$root.$emit('signin-with-wallet')" class="mt-1 mt-sm-0" v-if="isWeb3Connected && !isLoggedIn">
                <v-list-item-content class="justify-end">{{ $t('nav.signInWithWallet') }}</v-list-item-content>
              </v-list-item>
              <v-list-item @click="$root.$emit('switch-wallet')" class="mt-1 mt-sm-0" v-if="isWeb3Connected">
                <v-list-item-content class="justify-end">{{ $t('nav.changeWallet') }}</v-list-item-content>
              </v-list-item>
              <v-list-item @click="$root.$emit('disconnect-wallet')" v-if="isWeb3Connected">
                <v-list-item-content class="justify-end">{{ $t('nav.disconnectWallet') }}</v-list-item-content>
              </v-list-item>
              <v-list-item @click="$root.$emit('disconnect-wallet')" v-else-if="isLoggedIn">
                <v-list-item-content class="justify-end">{{ $t('nav.logoutSocial') }}</v-list-item-content>
              </v-list-item>
              <v-list-item @click="$root.$emit('open-wallet-connect')" class="mt-2 mt-sm-0 d-sm-none" v-else>
                <v-list-item-content class="justify-end">{{ $t('shared.connectWallet') }}</v-list-item-content>
              </v-list-item>
            </template>
          </pp-nav-list>
        </v-menu-ext>
      </div>
      <div class="text-end" v-else>
        <v-progress-circular indeterminate color="white"/>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import PpNavList from './PpNavList.vue'
import { uppercaseAddressOrName } from '@/filters'
import { mapGetters } from 'vuex'

export default {
  components: { PpNavList },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'userAccountTitle',
      'userAddressOrEns',
      'userAvatarUri',
      'web3Address',
      'isWeb3Connected'
    ]),
    formatteduserAccountTitle () {
      return uppercaseAddressOrName(this.userAccountTitle)
    }
  }
}
</script>

<style lang="scss">
  @import '@/styles/main.scss';

  #pp-top-bar {
    z-index: 2;
    position: relative;
    min-height: 150px;
    .v-btn.v-btn--icon .v-btn__content {
      opacity: 1;
      i { color: white; }
      svg { fill: white }
      &:hover {
        i { color: $accent; }
        svg { fill: $accent }
      }
    }
  }
</style>
