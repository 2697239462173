<template>
  <div id="favorites-view">
    <v-row align="stretch">
      <v-col cols="24" md="16" class="px-4">
        <h1 class="d-flex justify-start align-center text-uppercase text-shadow">
          <div class="relative square-40 text-start mr-2">
            <v-icon x-large class="absolute" color="accent">mdi-heart</v-icon>
            <v-icon x-large class="absolute">mdi-heart-outline</v-icon>
          </div>
          {{ $t('nav.favorites') }}
        </h1>
        <v-alert type="warning" class="my-8" v-if="!isLoggedIn">
          {{ $t('favorites.pleaseLogin') }}
        </v-alert>
        <div class="pt-8" v-else>
          <div class="py-4">
            <div class="pb-2 d-flex justify-space-between align-baseline" style="border-bottom: 2px white solid">
              <h2 class="text-start">POAPs</h2>
              <div class="text-end text-h2">#{{ formattedNumberOfPoaps }}</div>
            </div>
            <div class="py-8 mx-auto maxw-50" v-if="isLoadingPoaps && !favoritePoaps.length">
              <div class="pb-2">
                {{ $t('shared.loadingPoaps') }}
              </div>
              <v-progress-linear indeterminate color="white" />
            </div>
            <div class="py-8 mx-auto maxw-50" v-else-if="!renderedPoaps || !renderedPoaps.length">
              <div class="pb-2">
                {{ $t('favorites.noPoaps') }}
              </div>
            </div>
            <pp-tokens-table
              v-else
              :items="renderedPoaps"
              :items-per-page-options="[12, 24, 48]"
              @view-token="$refs.TokenDetails.$emit('open', $event)"
            />
          </div>
          <div class="pt-4">
            <div class="pb-2 d-flex justify-space-between align-baseline" style="border-bottom: 2px white solid">
              <h2 class="text-start">NFTs</h2>
              <div class="text-end text-h2">#{{ formattedNumberOfNFTs }}</div>
            </div>
            <div class="py-8 mx-auto maxw-50" v-if="isLoadingNFTs && !filteredNFTs.length">
                {{ $t('shared.loadingNfts') }}
              <v-progress-linear indeterminate color="white" />
            </div>
            <div class="py-8 mx-auto maxw-50" v-else-if="!filteredNFTs || !favoriteNFTs.length">
              <div class="pb-2">
                {{ $t('favorites.noNfts') }}
              </div>
            </div>
            <div v-else>
              <div class="px-4">
                <v-autocomplete
                  v-model="selectedCollection"
                  :items="nftCollections"
                  cleareable
                  item-text="name"
                  item-value="id"
                  :placeholder="$t('profile.collectionsPlaceholder')"
                  color="white"
                  :menu-props="{
                    contentClass: 'white-primary--border-2 rounded',
                    nudgeBottom: 10
                  }"
                >
                  <template #item="{ item, on: onItem }">
                    <v-list-item class="secondary" v-on="onItem">
                      <v-list-item-avatar>
                        <img :src="item.imageUri" v-if="item.imageUri">
                      </v-list-item-avatar>
                      <v-list-item-title>
                        <span class="text-truncate">{{ item.name }}</span>
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template #selection="{ item }">
                    <v-list-item>
                      <v-list-item-avatar>
                        <img :src="item.imageUri" v-if="item.imageUri">
                      </v-list-item-avatar>
                      <v-list-item-title>
                        <span class="text-truncate">{{ item.name }}</span>
                      </v-list-item-title>
                      <v-list-item-action>
                        <v-icon color="white" @click="selectedCollection = null">mdi-close</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                    <!-- <img :src="item.imageUri" width="32" height="32" v-if="item.imageUri">
                    <span class="text-truncate">{{ item.name }}</span> -->
                  </template>
                </v-autocomplete>
              </div>
              <pp-tokens-table
                :items="filteredNFTs"
                :items-per-page-options="[12, 24, 48]"
                @view-token="$refs.TokenDetails.$emit('open', $event)"
              />
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="24" md="8" class="pt-8 pt-md-2 pb-2 px-4 pr-md-0">
        <pp-featured-accounts :wide="$vb.smAndDown" />
      </v-col>
    </v-row>
    <pp-token-details ref="TokenDetails" show-for-account="" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { profileService } from '@/services'
import { translateIpfsUri } from '@/filters'
import PpFeaturedAccounts from '@/components/widgets/PpFeaturedAccounts.vue'
import PpTokensTable from '@/components/tables/PpTokensTable.vue'
import PpTokenDetails from '@/components/dialogs/PpTokenDetails.vue'

export default {
  components: {
    PpFeaturedAccounts,
    PpTokensTable,
    PpTokenDetails
  },
  data () {
    return {
      isLoadingPoaps: true,
      isLoadingNFTs: true,
      favoritePoaps: [],
      favoriteNFTs: [],
      selectedCollection: null
    }
  },
  metaInfo () {
    return {
      title: 'PlayerProof - ' + this.$t('nav.favorites')
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'userAddress'
    ]),
    formattedNumberOfPoaps () {
      if (!this.favoritePoaps) { return [] }
      return String(this.favoritePoaps.length).padStart(3, '0')
    },
    formattedNumberOfNFTs () {
      if (!this.favoriteNFTs) { return [] }
      return String(this.favoriteNFTs.length).padStart(3, '0')
    },
    renderedPoaps () {
      if (!this.favoritePoaps) { return [] }
      if (!this.favoritePoaps.length) { return [] }
      return this.favoritePoaps
        .filter(poapItem => {
          if (poapItem.banned) { return false }
          return true
        })
    },
    renderedNFTs () {
      if (!this.favoriteNFTs) { return [] }
      if (!this.favoriteNFTs.length) { return [] }
      return this.favoriteNFTs
        .filter(nftItem => {
          if (nftItem.standard !== 'erc721' && nftItem.standard !== 'erc1155') { return false }
          if (nftItem.banned) { return false }
          return true
        })
    },
    filteredNFTs () {
      if (!this.selectedCollection) {
        return this.renderedNFTs
      }
      return this.renderedNFTs
        .filter(nftItem => nftItem.collection.id === this.selectedCollection)
    },
    nftCollections () {
      if (!this.renderedNFTs) { return [] }
      if (!this.renderedNFTs.length) { return [] }
      const collectionsMap = {}
      this.renderedNFTs
        .forEach(nftItem => {
          if (!nftItem.collection) { return }
          const collectionId = nftItem.collection.id
          if (!collectionsMap[collectionId]) {
            collectionsMap[collectionId] = {
              id: collectionId,
              name: nftItem.collection.name,
              imageUri: nftItem.collection.imageUri
            }
          }
        })
      return Object.values(collectionsMap)
    }
  },
  methods: {
    getFavorites () {
      this.$root.$emit('signin-with-wallet', () => {
        this.getFavoritePoaps()
        this.getFavoriteNfts()
      })
    },
    async getFavoritePoaps () {
      if (!this.isLoggedIn) { return }
      this.isLoadingPoaps = true
      this.favoritePoaps = []
      profileService.getReactionsList('fav', 'poap')
        .then(fetchResponse => {
          if (fetchResponse.tokens && fetchResponse.tokens instanceof Array) {
            this.favoritePoaps = fetchResponse.tokens
              .map(favPoap => {
                favPoap.originalImageUri = favPoap.imageUri
                favPoap.imageUri = favPoap.imageUri ? translateIpfsUri(favPoap.imageUri) : null
                favPoap.fullId = `${favPoap.networkId}-${favPoap.contractAddress}-${favPoap.tokenId}`
                return favPoap
              })
          }
        })
        .catch(error => {
          this.$store.dispatch('alertShow', { error })
        })
        .finally(() => {
          this.isLoadingPoaps = false
        })
    },
    async getFavoriteNfts () {
      if (!this.isLoggedIn) { return }
      this.isLoadingNFTs = true
      this.favoriteNFTs = []
      profileService.getReactionsList('fav', 'nft')
        .then(fetchResponse => {
          if (fetchResponse.tokens && fetchResponse.tokens instanceof Array) {
            this.favoriteNFTs = fetchResponse.tokens
              .map(favNFT => {
                favNFT.originalImageUri = favNFT.imageUri
                favNFT.imageUri = favNFT.imageUri ? translateIpfsUri(favNFT.imageUri) : null
                favNFT.fullId = `${favNFT.networkId}-${favNFT.contractAddress}-${favNFT.tokenId}`
                return favNFT
              })
          }
        })
        .catch(error => {
          this.$store.dispatch('alertShow', { error })
        })
        .finally(() => {
          this.isLoadingNFTs = false
        })
    }
  },
  watch: {
    isLoggedIn (newValue) {
      if (!newValue) { return }
      this.getFavorites()
    }
  },
  mounted () {
    if (this.isLoggedIn) {
      this.getFavorites()
    } else {
      this.$root.$emit('signin-with-wallet')
    }
  }

}
</script>

<style>

</style>
