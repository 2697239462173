const requestAnimationFrame = window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame ||
  (callback => setTimeout(callback, 100))

function scrollTo (position = 0) {
  if (typeof window.scrollTo === 'function' &&
    'scrollBehavior' in document.documentElement.style &&
    window.__forceSmoothScrollPolyfill__ !== true) {
    window.scrollTo({
      top: position,
      left: 0,
      behavior: 'smooth'
    })
    return Promise.resolve(false)
  }
  return new Promise((resolve, reject) => {
    function toTop () {
      const pix = 75
      let current = document.documentElement.scrollTop || document.body.scrollTop
      current = current - pix > position ? current - pix : position
      document.documentElement.scrollTop = document.body.scrollTop = current
      if (current === position) {
        resolve(false)
      } else {
        requestAnimationFrame(toTop)
      }
    }
    requestAnimationFrame(toTop)
  })
}

const blobToDataUrl = contentBlob => {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(contentBlob)
  })
}

export {
  requestAnimationFrame,
  scrollTo,
  blobToDataUrl
}

export default {
  requestAnimationFrame,
  scrollTo,
  blobToDataUrl
}
